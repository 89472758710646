import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { castArray } from 'lodash-es';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { useAuth } from 'auth/AuthContext';
import { NotAllowed } from '../NotAllowed';
import { ErrorComponent } from '../ErrorComponent';

export const ProtectedRoute = ({
  role: requiredRoles,
  component,
  ...props
}) => {
  const [isPending, setIsPending] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const { roles: existingRoles } = useAuth();

  useEffect(() => {
    setIsAllowed(
      castArray(requiredRoles).some((requiredRole) =>
        existingRoles?.includes(requiredRole),
      ),
    );

    setIsPending(false);
  }, [existingRoles, requiredRoles]);

  if (isPending) {
    return SKIP_RENDER;
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorComponent} showDialog>
      <Route {...props} component={isAllowed ? component : NotAllowed} />
    </Sentry.ErrorBoundary>
  );
};
