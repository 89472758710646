import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import {
  Edit as EditIcon,
  RestartAlt as RestartAltIcon,
} from '@mui/icons-material';
import { readableColor } from 'polished';
import { useMemo } from 'react';
import { isEqual } from 'lodash-es';
import { IntlDate } from 'i18n/IntlDate';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/semanticConstants';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { AppointmentState } from 'constants/AppointmentState';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0),
  },
}));

const ColoredListItemSecondaryText = styled(({ backgroundColor, ...props }) => (
  <Typography variant="body1" {...props} />
))`
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
        width: fit-content;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: ${backgroundColor};
        color: ${readableColor(backgroundColor)};
      `}
`;

const ListEntry = ({ messageId, data, color }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return data ? (
    <ListItem dense>
      <ListItemText
        className={classes.root}
        disableTypography
        primary={
          <Typography variant="caption">
            {formatMessage({ id: messageId })}
          </Typography>
        }
        secondary={
          <ColoredListItemSecondaryText backgroundColor={color}>
            {data}
          </ColoredListItemSecondaryText>
        }
      />
    </ListItem>
  ) : (
    SKIP_RENDER
  );
};

/**
 * The exposé of an appointment.
 */
export const Expose = ({ appointment, onEdit, onRestartClick }) => {
  const {
    state,
    pii: {
      dateOfBirth,
      emailAddress,
      firstName,
      idNumber,
      insuranceCardNumber,
      lastName,
      phoneNumber,
    } = EMPTY_OBJECT,
    test: { hexColor, type: testType } = EMPTY_OBJECT,
    service: {
      name,
      test: { type: serviceTestType } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
  } = appointment;

  const isAppointmentCorrupt = useMemo(() => {
    const isAppointmentInTheSameOrganization = !isEqual(
      AppointmentState.OTHER_ORGANIZATION,
      state,
    );
    const isTestTypeMissing = !testType && !serviceTestType;

    return isAppointmentInTheSameOrganization && isTestTypeMissing;
  }, [state, serviceTestType, testType]);

  return (
    <Box component="section" bgcolor={Colour.mainLightest}>
      <List dense>
        <ListEntry messageId="test.type" data={name} color={hexColor} />
        <ListEntry messageId="person.first_name" data={firstName} />
        <ListEntry messageId="person.last_name" data={lastName} />
        {dateOfBirth && (
          <ListEntry
            messageId="person.date_of_birth"
            data={<IntlDate date={dateOfBirth} />}
          />
        )}
        <ListEntry
          messageId="person.insurance_card_number"
          data={insuranceCardNumber}
        />
        <ListEntry messageId="person.id_number" data={idNumber} />
        <ListEntry messageId="person.email_address" data={emailAddress} />
        <ListEntry messageId="person.phone_number" data={phoneNumber} />
      </List>

      <SelfCheckinProtectedState
        otherwise={
          <>
            {isAppointmentCorrupt ? (
              <Tooltip
                title={
                  <Translate text="scan.appointment.edit.reset_flow.tooltip" />
                }
                arrow
                placement="top"
              >
                <Box display="flex" justifyContent="center" p={1} mr={2} ml={2}>
                  <Button
                    color="error"
                    fullWidth
                    onClick={onRestartClick}
                    startIcon={<RestartAltIcon />}
                    variant="contained"
                  >
                    <Translate text="scan.appointment.edit.reset_flow" />
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              SKIP_RENDER
            )}

            <Box display="flex" justifyContent="center" p={1} mr={2} ml={2}>
              <Button
                color="secondary"
                fullWidth
                onClick={onEdit}
                startIcon={<EditIcon />}
                variant="outlined"
              >
                <Translate text="scan.appointment.edit.button" />
              </Button>
            </Box>
          </>
        }
      />
    </Box>
  );
};
