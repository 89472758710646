import { styled } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  color: ${Colour.textOrange};
  font-weight: 500;
`;

const OrangeWarningIcon = styled(WarningIcon)`
  color: ${Colour.orange};
  margin-right: 8px;
`;

export const ResultInfoWarning = () => (
  <Wrapper>
    <OrangeWarningIcon />
    <Translate text="test.execution.info.warning" />
  </Wrapper>
);
