import { EMPTY_STRING } from 'constants/semanticConstants';
import { getDomain } from 'utils/utils';

export const GetColour = (color) => {
  const domain = getDomain();

  switch (color) {
    case 'background':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#ffffff'
        : '#f8fcfc';
    case 'main':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#134169'
        : '#69E2CE';
    case 'mainDark':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#134169'
        : '#146759';

    case 'mainLight':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#52B9D8'
        : '#b8f5dc';

    case 'mainLightest':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#52B9D8'
        : '#e5faf6';

    case 'orange':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#DF7046'
        : '#FAB758';

    case 'orangeBackground':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#DF7046'
        : '#fef6eb';

    case 'textOrange':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#DF7046'
        : '#e48807';

    case 'text':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#FFFFFF'
        : '#444444';

    case 'linkedText':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#134169'
        : '#444444';

    case 'buttonPrimary':
      return domain.includes('c02p') || domain.includes('co2p')
        ? '#DF7046'
        : '#69E2CE';

    default:
      return EMPTY_STRING;
  }
};
export const Colour = {
  background: GetColour('background'),
  black: 'black',
  main: GetColour('main'),
  mainDark: GetColour('mainDark'),
  mainLight: GetColour('mainLight'),
  mainLightest: GetColour('mainLightest'),
  orange: GetColour('orange'),
  orangeBackground: GetColour('orangeBackground'),
  red: '#F57A7A',
  text: GetColour('text'),
  textMid: '#4A5568',
  linkedText: GetColour('linkedText'),
  textOrange: GetColour('textOrange'),
  buttonPrimary: GetColour('buttonPrimary'),
  buttonSecondary: '#444444',
  green: '#008000',
};
