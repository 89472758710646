import { styled } from '@mui/material';
import { TestType } from 'constants/TestType';
import { EMPTY_STRING, SKIP_RENDER } from 'constants/semanticConstants';

const Image = styled('img')`
  fill: white;
  height: 100%;
  max-height: 24px;
  max-width: 24px;
  width: 100%;
`;

const getIconByType = (type) => {
  switch (type) {
    case TestType.COV19_PCR:
      return `${process.env.PUBLIC_URL}/cov19-pcr-icon.svg`;
    case TestType.COV19_POOL_PCR:
      return `${process.env.PUBLIC_URL}/cov19-pool-pcr-icon.svg`;
    case TestType.COV19_ANTIGEN:
      return `${process.env.PUBLIC_URL}/cov19-antigen-icon.svg`;
    case TestType.COV19_ANTIBODY:
      return `${process.env.PUBLIC_URL}/cov19-antibody-icon.svg`;
    case TestType.COV19_VACCINE:
      return `${process.env.PUBLIC_URL}/cov19-vaccine-icon.svg`;
    default:
      return `${process.env.PUBLIC_URL}/cov19-unknown-icon.svg`;
  }
};

export const TestTypeIcon = ({ type }) => {
  const src = getIconByType(type);

  return src ? <Image src={src} alt={EMPTY_STRING} /> : SKIP_RENDER;
};
