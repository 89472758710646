import { Box } from '@mui/material';
import { Spinner } from './Spinner';

export const FullPageSpinner = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <Spinner />
  </Box>
);
