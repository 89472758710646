import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'booking/service';

/**
 * Hook which returns the available tests for any appointment.
 */
const useAvailableTests = ({ useDefaultServices = false }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_TEST_TYPES, useDefaultServices],
    () => get(`${API_RESOURCE}?useDefaultServices=${useDefaultServices}`),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
      refetchOnWindowFocus: false,
    },
  );
};

export default useAvailableTests;
