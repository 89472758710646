import { useCallback, useState } from 'react';
import { identity, isEqual, noop, pickBy } from 'lodash-es';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { camelToSnakeCase } from 'utils/utils';
import { IdentityVerificationLevel } from 'constants/IdentityVerificationLevel';

const IdentityVerification = {
  DRIVERS_LICENCE: 'drivers_licence',
  IDENTITY_CARD: 'identity_card',
  OTHER: 'other',
  PASSPORT: 'passport',
  PERSONALLY_KNOWN: 'personally_known',
};

/**
 * The existing options (sorted)
 */
const IdentityVerificationOptions = [
  IdentityVerification.IDENTITY_CARD,
  IdentityVerification.PASSPORT,
  IdentityVerification.DRIVERS_LICENCE,
  IdentityVerification.PERSONALLY_KNOWN,
  IdentityVerification.OTHER,
];

export const IdentityCheck = ({ onSuccess = noop }) => {
  const intl = useIntl();
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [isDefaultViewSelected, setIsDefaultViewSelected] = useState(true);
  const onCancel = useCallback(() => {
    reset();
    setIsDefaultViewSelected(true);
  }, [reset]);
  const onSelectOtherIdentityOption = useCallback(() => {
    setIsDefaultViewSelected(false);
  }, []);

  const onSubmit = useCallback(
    ({ identityType = IdentityVerification.OTHER, otherDocumentNote }) => {
      setIsDefaultViewSelected(true);

      onSuccess(
        camelToSnakeCase(
          pickBy(
            {
              identityType,
              verificationLevel: IdentityVerificationLevel.USER,
              description: otherDocumentNote,
            },
            identity,
          ),
        ),
      );
    },
    [onSuccess],
  );

  return (
    <>
      {/* Title section */}
      <Box display="flex" justifyContent="center" pb={1}>
        <Typography variant="h6" align="center">
          <Translate text="scan.appointment.identity_check.title" />
        </Typography>
      </Box>

      {/* Default view (button options) */}
      {isDefaultViewSelected ? (
        <Grid container direction="column" justifyContent="center" spacing={1}>
          {IdentityVerificationOptions.map((identityType) => {
            const isCustomIdentityOption = isEqual(
              IdentityVerification.OTHER,
              identityType,
            );
            const onClick = () =>
              isCustomIdentityOption
                ? onSelectOtherIdentityOption()
                : onSubmit({ identityType });

            return (
              <Grid item key={identityType}>
                <Button
                  variant={isCustomIdentityOption ? 'outlined' : 'contained'}
                  color="primary"
                  fullWidth
                  onClick={onClick}
                >
                  <Translate
                    text={`scan.appointment.identity_check.option.${identityType}`}
                  />
                </Button>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box
          component="form"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width={1}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Other option input view */}
          <Controller
            control={control}
            name="otherDocumentNote"
            rules={{
              required: intl.formatMessage({
                id: 'scan.appointment.identity_check.input.otherDocumentNote.required',
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                multiline
                autoFocus
                fullWidth
                variant="outlined"
                label={
                  <Translate text="scan.appointment.identity_check.input.otherDocumentNote" />
                }
                rows={2}
                error={!!error}
                helperText={error?.message}
                {...field}
              />
            )}
          />

          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            pt={1}
          >
            <Button variant="contained" color="primary" type="submit">
              <Translate text="action.continue" />
            </Button>
            <Box
              mt={1}
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <Button variant="text" color="secondary" onClick={onCancel}>
                <Translate text="action.back" />
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
