import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'components/organization/OrganizationProvider';
import useOrganizationLogin from 'auth/useOrganizationLogin';
import { useAuth } from 'auth/AuthContext';
import { EMPTY_OBJECT } from 'constants/semanticConstants';
import { AppointmentState } from 'constants/AppointmentState';

export const useChangedToScopedOrganization = ({
  appointmentData: {
    service: { scopedOrganizationId } = EMPTY_OBJECT,
    state,
  } = EMPTY_OBJECT,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { onLoginSucceeded } = useAuth();
  const { organizations, setCurrent } = useOrganization();

  const targetOrganizationId = useMemo(
    () =>
      organizations?.find(
        ({ organizationId }) => scopedOrganizationId === organizationId,
      )?.organizationId,
    [organizations, scopedOrganizationId],
  );
  const shouldTriggerOrganizationChange = useMemo(
    () =>
      state === AppointmentState.OTHER_ORGANIZATION &&
      targetOrganizationId != null,
    [state, targetOrganizationId],
  );

  const onChangeOrganizationSuccess = ({
    token,
    refreshToken,
    organization,
    account,
  }) => {
    setCurrent(organization);
    onLoginSucceeded({ token, refreshToken }, account);

    onSuccess();
  };

  const { isLoading: isChangeOrganizationLoading, mutate: changeOrganization } =
    useOrganizationLogin({
      onSuccess: onChangeOrganizationSuccess,
    });

  useEffect(() => {
    if (shouldTriggerOrganizationChange) {
      changeOrganization({
        organizationId: targetOrganizationId,
      });
    }
    setIsLoading(false);
  }, [
    shouldTriggerOrganizationChange,
    targetOrganizationId,
    changeOrganization,
  ]);

  return { isLoading: isLoading || isChangeOrganizationLoading };
};
