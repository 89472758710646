import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { isEqual } from 'lodash-es';
import { Translate } from 'i18n/Translate';
import { IdentityType } from 'constants/IdentityType';
import { Colour } from 'ui/Colour';
import { BACKDROP_CLICK_EVENT, SKIP_RENDER } from 'constants/semanticConstants';
import { IdCard } from './IdCard/IdCard';
import { Microsoft } from './Microsoft/Microsoft';

const DialogHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomDialogContent = styled(DialogContent)`
  background: linear-gradient(
    -45deg,
    ${Colour.main} 0%,
    ${Colour.mainLight} 100%
  );
  max-width: 360px;
  min-width: 300px;
  padding: 16px;
`;

const RedButton = styled(Button)`
  color: ${Colour.red};
  border-color: ${Colour.red};
`;

const PinInfoText = styled(Box)`
  color: ${Colour.red};
  background-color: white;
  padding: 8px;
  border-radius: 6px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const IdentityDialog = ({
  accountName,
  isDeletionEnabled,
  identity,
  identity: { type },
  isCreate,
  isOpen,
  onClose,
  onDelete,
}) => {
  const closeDialog = (_, reason) => {
    if (isCreate && isEqual(BACKDROP_CLICK_EVENT, reason)) {
      return;
    }

    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <CustomDialogContent>
        {isEqual(IdentityType.IDCARD, type) ? (
          <IdCard
            identity={identity}
            accountName={accountName}
            hideReset={isCreate}
          />
        ) : (
          SKIP_RENDER
        )}

        {isEqual(IdentityType.MICROSOFT, type) ? (
          <Microsoft identity={identity} accountName={accountName} />
        ) : (
          SKIP_RENDER
        )}

        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        <DialogHeader>
          {isDeletionEnabled ? (
            <Box pt={2}>
              <RedButton
                variant="outlined"
                color="secondary"
                onClick={() => onDelete(identity)}
              >
                <Translate text="action.remove" />
              </RedButton>
            </Box>
          ) : (
            SKIP_RENDER
          )}
        </DialogHeader>

        {isCreate ? (
          <PinInfoText mt={1}>
            <Typography variant="body2">
              <Translate text="admin.accounts.identities.pin_warning" />
            </Typography>
          </PinInfoText>
        ) : (
          SKIP_RENDER
        )}
      </CustomDialogContent>
    </Dialog>
  );
};
