import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RoutePath } from 'constants/RoutePath';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { Colour as Colors } from 'ui/Colour';

/**
 * Appointments vs scanning mode tabs switcher
 */
export const ModeSwitcher = () => {
  const { current } = useOrganization();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  return current?.features?.enableAppointmentList ? (
    <Box
      alignItems="stretch"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      py={2}
    >
      <Tabs
        centered
        indicatorColor="primary"
        TabIndicatorProps={{ sx: { backgroundColor: Colors.text } }}
        textColor="secondary"
        value={pathname}
        variant="fullWidth"
      >
        <Tab
          component={Link}
          label={formatMessage({ id: 'checkin.mode.scan' })}
          sx={{ px: 2 }}
          to={RoutePath.CHECKIN}
          value={RoutePath.CHECKIN}
        />
        <Tab
          component={Link}
          label={formatMessage({ id: 'checkin.mode.appointments' })}
          sx={{ px: 2 }}
          to={RoutePath.CHECKIN_APPOINTMENTS}
          value={RoutePath.CHECKIN_APPOINTMENTS}
        />
      </Tabs>
      <Divider />
    </Box>
  ) : (
    SKIP_RENDER
  );
};
