import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEqual, last, lt, negate, size, takeRight } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import { StorageKey } from 'constants/StorageKey';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { usePendingPath } from 'hooks/usePendingPath';
import { isTokenExpired } from 'auth/helpers';
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  SKIP_RENDER,
  UNDEFINED,
} from 'constants/semanticConstants';
import { AuthenticationState } from 'constants/AuthenticationState';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { RouteParam } from 'constants/RouteParam';
import { RoutePath } from 'constants/RoutePath';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { IdentityType } from 'constants/IdentityType';
import { TokenRefresh } from './TokenRefresh';

export const SECURITY_TOKEN_EXCHANGE_ROLE = 'security.token.exchange';

const AuthContext = createContext(EMPTY_OBJECT);

export const AuthProvider = ({ children }) => {
  /**
   * Locally persisted session information
   */
  const [tokens, setTokens] = useLocalStorage(
    StorageKey.HPS_SESSION,
    EMPTY_OBJECT,
  );

  /**
   * Locally persisted account information
   */
  const [account, setAccount] = useLocalStorage(
    StorageKey.HPS_ACCOUNT,
    EMPTY_OBJECT,
  );

  /**
   * Locally persisted user context information.
   */
  const [userContext, setUserContext] = useLocalStorage(
    StorageKey.HPS_USER_CONTEXT,
    EMPTY_OBJECT,
  );

  // ---
  // Reused hooks
  const { search, pathname } = useLocation();
  const { setCurrent: setCurrentOrganization, setOrganizations } =
    useOrganization();
  const { pendingPath, setPendingPath } = usePendingPath();

  // ---
  // Custom hooks
  const [isPending, setIsPending] = useState(true);
  const [roles, setRoles] = useState(EMPTY_ARRAY);

  /**
   * Tracks the current authentication state
   */
  const [authState, onAuthStateChange] = useState(
    AuthenticationState.NOT_AUTHENTICATED,
  );

  /**
   * Keeps track of the auth flow.
   * Useful in different scenarios like knowing if
   * pending path should be trackable.
   */
  const [authHistory, setAuthHistory] = useState([authState]);

  /**
   * Flag telling is if the user is in the logout flow
   */
  const isLogoutFlow = useMemo(() => {
    const MIN_REQUIRED_ENTRIES = 2;

    if (lt(size(authHistory), MIN_REQUIRED_ENTRIES)) {
      return false;
    }

    const [previousEntry, currentEntry] = takeRight(
      authHistory,
      MIN_REQUIRED_ENTRIES,
    );

    return (
      isEqual(AuthenticationState.AUTHENTICATED, previousEntry) &&
      isEqual(AuthenticationState.NOT_AUTHENTICATED, currentEntry)
    );
  }, [authHistory]);

  /**
   * Syncs the auth state with the history.
   */
  const setAuthState = useCallback(
    (nextAuthState) => {
      setAuthHistory((currentAuthHistory) => {
        const isDifferent = negate(isEqual)(
          last(currentAuthHistory),
          nextAuthState,
        );

        return isDifferent
          ? [...currentAuthHistory, nextAuthState]
          : currentAuthHistory;
      });

      onAuthStateChange(nextAuthState);
    },
    [setAuthHistory, onAuthStateChange],
  );

  /**
   * Represents data from the current (maybe) authenticated user context.
   */
  const {
    encryptedKey,
    identity: currentIdentity,
    type: currentIdentityType,
  } = userContext || EMPTY_OBJECT;

  /**
   * Temporarily saved decrypted logged in key.
   *
   * Note: it gets wiped out after the onboarding process.
   */
  const decryptedKey = useMemo(() => {
    if (!encryptedKey) {
      return UNDEFINED;
    }
    return jwtDecode(encryptedKey);
  }, [encryptedKey]);

  const newIdentity = queryString.parse(search)?.[RouteParam.ID];

  const { token, refreshToken } = tokens;

  /**
   * Required to be called on login succeeded.
   */
  const onLoginSucceeded = useCallback(
    (nextTokens, nextAccount) => {
      setTokens(nextTokens);
      setAccount(nextAccount);
    },
    [setTokens, setAccount],
  );

  /**
   * Required to be called on logout
   */
  const onLogout = useCallback(
    (postLogoutTargetURL) => {
      setAuthState(AuthenticationState.NOT_AUTHENTICATED);
      setAccount(EMPTY_OBJECT);
      setTokens(EMPTY_OBJECT);
      setRoles(EMPTY_ARRAY);
      setUserContext(EMPTY_OBJECT);
      setCurrentOrganization?.(UNDEFINED);
      setOrganizations?.(EMPTY_ARRAY);

      setPendingPath(postLogoutTargetURL);
    },
    [
      setAccount,
      setAuthState,
      setCurrentOrganization,
      setOrganizations,
      setPendingPath,
      setRoles,
      setTokens,
      setUserContext,
    ],
  );

  /**
   * Required to be called whenever the user context gets updated.
   *
   * It can contain custom information for the existing user.
   */
  const onUserContextUpdate = useCallback(
    (updates) =>
      setUserContext((currentUserContext) => ({
        ...currentUserContext,
        ...updates,
      })),
    [setUserContext],
  );

  /**
   * If the new id provided as a parameter does not match with the
   * existing (current logged-in user).
   */
  useEffect(() => {
    const isLoginPage = pathname?.includes(RoutePath.LOGIN);

    if (!isLoginPage) {
      return;
    }

    /**
     * a) If logged in via IDCard -> both are filled
     * b) If logged in via Microsoft -> only type is filled
     */
    const isCurrentlyLoggedOut = !currentIdentityType && !currentIdentity;
    if (isCurrentlyLoggedOut) {
      return;
    }

    const isAuthRequired = Boolean(newIdentity);
    if (!isAuthRequired) {
      return;
    }

    const isUserWithIdCardIdentityLoggedIn = isEqual(
      IdentityType.IDCARD,
      currentIdentityType,
    );
    const isDifferentIdentityThanCurrentOne = !isEqual(
      currentIdentity,
      newIdentity,
    );
    const isLogoutAndLoginRequiredFromUser =
      isUserWithIdCardIdentityLoggedIn && isDifferentIdentityThanCurrentOne;
    const isReLoginRequired =
      isLogoutAndLoginRequiredFromUser || !isUserWithIdCardIdentityLoggedIn;
    if (!isReLoginRequired) {
      return;
    }
    /**
     * Post-redirect to the existing target - to start the login process.
     */
    onLogout(`${pathname}${search}`);
  }, [
    currentIdentity,
    currentIdentityType,
    newIdentity,
    onLogout,
    pathname,
    pendingPath,
    search,
  ]);

  /**
   * Logout in case of an expired token
   */
  useEffect(() => {
    if (!(token && isTokenExpired(token))) {
      return;
    }

    onLogout();
  }, [token, onLogout]);

  /**
   * Decodes the token as follows:
   * - If it does not exist, sets the state as being unauthenticated
   * - If it does exist, it sets the role and either allows th
   */
  useEffect(() => {
    if (!token) {
      setAuthState(AuthenticationState.NOT_AUTHENTICATED);

      setIsPending(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const existingRoles = Array.isArray(decodedToken?.role)
      ? decodedToken.role
      : [decodedToken.role];

    setRoles(existingRoles);

    const hasExchangeRole = existingRoles.includes(
      SECURITY_TOKEN_EXCHANGE_ROLE,
    );
    const isTokenStillValid = !isTokenExpired(token);

    if (hasExchangeRole && isTokenStillValid) {
      setAuthState(AuthenticationState.TOKEN_EXCHANGE_REQUIRED);
    } else if (!hasExchangeRole && isTokenStillValid) {
      setAuthState(AuthenticationState.AUTHENTICATED);
    }

    setIsPending(false);
  }, [token, setAuthState]);

  /**
   * Sets the account key in Sentry for easier debugging
   */
  useEffect(() => {
    Sentry.setUser({ id: account?.account_key });
  }, [account]);

  const context = useMemo(
    () => ({
      account,
      authHistory,
      authState,
      decryptedKey,
      isLogoutFlow,
      onLoginSucceeded,
      onLogout,
      onUserContextUpdate,
      refreshToken,
      roles,
      token,
      userContext,
    }),
    [
      account,
      authHistory,
      authState,
      decryptedKey,
      isLogoutFlow,
      onLoginSucceeded,
      onLogout,
      onUserContextUpdate,
      refreshToken,
      roles,
      token,
      userContext,
    ],
  );

  return isPending ? (
    <FullPageSpinner />
  ) : (
    <AuthContext.Provider value={context}>
      {isEqual(authState, AuthenticationState.AUTHENTICATED) ? (
        <TokenRefresh
          token={token}
          refreshToken={refreshToken}
          onSuccess={setTokens}
        />
      ) : (
        SKIP_RENDER
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
