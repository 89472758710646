import queryString from 'query-string';
import { pickBy } from 'lodash-es';
import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';
import { EMPTY_STRING } from 'constants/semanticConstants';

/**
 * Hook which gets the upcoming appointments.
 */
export const useAppointments = ({ search = EMPTY_STRING }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_APPOINTMENTS, search],
    () =>
      get(
        queryString.stringifyUrl({
          url: 'booking/appointment',
          query: pickBy({ search }),
        }),
      ),
    {
      refetchOnWindowFocus: false,
      onError: () =>
        showNotification(ERROR_NOTIFICATION, 'appointments.fetch.error'),
    },
  );
};
