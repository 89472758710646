import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which returns data of a single account of an organization.
 */
export const useSingleAccount = ({ organizationId, accountKey }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_SINGLE_ACCOUNT, accountKey],
    () => get(`organization/${organizationId}/account/${accountKey}`),
    {
      onError: () =>
        showNotification(
          ERROR_NOTIFICATION,
          'admin.accounts.fetchSingle.error',
        ),
      refetchOnWindowFocus: false,
    },
  );
};
