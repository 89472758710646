import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'booking/appointment/';

/**
 * Hook which updates an appointment and set´s the payment state to paid
 */
const useChangeAppointmentPaymentState = () => {
  const { put } = useApi();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    ({ appointmentID, markedAsPaid }) =>
      put(`${API_RESOURCE}${appointmentID}/paymentstate`, { markedAsPaid }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.FETCH_APPOINTMENT);
        showNotification(
          SUCCESS_NOTIFICATION,
          'scan.appointment.markAsPaid.success',
        );
      },
      onError: () =>
        showNotification(
          ERROR_NOTIFICATION,
          'scan.appointment.markAsPaid.error',
        ),
    },
  );
};

export default useChangeAppointmentPaymentState;
