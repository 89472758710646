import { useCallback, useEffect } from 'react';
import { first, gt, isEmpty, size } from 'lodash-es';
import useOrganizationLogin from 'auth/useOrganizationLogin';
import { useAuth } from 'auth/AuthContext';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { UNDEFINED } from 'constants/semanticConstants';
import { usePendingPath } from 'hooks/usePendingPath';
import { getOrganizationSlug } from 'constants/RoutePath';
import { EmptyState } from './EmptyState';
import { SelectOrganizationList } from './SelectOrganizationList';

const SINGLE_ORG = 1;

export const SelectOrganization = () => {
  const { onLoginSucceeded, onUserContextUpdate } = useAuth();
  const { setCurrent, organizations } = useOrganization();
  const { pendingPath } = usePendingPath();

  const selectOrganization = ({
    token,
    refreshToken,
    organization,
    account,
  }) => {
    setCurrent(organization);
    onLoginSucceeded(
      {
        token,
        refreshToken,
      },
      account,
    );
    onUserContextUpdate({
      encryptedKey: UNDEFINED,
    });
  };

  const { mutate: mutateByLoggingIntoOrganization, isLoading } =
    useOrganizationLogin({
      onSuccess: selectOrganization,
    });

  /**
   * The app can auto login if:
   * - There is only a single organization available
   * - There are multiple organization available, but the targeted one is
   * matched with the pending path
   */
  const getOrganizationIdToAutoLogInTo = useCallback(() => {
    const isMultipleOrganizations = gt(size(organizations), SINGLE_ORG);
    if (!isMultipleOrganizations) {
      return first(organizations)?.organizationId;
    }

    return organizations.find(({ organizationId }) =>
      pendingPath?.includes(getOrganizationSlug(organizationId)),
    )?.organizationId;
  }, [pendingPath, organizations]);

  /**
   * The auto login into organization effect
   */
  useEffect(() => {
    const maybeOrganizationId = getOrganizationIdToAutoLogInTo();
    if (!maybeOrganizationId) {
      return;
    }

    mutateByLoggingIntoOrganization({
      organizationId: maybeOrganizationId,
    });
  }, [getOrganizationIdToAutoLogInTo, mutateByLoggingIntoOrganization]);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (isEmpty(organizations)) {
    return <EmptyState />;
  }

  return (
    <SelectOrganizationList
      organizations={organizations}
      setOrganization={mutateByLoggingIntoOrganization}
    />
  );
};
