import { useMutation } from 'react-query';
import { useCallback } from 'react';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';
import { useAuth } from 'auth/AuthContext';

export const useCancelAccount = () => {
  const { del } = useApi();
  const { showNotification } = useNotification();
  const { onLogout } = useAuth();

  const onSuccess = useCallback(() => {
    showNotification(SUCCESS_NOTIFICATION, 'cancel.account.success');

    onLogout();
  }, [showNotification, onLogout]);

  const onError = () =>
    showNotification(ERROR_NOTIFICATION, 'cancel.account.error');

  return useMutation(() => del('account'), {
    onSuccess,
    onError,
  });
};
