import { Avatar, styled, Typography } from '@mui/material';
import MinusIcon from '@mui/icons-material/Remove';
import { TestResult } from 'constants/TestResult';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';
import { ResultSelector } from './ResultSelector';

const NegativeTestIcon = styled(Avatar)`
  background-color: ${Colour.main};
  margin-right: 16px;
`;

export const OptionNegative = ({ onClick }) => (
  <ResultSelector
    focusRipple
    color={Colour.main}
    onClick={() => onClick(TestResult.NEGATIVE)}
  >
    <NegativeTestIcon>
      <MinusIcon />
    </NegativeTestIcon>
    <Typography variant="body1">
      <Translate text="scan.result.negative" />
    </Typography>
  </ResultSelector>
);
