import { Box, Icon, styled, Typography } from '@mui/material';
import {
  AddCircleOutline as PlusIcon,
  CancelOutlined as InvalidIcon,
  CheckCircleOutline as CheckIcon,
  Healing as HealingIcon,
  Help as HelpIcon,
  RemoveCircleOutline as NegativeIcon,
} from '@mui/icons-material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Colour } from 'ui/Colour';
import { TestResult as TestResultType, Co2Result } from 'constants/TestResult';
import { TestResultText } from '../TestResultText';

const REPRESENTATION_PER_TEST_RESULT = {
  [TestResultType.NEGATIVE]: {
    icon: NegativeIcon,
    color: Colour.main,
  },
  [TestResultType.POSITIVE]: {
    icon: PlusIcon,
    color: Colour.red,
  },
  [TestResultType.INVALID]: {
    icon: InvalidIcon,
    color: Colour.orange,
  },
  [TestResultType.DONE]: {
    icon: CheckIcon,
    color: Colour.mainDark,
  },
  [TestResultType.UNKNOWN]: {
    icon: HelpIcon,
    color: Colour.textMid,
  },
  [TestResultType.COMPLEX]: {
    icon: HealingIcon,
    color: Colour.mainLight,
  },
  [Co2Result.VERIFIED]: {
    icon: VerifiedIcon,
    color: Colour.green,
  },
};

const Layout = styled(Box)`
  color: ${({ color }) => color};
`;

export const TestResult = ({ result = TestResultType.UNKNOWN }) => {
  const { color, icon } =
    REPRESENTATION_PER_TEST_RESULT?.[result] ||
    REPRESENTATION_PER_TEST_RESULT[TestResultType.UNKNOWN];

  return (
    <Layout display="flex" alignItems="center" color={color}>
      <Icon component={icon} />

      <Box ml={1}>
        <Typography variant="body2">
          <TestResultText result={result} />
        </Typography>
      </Box>
    </Layout>
  );
};
