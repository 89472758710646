export const TestType = {
  COV19_ANTIBODY: 'cov19.antibody',
  COV19_ANTIGEN: 'cov19.antigen',
  COV19_PCR: 'cov19.pcr',
  COV19_POOL_PCR: 'cov19.pool_pcr',
  COV19_VACCINE: 'cov19.vaccine',
  UNKNOWN: 'unknown',
  PLASTIC_CERT: 'plastic.cert',
  PLASTIC_CONTAINER: 'plastic.container',
};

export const Co2Type = {
  CO2_CERTIFICATE: 'co2.cert',
  CO2_PROJECT: 'co2.project',
  CO2_ORGANIZATION: 'co2.org',
  CO2_MODEL: 'co2.model',
  CO2_CONTAINER: 'co2.container',
};
