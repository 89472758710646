import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { SKIP_RENDER } from 'constants/semanticConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0),
  },
}));

export const ConfirmationFragment = ({ messageId, data }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return data ? (
    <ListItem dense>
      <ListItemText
        className={classes.root}
        disableTypography
        primary={
          <Typography variant="caption">
            {formatMessage({ id: messageId })}
          </Typography>
        }
        secondary={<Typography variant="body1">{data}</Typography>}
      />
    </ListItem>
  ) : (
    SKIP_RENDER
  );
};
