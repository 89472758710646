import { useEffect, useState } from 'react';
import { castArray } from 'lodash-es';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { useAuth } from 'auth/AuthContext';

export const ProtectedState = ({
  role: requiredRoles,
  children,
  errorComponent = SKIP_RENDER,
}) => {
  const [isPending, setIsPending] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const { roles: existingRoles } = useAuth();

  useEffect(() => {
    setIsAllowed(
      castArray(requiredRoles).some((requiredRole) =>
        existingRoles?.includes(requiredRole),
      ),
    );

    setIsPending(false);
  }, [existingRoles, requiredRoles]);

  if (isPending) {
    return SKIP_RENDER;
  }

  return isAllowed ? children : errorComponent;
};
