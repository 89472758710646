import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Translate } from 'i18n/Translate';
import { useOidc } from 'auth/oidc/OidcHook';
import { OidcProvider } from 'auth/oidc/Oidc';
import { getAdminRedirect, getRedirect } from 'constants/RoutePath';

const StyledAvatar = styled(Avatar)`
  background-color: white;
`;

export const IdentityItemAddMicrosoft = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const isAdminArea = location?.pathname?.includes('admin');

  const { getUser, signInRedirect } = useOidc(
    OidcProvider.MICROSOFT,
    isAdminArea
      ? getAdminRedirect(OidcProvider.MICROSOFT)
      : getRedirect(OidcProvider.MICROSOFT),
  );

  const onButtonClick = async () => {
    await getUser();

    signInRedirect({
      state: {
        url: location.pathname,
      },
    });
  };

  return (
    <ListItemButton key="add" onClick={onButtonClick}>
      <ListItemAvatar>
        <StyledAvatar>
          <img
            src={`${process.env.PUBLIC_URL}/msIconSmall.svg`}
            alt={formatMessage({ id: 'alt.microsoft_login_image' })}
          />
        </StyledAvatar>
      </ListItemAvatar>

      <ListItemText
        primary={<Translate text="profile.identities.microsoft.add" />}
      />
    </ListItemButton>
  );
};
