export const FormField = {
  DATE_OF_BIRTH: 'date_of_birth',
  EMAIL_ADDRESS: 'email_address',
  FIRST_NAME: 'first_name',
  ID_NUMBER: 'id_number',
  INSURANCE_CARD_NUMBER: 'insurance_card_number',
  LAST_NAME: 'last_name',
  PHONE_NUMBER: 'phone_number',
  TEST_TYPE: 'test_type',
};
