import { useMutation } from 'react-query';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { DEFAULT_HEADERS, post } from 'utils/api';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'identity/token/refresh';

/**
 * Hook which renews a users' session (token-refresh).
 */
const useRefreshToken = ({ onSuccess, onError }) => {
  const { showNotification } = useNotification();

  return useMutation(
    (payload) => post(API_RESOURCE, payload, DEFAULT_HEADERS),
    {
      onSuccess,
      onError: () => {
        showNotification(ERROR_NOTIFICATION, 'session.refresh.error');
        onError();
      },
    },
  );
};

export default useRefreshToken;
