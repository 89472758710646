import { useIntl } from 'react-intl';
import { ButtonBase } from '@mui/material';
import { Language } from 'ui/Language';

export const MicrosoftLoginButton = ({ onButtonClick }) => {
  const { locale, formatMessage } = useIntl();
  let imageUrl;

  switch (locale) {
    case Language.DE:
      imageUrl = `${process.env.PUBLIC_URL}/msLoginImageDE.svg`;
      break;
    case Language.EN:
    default:
      imageUrl = `${process.env.PUBLIC_URL}/msLoginImageEN.svg`;
      break;
  }

  return (
    <ButtonBase focusRipple onClick={onButtonClick}>
      <img
        src={imageUrl}
        alt={formatMessage({ id: 'alt.microsoft_login_image' })}
      />
    </ButtonBase>
  );
};
