import jwtDecode from 'jwt-decode';

export const isTokenExpired = (jwt) => {
  try {
    const token = jwtDecode(jwt);
    const exp = new Date(0).setUTCSeconds(token.exp);
    const now = new Date().getTime();
    return exp < now;
  } catch (error) {
    return true;
  }
};

/**
 * @param jwt The JSON Web Token to be checked
 * @param checkInterval The interval that the token should be checked before
 */
export const isTokenAboutToExpire = (jwt, checkInterval) => {
  try {
    const token = jwtDecode(jwt);
    const exp = new Date(0).setUTCSeconds(token.exp) - checkInterval;
    const now = new Date().getTime();
    return exp < now;
  } catch (error) {
    return true;
  }
};
