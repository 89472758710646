import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { getCertificatePath } from 'constants/RoutePath';
import { useCo2Certificates } from 'hooks/useCo2Certificates';
import { ProjectOverview } from './ProjectOverview';

export const ProjectDetails = () => {
  const { externalReference } = useParams();
  const { push } = useHistory();

  const { data: certificates, isLoading } = useCo2Certificates({
    projectReference: externalReference,
  });

  const switchToCertificateDetails = useCallback(
    ({ external_reference: reference }) => push(getCertificatePath(reference)),
    [push],
  );

  return (
    <Box alignItems="center" display="flex" flexDirection="column" width={1}>
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <ProjectOverview
          certificates={certificates}
          onRowClick={switchToCertificateDetails}
        />
      )}
    </Box>
  );
};
