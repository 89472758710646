import { Paper, styled } from '@mui/material';
import { Colour } from 'ui/Colour';

const getBorderColor = (isSelected, hasError) => {
  if (isSelected) {
    return Colour.main;
  }
  if (hasError) {
    return Colour.red;
  }
  return 'transparent';
};

export const BorderedPaper = styled(({ isSelected, hasError, ...props }) => (
  <Paper {...props} />
))`
  height: 100%;
  ${({ isSelected, hasError }) =>
    `border: 2px solid ${getBorderColor(isSelected, hasError)}`}
`;
