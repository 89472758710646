import { useQuery } from 'react-query';
import queryString from 'query-string';
import { isEmpty, negate } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import { QueryKeys } from 'constants/QueryKeys';

export const useLookupTests = ({ lookupCriteria }) => {
  const { get } = useApi();

  return useQuery(
    [QueryKeys.LOOKUP_TEST, JSON.stringify(lookupCriteria)],
    () => get(`test?${queryString.stringify(lookupCriteria)}`),
    {
      enabled: negate(isEmpty)(lookupCriteria),
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};
