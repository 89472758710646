import Icon from '@mui/material/Icon';
import NotStartedIcon from '@mui/icons-material/HelpOutline';
import PendingIcon from '@mui/icons-material/PendingActionsOutlined';
import FinishedIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { TestState } from 'constants/TestState';
import { TestStateText } from './TestStateText';

const Icons = {
  [TestState.FINISHED]: FinishedIcon,
  [TestState.NOT_STARTED]: NotStartedIcon,
  [TestState.PENDING]: PendingIcon,
};

export const TestStateIcon = ({ state, className, tooltip }) =>
  tooltip ? (
    <Tooltip title={<TestStateText state={state} />}>
      <Icon component={Icons[state]} className={className} />
    </Tooltip>
  ) : (
    <Icon component={Icons[state]} className={className} />
  );
