import { styled } from '@mui/material';
import { IntlDate } from 'i18n/IntlDate';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';

const Layout = styled('div')`
  width: 100%;
`;

const PersonalDataContainer = styled('div')`
  background-color: white;
  margin-top: 4px;
  padding: 8px 16px;
`;

const Text = styled('p')`
  font-size: 1.1rem;
  margin: 0;
`;

const Label = styled('p')`
  margin: 0;
  font-size: 0.8rem;
  color: ${Colour.textMid};
  margin-bottom: 8px;
`;

const ContainerLabel = styled('span')`
  font-size: 0.9rem;
  color: ${Colour.text};
  font-weight: 500;
  padding-left: 2px;
`;

export const PersonInfoExternal = ({ pii = {} }) => (
  <Layout>
    <ContainerLabel>
      <Translate text="cfp.personal_data" />
    </ContainerLabel>
    <PersonalDataContainer>
      {pii.name ? (
        <>
          <Text>{pii.name}</Text>
          <Label>
            <Translate text="cfp.procedure.external.name" />
          </Label>
        </>
      ) : null}
      {pii.date_of_birth ? (
        <>
          <Text>
            <IntlDate date={pii.date_of_birth} />
          </Text>
          <Label>
            <Translate text="cfp.procedure.base.date_of_birth" />
          </Label>
        </>
      ) : null}
      {pii.year_of_birth ? (
        <>
          <Text>{pii.year_of_birth}</Text>
          <Label>
            <Translate text="cfp.procedure.external.year_of_birth" />
          </Label>
        </>
      ) : null}
    </PersonalDataContainer>
  </Layout>
);
