import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { isEqual } from 'lodash-es';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { Translate } from 'i18n/Translate';
import { AppointmentState } from 'constants/AppointmentState';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { IdentityCheck } from './IdentityCheck';
import { Edit } from './Edit';
import { Expose } from './Expose';
import { ErrorStatus } from './ErrorStatus';
import { AppointmentUsed } from './AppointmentUsed';
import { PaymentInformation } from './PaymentInformation/PaymentInformation';
import { QuestionnaireResult } from './QuestionnaireResult';

export const Overview = ({
  appointment: { state, externalReference: appointmentID },
  appointment,
  onCheckInReady,
  onRestartClick,
  onUpdatedAppointment,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const enableEditMode = useCallback(() => setIsEditMode(true), []);
  const disableEditMode = useCallback(() => setIsEditMode(false), []);
  const canContinueFlow = useMemo(
    () =>
      isEqual(AppointmentState.VALID, state) ||
      isEqual(AppointmentState.USED, state),
    [state],
  );

  return (
    <>
      {canContinueFlow ? (
        <>
          {/* Wizard title */}
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            pb={2}
          >
            <Typography variant="h6" align="center">
              <Translate
                text={
                  isEditMode
                    ? 'scan.appointment.edit.title'
                    : 'scan.appointment.result.title'
                }
              />
            </Typography>
            <Typography variant="body1" align="center">
              <Translate
                text={
                  isEditMode
                    ? 'scan.appointment.edit.sub_title'
                    : 'scan.appointment.result.sub_title'
                }
              />
            </Typography>
          </Box>

          {/* Wizard body */}
          <Box display="flex" flexDirection="column">
            <SelfCheckinProtectedState
              otherwise={
                isEqual(AppointmentState.USED, state) ? (
                  <AppointmentUsed />
                ) : (
                  SKIP_RENDER
                )
              }
            />

            <PaymentInformation
              appointmentID={appointmentID}
              metadata={appointment.metadata}
            />

            <QuestionnaireResult {...appointment} />

            {isEditMode ? (
              <Edit
                appointment={appointment}
                onCancelEdit={disableEditMode}
                onSettled={onUpdatedAppointment}
              />
            ) : (
              <Expose
                appointment={appointment}
                onEdit={enableEditMode}
                onRestartClick={onRestartClick}
              />
            )}
          </Box>
        </>
      ) : (
        <ErrorStatus state={state} />
      )}

      {/* Wizard flow controls */}
      {!isEditMode && (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          m={1}
          mr={2}
          ml={2}
        >
          {canContinueFlow ? (
            <SelfCheckinProtectedState
              otherwise={<IdentityCheck onSuccess={onCheckInReady} />}
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                pt={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onCheckInReady()}
                >
                  <Translate text="action.continue" />
                </Button>
              </Box>
            </SelfCheckinProtectedState>
          ) : (
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={onRestartClick}
            >
              <Translate text="action.restart" />
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
