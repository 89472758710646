import { Translate } from 'i18n/Translate';
import { IdentityType } from 'constants/IdentityType';
import { SKIP_RENDER } from 'constants/semanticConstants';

export const IdentityTypeText = ({ type }) => {
  switch (type) {
    case IdentityType.MICROSOFT:
      return <Translate text="identity.type.microsoft" />;
    case IdentityType.IDCARD:
      return <Translate text="identity.type.id_card" />;
    case IdentityType.APIKEY:
      return <Translate text="identity.type.api_key" />;
    case IdentityType.KEYCLOAK:
      return <Translate text="identity.type.keycloak" />;
    default:
      return SKIP_RENDER;
  }
};
