import { useFetchAccount } from 'customer/profile/hooks/useFetchAccount';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { OrganizationData } from './OrganizationsData';

export const OrganizationOverview = () => {
  const { data: fetchedAccount, isLoading: isFetchingAccount } =
    useFetchAccount();

  return isFetchingAccount ? (
    <FullPageSpinner />
  ) : (
    <OrganizationData
      accountKey={fetchedAccount?.accountKey}
      isPending={isFetchingAccount}
    />
  );
};
