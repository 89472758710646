import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which returns co2 certificate.
 */
export const useCo2Certificate = ({ certificateReference }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_CO2_CERTIFICATE, certificateReference],
    () => get(`co2/${certificateReference}/certificate`),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
      refetchOnWindowFocus: false,
    },
  );
};
