import { useState } from 'react';
import { styled } from '@mui/material';
import { StateHeader } from 'ui/theme/text/StateHeader';
import { Stepper } from 'ui/theme/stepper/Stepper';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { DownloadLabel } from './DownloadLabel';

const Container = styled('div')`
  padding: 16px;
`;

const steps = ['test.label.step.scan_test', 'test.label.step.download'];

const UiState = {
  SCAN_TEST: 0,
  DOWNLOAD_LABEL: 1,
};

export const PrintLabel = () => {
  const [uiState, setUiState] = useState(UiState.SCAN_TEST);
  const [testCode, setTestCode] = useState(null);

  const onScanSuccess = (data) => {
    if (data) {
      setTestCode(data);
      setUiState(UiState.DOWNLOAD_LABEL);
    }
  };

  const onRestartClick = () => {
    setUiState(UiState.SCAN_TEST);
  };

  return (
    <Container>
      <StateHeader text="test.label.title" />
      <Stepper steps={steps} activeStep={uiState} />
      {uiState === UiState.SCAN_TEST ? (
        <BarcodeReader onScanSuccess={onScanSuccess} />
      ) : (
        SKIP_RENDER
      )}
      {uiState === UiState.DOWNLOAD_LABEL ? (
        <DownloadLabel onRestartClick={onRestartClick} testCode={testCode} />
      ) : (
        SKIP_RENDER
      )}
    </Container>
  );
};
