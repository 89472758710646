import { useEffect, useMemo } from 'react';
import { castArray, isEmpty, isNaN, reject } from 'lodash-es';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { EMPTY_OBJECT } from 'constants/semanticConstants';
import { snakeToCamelCase } from 'utils/utils';
import { CommunicationChannel } from 'constants/CommunicationChannel';
import { Roles } from 'constants/Roles';
import { useAuth } from 'auth/AuthContext';
import { ProtectedState } from 'components/auth/ProtectedState';

export const ConfirmationOptions = ({
  checkInOptions = EMPTY_OBJECT,
  isPending,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { roles: existingRoles } = useAuth();

  const communicationOptions = useMemo(
    () =>
      reject(snakeToCamelCase(checkInOptions)?.communicationOptions, [
        'id',
        CommunicationChannel.CUSTOMER_CARD,
      ]),
    [checkInOptions],
  );

  const channelsSelection = useMemo(
    () =>
      communicationOptions?.reduce(
        (accumulator, { id, selected }) => ({
          ...accumulator,
          [id]: selected,
        }),
        EMPTY_OBJECT,
      ),
    [communicationOptions],
  );

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: channelsSelection,
  });

  // ---
  // Auto submit if the user can't select anything
  useEffect(() => {
    if (castArray(existingRoles)?.includes(Roles.SUPPORT)) {
      return;
    }

    const allOptionsAreDisabled = communicationOptions.every(
      ({ disabled }) => disabled,
    );

    if (!allOptionsAreDisabled) {
      return;
    }

    onSubmit(channelsSelection);
  }, [existingRoles, channelsSelection, communicationOptions, onSubmit]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <FormGroup>
          {communicationOptions?.map(
            ({ id: fieldName, disabled: isDisabled }) => (
              <FormControlLabel
                key={fieldName}
                disabled={isDisabled || isPending}
                label={
                  <Translate text={`scan.confirmation.channel.${fieldName}`} />
                }
                control={
                  <Controller
                    control={control}
                    name={fieldName}
                    render={({ field, field: { value } }) => (
                      <Checkbox
                        color="primary"
                        disabled={isDisabled || isPending}
                        checked={value}
                        {...field}
                      />
                    )}
                  />
                }
              />
            ),
          )}
        </FormGroup>
      </Box>

      {/* Show only whenever the support role is available */}
      <ProtectedState role={Roles.SUPPORT}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mt={3}
        >
          <Controller
            control={control}
            name="timestamp"
            rules={{
              validate: (value) =>
                !isNaN(value?.getTime?.()) ||
                formatMessage({
                  id: 'scan.confirmation.input.timestamp.incorrect',
                }),
            }}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                disabled={isPending || isEmpty(communicationOptions)}
                mask="__.__.____"
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={!!error}
                    id="timestamp"
                    name="timestamp"
                    fullWidth
                    helperText={error?.message}
                    InputLabelProps={{
                      shrink: true,
                      htmlFor: 'timestamp',
                    }}
                    label={
                      <Translate text="scan.confirmation.input.timestamp" />
                    }
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: formatMessage({
                        id: 'scan.confirmation.input.timestamp.placeholder',
                      }),
                    }}
                  />
                )}
                {...field}
              />
            )}
          />
        </Box>
      </ProtectedState>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        pt={6}
        mr={2}
        ml={2}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPending || isEmpty(communicationOptions)}
        >
          <Translate text="action.continue" />
        </Button>
      </Box>
    </form>
  );
};
