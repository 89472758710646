import {
  createTheme as createMuiTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { Button } from '@mui/material';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';

const muiTheme = createMuiTheme({
  palette: {
    text: { hint: 'rgba(0, 0, 0, 0.38)' },
    mode: 'light',
    type: 'light',
    primary: { main: Colour.red, contrastText: 'white' },
  },
});

export const RedButton = ({ text, ...props }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <Button color="primary" {...props}>
        <Translate text={text} />
      </Button>
    </MuiThemeProvider>
  </StyledEngineProvider>
);
