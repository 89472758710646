import { Box, Typography } from '@mui/material';
import { CodeInput } from 'ui/theme/input/CodeInput';
import { Translate } from 'i18n/Translate';

export const IdCardCode = ({ onComplete }) => (
  <Box textAlign="center">
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      m={2}
    >
      <Typography variant="h6" align="center">
        <Translate text="login.id_card.default" />
      </Typography>
    </Box>

    <CodeInput onComplete={onComplete} />
  </Box>
);
