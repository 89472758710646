import { useCallback, useState } from 'react';
import { noop } from 'lodash-es';

/**
 * Provides simple state management for boolean values.
 *
 * The hook can be used for various use cases that require state management
 * between TRUE and FALSE. It can be used standalone or composed of hooks
 * more specific to the actual use case.
 *
 * @param initialIsTrue
 *        Bool indicating the initial boolean value.
 * @param onFalse
 *        Callback invoked after the state changed to FALSE.
 * @param onTrue
 *        Callback invoked after the state changed to TRUE.
 */
export const useBool = ({
  initialIsTrue,
  onFalse = noop,
  onTrue = noop,
} = {}) => {
  const [isTrue, setIsTrue] = useState(Boolean(initialIsTrue));

  /**
   * Action to change the state to FALSE.
   */
  const setFalse = useCallback(() => {
    setIsTrue(false);
    onFalse();
  }, [onFalse]);

  /**
   * Action to change the state to TRUE.
   */
  const setTrue = useCallback(() => {
    setIsTrue(true);
    onTrue();
  }, [onTrue]);

  /**
   * Action to toggle between TRUE and FALSE.
   */
  const toggle = useCallback(
    () => (isTrue ? setFalse() : setTrue()),
    [isTrue, setFalse, setTrue],
  );

  return {
    /* state */
    isTrue,

    /* actions */
    setFalse,
    setTrue,
    toggle,
  };
};
