import { useMemo } from 'react';
import {
  Box,
  FormControlLabel as MuiFormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { times } from 'lodash-es';
import { Controller, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { BorderedPaper } from 'ui/theme/BorderedPaper';
import { RECOVERY_VACCINATION_CODE } from '../../Confirmation/modules';

const FormControlLabel = styled(MuiFormControlLabel)`
  width: 100%;
`;

export const DoseFragment = ({ vaccines, control, clearErrors }) => {
  const intl = useIntl();

  const selectedVaccine = useWatch({
    control,
    name: 'vaccine',
  });

  const vaccine = useMemo(
    () => vaccines?.find((vac) => vac.identifier === selectedVaccine),
    [vaccines, selectedVaccine],
  );

  return selectedVaccine ? (
    <>
      <Box pt={4} pb={2}>
        <Typography variant="body1">
          <Translate text="checkin.vaccine.selectDose" />
        </Typography>
      </Box>
      <Controller
        control={control}
        name="dose"
        rules={{
          validate: {
            required: (v) =>
              parseInt(v.numberOfDoses, 10) > 0
                ? undefined
                : intl.formatMessage({
                    id: 'checkin.vaccine.radioGroup.dose.required',
                  }),
          },
        }}
        render={({
          field: { onChange, value, ...fieldProps },
          fieldState: { error },
        }) => (
          <>
            <RadioGroup
              onChange={(e) => {
                clearErrors('dose');
                onChange({
                  numberOfDoses: Number(e.target.value),
                  totalNumberOfDoses: vaccine.numberOfDosesFullVaccination,
                });
              }}
              value={value?.numberOfDoses}
              {...fieldProps}
            >
              <Grid container spacing={2}>
                {times(vaccine.numberOfDosesPossible, (x) => x + 1).map((x) => (
                  <Grid item xs={6} key={x}>
                    <Box
                      p={1}
                      pl={2}
                      display="flex"
                      alignItems="center"
                      component={BorderedPaper}
                      isSelected={value.numberOfDoses === x}
                      hasError={!!error}
                    >
                      <FormControlLabel
                        value={x}
                        control={<Radio color="primary" />}
                        label={
                          <Translate
                            text="checkin.vaccine.doseX"
                            values={{
                              part: x,
                              full: vaccine.numberOfDosesFullVaccination,
                            }}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                ))}
                {/** Special case for 1/1 vaccination for recovered people.
                 * Don´t show for vaccines that only have one dose (e.g. Janssen)
                 */}
                {vaccine.numberOfDosesFullVaccination > 1 ? (
                  <Grid item xs={6}>
                    <Box
                      p={1}
                      pl={2}
                      display="flex"
                      alignItems="center"
                      component={BorderedPaper}
                      isSelected={
                        value.numberOfDoses === RECOVERY_VACCINATION_CODE
                      }
                      hasError={!!error}
                    >
                      <FormControlLabel
                        value={RECOVERY_VACCINATION_CODE}
                        control={<Radio color="primary" />}
                        label={
                          <Translate text="checkin.vaccine.dose.recovery" />
                        }
                      />
                    </Box>
                  </Grid>
                ) : (
                  SKIP_RENDER
                )}
              </Grid>
            </RadioGroup>
            {error ? (
              <Box pt={2}>
                <FormHelperText error>{error.message}</FormHelperText>
              </Box>
            ) : (
              SKIP_RENDER
            )}
          </>
        )}
      />
    </>
  ) : (
    SKIP_RENDER
  );
};
