import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useAuth } from 'auth/AuthContext';
import { AuthenticationState } from 'constants/AuthenticationState';
import { Onboarding } from 'onboarding';
import { AuthenticatedApp } from 'AuthenticatedApp';
import { UnauthenticatedApp } from 'UnauthenticatedApp';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { getDomain } from 'utils/utils';

Sentry.init({
  dsn: 'https://8f11d3479adc4fa0964b839e2a99dce4@o76749.ingest.sentry.io/5479960',
  environment: process.env.REACT_APP_KEYPER_ENV,
  enabled: !['local', 'develop'].includes(
    process.env.REACT_APP_KEYPER_ENV.toLowerCase(),
  ),
  release: process.env.REACT_APP_VERSION,
});

const App = () => {
  useScrollToTop();

  const domain = getDomain();

  useEffect(() => {
    document.title =
      domain.includes('c02p') || domain.includes('co2p') ? 'Co2P' : 'C19T';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href =
      domain.includes('c02p') || domain.includes('co2p')
        ? `${process.env.PUBLIC_URL}/co2favicon.ico`
        : `${process.env.PUBLIC_URL}/favicon.ico`;
  }, [domain]);

  const { authState } = useAuth();

  switch (authState) {
    case AuthenticationState.AUTHENTICATED:
      return <AuthenticatedApp />;
    case AuthenticationState.TOKEN_EXCHANGE_REQUIRED:
      return <Onboarding />;
    case AuthenticationState.NOT_AUTHENTICATED:
    default:
      return <UnauthenticatedApp />;
  }
};

export default App;
