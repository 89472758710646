export const RouteParam = {
  /**
   * Self check-in mode parameter
   */
  SELF_CHECKIN: 'self',

  /**
   * Represents the login identity.
   */
  ID: 'idcard',
};

export const RouteParamValue = {
  TRUE: 'true',
};
