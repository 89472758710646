import { useCallback, useState } from 'react';
import { Stepper } from 'ui/theme/stepper/Stepper';
import { CheckInState } from '../CheckInState';
import { Confirmation } from './Confirmation';
import { DataAttachment } from './DataAttachment';

const STEPPER_STATES = {
  [CheckInState.APPOINTMENT_SCAN]: 'checkin.step.appointment',
  [CheckInState.VACCINATION_DATA_ATTACHMENT]: 'checkin.step.vaccination',
  [CheckInState.VACCINATION_CONFIRMATION]: 'checkin.step.confirmation',
};

/**
 * Data should contain two keys:
 *
 * `appointment` which is the retrieved data (and/or updated)
 * `identity` which is confirmed by user
 */
const NO_CONTEXT_DATA = undefined;

/**
 * The main Wizard view
 * @param contextData: Data should contain two keys:
 *      `appointment` which is the retrieved data (and/or updated)
 *      `identity` which is confirmed by user
 * @param onSuccess: result callback
 */
export const VaccinationCheckInFlow = ({
  contextData: initialContextData,
  onSuccess,
}) => {
  const [uiState, setUiState] = useState(
    CheckInState.VACCINATION_DATA_ATTACHMENT,
  );
  const [contextData, setContextData] = useState(initialContextData);

  const resetDataAndSwitchToAppointmentView = useCallback(() => {
    setContextData(NO_CONTEXT_DATA);
    onSuccess();
  }, [onSuccess]);

  const switchToConfirmationView = useCallback((vaccination) => {
    setContextData((existingContextData) => ({
      ...existingContextData,
      vaccination,
    }));
    setUiState(CheckInState.VACCINATION_CONFIRMATION);
  }, []);

  return (
    <>
      {/* Stepper */}
      <Stepper
        steps={Object.values(STEPPER_STATES)}
        activeStep={Object.keys(STEPPER_STATES).indexOf(uiState)}
      />

      {/* Step 2 (Attach vaccination data) */}
      {CheckInState.VACCINATION_DATA_ATTACHMENT === uiState && (
        <DataAttachment onSuccess={switchToConfirmationView} />
      )}

      {/* Step 3 (Confirmation page) */}
      {CheckInState.VACCINATION_CONFIRMATION === uiState && (
        <Confirmation
          contextData={contextData}
          onSuccess={resetDataAndSwitchToAppointmentView}
        />
      )}
    </>
  );
};
