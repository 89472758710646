import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/auth/ProtectedRoute';
import { RoutePath } from 'constants/RoutePath';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { AuthenticatedCustomerApp } from 'customer/AuthenticatedCustomerApp';
import { AdminRoles, Roles } from 'constants/Roles';
import { AuthSetupRedirect } from 'auth/AuthSetupRedirect';

const AuthenticatedAdminApp = lazy(() =>
  import('./admin/AuthenticatedAdminApp'),
);

export const AuthenticatedApp = () => (
  <Suspense fallback={<FullPageSpinner />}>
    <Switch>
      {/* It is very important to have this extracted outside the `RoutePath.ADMIN` since OIDC will fail otherwise */}
      <ProtectedRoute
        path={RoutePath.ADMIN_OAUTH_REDIRECT}
        component={AuthSetupRedirect}
        role={Roles.ORGANIZATION_MANAGEMENT}
      />

      {/* Base authenticated admin route (contains the sub routes) */}
      <ProtectedRoute
        path={RoutePath.ADMIN}
        component={AuthenticatedAdminApp}
        role={AdminRoles}
      />

      {/* Base un-authenticated customer route (contains the sub routes) */}
      <Route component={AuthenticatedCustomerApp} />
    </Switch>
  </Suspense>
);
