import { IntlDate } from '../../../../i18n/IntlDate';
import { Translate } from '../../../../i18n/Translate';
import { PersonInfoBase } from './PersonInfoBase';
import { Label, Row, Text } from './style';

export const PersonInfoProcedureGeneric = ({
  firstName,
  lastName,
  dateOfBirth,
  idNumber,
  taxNumber,
}) => (
  <PersonInfoBase>
    {firstName && (
      <Row>
        <Text>{firstName}</Text>
        <Label>
          <Translate text="cfp.procedure.base.first_name" />
        </Label>
      </Row>
    )}
    {lastName && (
      <Row>
        <Text>{lastName}</Text>
        <Label>
          <Translate text="cfp.procedure.base.last_name" />
        </Label>
      </Row>
    )}
    {dateOfBirth && (
      <Row>
        <Text>
          <IntlDate date={dateOfBirth} />
        </Text>
        <Label>
          <Translate text="cfp.procedure.base.date_of_birth" />
        </Label>
      </Row>
    )}
    {idNumber && (
      <Row>
        <Text>{idNumber}</Text>
        <Label>
          <Translate text="cfp.procedure.base.id_number" />
        </Label>
      </Row>
    )}
    {taxNumber && (
      <Row>
        <Text>{taxNumber}</Text>
        <Label>
          <Translate text="cfp.procedure.four.tax_number" />
        </Label>
      </Row>
    )}
  </PersonInfoBase>
);
