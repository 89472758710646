import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deATLocale from 'date-fns/locale/de-AT';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'ui/theme/ThemeProvider';
import { NotificationProvider } from 'components/notification/NotificationProvider';
import { AuthProvider } from 'auth/AuthContext';
import { OrganizationProvider } from 'components/organization/OrganizationProvider';
import { IntlProvider } from 'i18n/IntlProvider';
import { PendingPathProvider } from 'hooks/usePendingPath';

const DEFAULT_QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    staleTime: Number.MAX_SAFE_INTEGER,
    queries: {
      retry: 0,
    },
  },
};

export const AppProviders = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deATLocale}>
    <QueryClientProvider client={new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS)}>
      <ThemeProvider>
        <IntlProvider>
          <NotificationProvider>
            <PendingPathProvider>
              <OrganizationProvider>
                <BrowserRouter>
                  <AuthProvider>{children}</AuthProvider>
                </BrowserRouter>
              </OrganizationProvider>
            </PendingPathProvider>
          </NotificationProvider>
        </IntlProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </LocalizationProvider>
);
