import { Box, Typography } from '@mui/material';
import { IdentityTypeText } from '../../IdentityTypeText';

export const Microsoft = ({
  identity: { type, visualIdentity },
  accountName,
}) => {
  return (
    <Box>
      <Typography variant="h5">
        <IdentityTypeText type={type} />
      </Typography>

      <Box pt={2}>
        <Typography variant="body1">{visualIdentity}</Typography>
      </Box>

      {/* Account */}
      <Box pt={2}>
        <Typography variant="caption">Name</Typography>
        <Typography variant="body1">{accountName}</Typography>
      </Box>
    </Box>
  );
};
