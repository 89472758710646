import { useEffect, useState } from 'react';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { useAuth } from 'auth/AuthContext';
import { IdCardWithPermissionMatcher } from 'constants/Regexes';

export const SelfCheckinProtectedState = ({
  children = SKIP_RENDER,
  otherwise = SKIP_RENDER,
}) => {
  const [isAllowed, setIsAllowed] = useState(false);
  const { userContext } = useAuth();

  useEffect(() => {
    setIsAllowed(IdCardWithPermissionMatcher.test(userContext?.identity));
  }, [userContext?.identity]);

  return isAllowed ? children : otherwise;
};
