import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/ExitToAppOutlined';
import { Translate } from 'i18n/Translate';
import { useAuth } from 'auth/AuthContext';

export const MoreLogoutButton = () => {
  const { onLogout } = useAuth();

  const onLogoutClick = () => onLogout();

  return (
    <ListItemButton onClick={onLogoutClick}>
      <ListItemAvatar>
        <Avatar>
          <LogoutIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Translate text="more.menu.logout" />} />
    </ListItemButton>
  );
};
