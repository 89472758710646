import { Box, styled, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { readableColor } from 'polished';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/semanticConstants';
import { RelativeDate } from 'i18n/RelativeDate';
import { Translate } from 'i18n/Translate';
import { ClipboardCopy } from 'components/ClipboardCopy';
import { RouteParam } from 'constants/RouteParam';
import { RoutePath } from 'constants/RoutePath';
import { Colour } from 'ui/Colour';
import { IdentityTypeText } from '../../IdentityTypeText';
import { ResetIdCard } from './ResetIdCard';

const WarningBanner = styled(Box)`
  background-color: ${Colour.orange};
  color: ${() => readableColor(Colour.orange)};
  border-radius: 6px;
`;

const QrBox = styled(Box)`
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  background-color: white;
`;

const IdentityCopyBox = styled(Box)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IdCard = ({
  identity: { identity, type, createdAt, key, activated } = EMPTY_OBJECT,
  accountName,
  hideReset,
}) => (
  <Box>
    <Box display="flex">
      {/* QR code in box with whitespace */}
      <QrBox display="flex" p={1} mr={2}>
        <QRCode
          value={`${window.location.origin}${RoutePath.LOGIN}?${RouteParam.ID}=${identity}`}
          size={80}
        />
      </QrBox>

      {/* ID-Card information */}
      <Box minWidth={0}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">
            <IdentityTypeText type={type} />
          </Typography>
        </Box>

        <ClipboardCopy
          content={`${window.location.origin}${RoutePath.LOGIN}?${RouteParam.ID}=${identity}`}
        >
          <IdentityCopyBox pb={2} mt={1}>
            <Typography variant="body2">{identity}</Typography>
          </IdentityCopyBox>
        </ClipboardCopy>

        <Typography variant="body2">
          <Translate
            text="date.created_at"
            values={{ date: <RelativeDate date={createdAt} /> }}
          />
        </Typography>
      </Box>
    </Box>

    {/* Account and PIN */}
    <Box display="flex" justifyContent="space-between" pt={2}>
      <Box>
        <Typography variant="caption">Name</Typography>
        <Typography variant="body1">{accountName}</Typography>
      </Box>

      <Box>
        <Typography variant="caption">PIN</Typography>
        <Typography variant="body1">{key || '****'}</Typography>
      </Box>
    </Box>

    {/* Warning banner for not activated ID-Cards */}
    {activated ? (
      SKIP_RENDER
    ) : (
      <WarningBanner p={1} mt={2}>
        <Typography variant="body2">
          <Translate text="identity.idCard.notActivated" />
        </Typography>
      </WarningBanner>
    )}

    {hideReset ? (
      SKIP_RENDER
    ) : (
      <Box pt={2}>
        <ResetIdCard identity={identity} />
      </Box>
    )}
  </Box>
);
