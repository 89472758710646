import { Checkbox, FormControlLabel, Paper, styled } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';

const Layout = styled(Paper)`
  padding: 16px;
  margin-top: 16px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 0.9em;
`;

export const ResultSendHealthAuthorityNotification = ({
  data,
  sendHealthAuthorityResult,
  onSendHealthAuthorityResultChanged,
}) =>
  data?.flags?.suppress_health_authority_notification?.show ? (
    <Layout>
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={sendHealthAuthorityResult}
            name="suppress_health_authority_notification"
            color="primary"
            onChange={(event) =>
              onSendHealthAuthorityResultChanged(event.target.checked)
            }
          />
        }
        label={
          <Translate text="test.result.suppress_health_authority_notification" />
        }
      />
    </Layout>
  ) : (
    SKIP_RENDER
  );
