import { Box, Button, styled, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { RedButton } from 'ui/theme/button/RedButton';
import { Translate } from 'i18n/Translate';
import { Roles } from 'constants/Roles';
import { ProtectedState } from 'components/auth/ProtectedState';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { RoutePath } from 'constants/RoutePath';
import { RouteParam, RouteParamValue } from 'constants/RouteParam';
import { CustomizableContent } from 'components/auth/CustomizableContent';
import { KnownOrganizationId } from 'constants/KnownOrganizationId';
import { SendIdCard } from 'components/account/identity/SendIdCard';

const Layout = styled('section')`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Cfp = () => {
  const { formatMessage } = useIntl();

  const cfpCheckSection = (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      mt={6}
    >
      <Box mb={4} mt={1}>
        <Typography variant="body2" textAlign="center">
          <Translate text="cfp.info" />
        </Typography>
      </Box>
      <RedButton
        component={Link}
        to={RoutePath.CFP_CHECK}
        variant="contained"
        text="cfp.action.check"
        endIcon={<ChevronRightIcon />}
      />
      <Box mt={2}>
        <Button
          component="a"
          href="https://cfp.c19t.org"
          rel="noopener noreferrer"
          target="_blank"
          variant="secondary"
          color="secondary"
        >
          <Translate text="cfp.action.show_more" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <Layout>
      <ProtectedState role={Roles.TEST_CHECKIN} errorComponent={SKIP_RENDER}>
        <SelfCheckinProtectedState>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Box mb={2}>
              <Typography variant="body2">
                <Translate text="cfp.action.self_check_in.title" />
              </Typography>
            </Box>

            <RedButton
              component={Link}
              to={`${RoutePath.CHECKIN}?${queryString.stringify({
                [RouteParam.SELF_CHECKIN]: RouteParamValue.TRUE,
              })}`}
              variant="contained"
              text="cfp.action.self_check_in.cta"
            />

            <Box mt={2}>
              <Button
                component="a"
                href={formatMessage({
                  id: 'cfp.action.self_check_in.reference',
                })}
                rel="noopener noreferrer"
                target="_blank"
                variant="secondary"
              >
                <Translate text="cfp.action.show_more" />
              </Button>
            </Box>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            mt={5}
          >
            <Box mb={2}>
              <Typography variant="body2">
                <Translate text="cfp.action.check_in.title" />
              </Typography>
            </Box>

            <RedButton
              component={Link}
              to={RoutePath.CHECKIN}
              variant="contained"
              text="cfp.action.check_in.cta"
            />

            <Box mt={2}>
              <Button
                component="a"
                href={formatMessage({
                  id: 'cfp.action.check_in.reference',
                })}
                rel="noopener noreferrer"
                target="_blank"
                variant="secondary"
              >
                <Translate text="cfp.action.show_more" />
              </Button>
            </Box>
          </Box>

          <Box mt={5}>
            <SendIdCard />
          </Box>
        </SelfCheckinProtectedState>
      </ProtectedState>

      <SelfCheckinProtectedState otherwise={cfpCheckSection}>
        <CustomizableContent
          organizationId={KnownOrganizationId.SGQUZPUR}
          otherwise={cfpCheckSection}
        />
      </SelfCheckinProtectedState>
    </Layout>
  );
};
