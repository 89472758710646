import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useIntl } from 'react-intl';
import { isNaN } from 'lodash-es';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { Translate } from 'i18n/Translate';
import { DATE_FORMAT_PLACEHOLDER } from 'customer/cfp/constants';
import { CountryOfVaccination } from './CountryOfVaccinationFragment';

export const MetadataFragment = ({ control }) => {
  const intl = useIntl();

  const selectedVaccine = useWatch({
    control,
    name: 'vaccine',
  });

  const isConfirmation = useWatch({
    control,
    name: 'isConfirmation',
  });

  return selectedVaccine ? (
    <>
      <Box pt={4} pb={2}>
        <Typography variant="body1">
          <Translate text="checkin.vaccine.metadata" />
        </Typography>
      </Box>
      <Controller
        control={control}
        name="batchNumber"
        rules={{
          required: intl.formatMessage({
            id: 'checkin.vaccine.input.batchNumber.required',
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            size="small"
            id="batchNumber"
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{ htmlFor: 'batchNumber' }}
            label={<Translate text="checkin.vaccine.input.batchNumber" />}
            error={!!error}
            helperText={error?.message}
            {...field}
          />
        )}
      />
      <Box pt={2}>
        <Controller
          control={control}
          name="isConfirmation"
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox {...field} id="isConfirmation" color="primary" />
              }
              label={
                <Translate text="checkin.vaccine.checkbox.isConfirmation" />
              }
            />
          )}
        />
      </Box>
      {isConfirmation ? (
        <Box pt={1}>
          <Controller
            control={control}
            name="vaccinationDate"
            rules={{
              required: intl.formatMessage({
                id: 'checkin.vaccine.input.vaccinationDate.required',
              }),
              validate: (value) =>
                !isNaN(value?.getTime?.()) ||
                intl.formatMessage({
                  id: 'checkin.vaccine.input.vaccinationDate.incorrect',
                }),
            }}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                disableFuture
                mask="__.__.____"
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={!!error}
                    id="vaccinationDate"
                    name="vaccinationDate"
                    fullWidth
                    helperText={error?.message}
                    InputLabelProps={{
                      shrink: true,
                      htmlFor: 'vaccinationDate',
                    }}
                    label={
                      <Translate text="checkin.vaccine.input.vaccinationDate" />
                    }
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: DATE_FORMAT_PLACEHOLDER,
                    }}
                  />
                )}
                {...field}
              />
            )}
          />
        </Box>
      ) : (
        SKIP_RENDER
      )}
      {isConfirmation ? (
        <Box pt={1}>
          <CountryOfVaccination control={control} />
        </Box>
      ) : (
        SKIP_RENDER
      )}
    </>
  ) : (
    SKIP_RENDER
  );
};
