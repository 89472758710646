import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { isNaN } from 'lodash-es';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { Translate } from 'i18n/Translate';

export const DateOfBirthFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Controller
      control={control}
      name="dateOfBirth"
      rules={{
        required: formatMessage({
          id: 'scan.appointment.edit.input.dateOfBirth.required',
        }),
        validate: (value) =>
          !isNaN(value?.getTime?.()) ||
          formatMessage({
            id: 'scan.appointment.edit.input.dateOfBirth.incorrect',
          }),
      }}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          disabled={isDisabled}
          readOnly={isDisabled}
          disableFuture
          mask="__.__.____"
          renderInput={(params) => (
            <TextField
              size="small"
              {...params}
              error={!!error}
              id="dateOfBirth"
              name="dateOfBirth"
              fullWidth
              helperText={error?.message}
              InputLabelProps={{
                shrink: true,
                htmlFor: 'dateOfBirth',
              }}
              label={
                <Translate text="scan.appointment.edit.input.dateOfBirth" />
              }
              margin="dense"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                placeholder: formatMessage({
                  id: 'scan.appointment.edit.input.dateOfBirth.placeholder',
                }),
              }}
            />
          )}
          {...field}
        />
      )}
    />
  );
};
