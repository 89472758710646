import { Step, StepLabel, Stepper as MuiStepper, styled } from '@mui/material';
import { Translate } from 'i18n/Translate';

const classes = {
  root: 'root',
};

const StyledStepper = styled(MuiStepper)`
  padding: 16px 0;
`;

const StyledStyledStepper = styled(StyledStepper)(() => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },
}));

export const Stepper = ({ steps, activeStep }) => (
  <StyledStyledStepper activeStep={activeStep} alternativeLabel>
    {steps.map((label) => (
      <Step key={label} classes={classes}>
        <StepLabel>
          <Translate text={label} />
        </StepLabel>
      </Step>
    ))}
  </StyledStyledStepper>
);
