import { Roles, Co2Roles } from 'constants/Roles';
import { EMPTY_STRING } from 'constants/semanticConstants';

export const getRoleName = (role) => {
  switch (role) {
    case Roles.TEST_RESULT:
      return 'admin.accounts.roles.scan_result';
    case Roles.TEST_CHECKIN:
      return 'admin.accounts.roles.checkin.test.write';
    case Roles.VACCINATION_CHECKIN:
      return 'admin.accounts.roles.checkin.vaccination.write';
    case Roles.ORGANIZATION_MANAGEMENT:
      return 'admin.accounts.roles.organization.manage';
    case Roles.ORGANIZATION_TEST_MANAGEMENT:
      return 'admin.accounts.roles.organization.test.manage';
    case Roles.SUPPORT:
      return 'admin.accounts.roles.support';
    case Co2Roles.CERTIFICATE_CHECKIN:
      return 'admin.accounts.roles.checkin.certificate.write';
    case Co2Roles.ORGANIZATION_CERTIFICATE_MANAGEMENT:
      return 'admin.accounts.roles.organization.certificate.manage';

    default:
      return EMPTY_STRING;
  }
};
export const getCo2RoleName = (role) => {
  switch (role) {
    case Roles.TEST_RESULT:
      return 'admin.accounts.roles.scan_result';
    case Roles.VACCINATION_CHECKIN:
      return 'admin.accounts.roles.checkin.vaccination.write';
    case Roles.ORGANIZATION_MANAGEMENT:
      return 'admin.accounts.roles.organization.manage';
    case Roles.SUPPORT:
      return 'admin.accounts.roles.support';
    case Co2Roles.CERTIFICATE_CHECKIN:
      return 'admin.accounts.roles.checkin.certificate.write';
    case Co2Roles.ORGANIZATION_CERTIFICATE_MANAGEMENT:
      return 'admin.accounts.roles.organization.certificate.manage';

    default:
      return EMPTY_STRING;
  }
};
