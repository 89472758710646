import { useCallback, useState } from 'react';
import { Stepper } from 'ui/theme/stepper/Stepper';
import { CheckInState } from '../CheckInState';
import { SampleAttachment } from './SampleAttachment';
import { Confirmation } from './Confirmation';

const STEPPER_STATES = {
  [CheckInState.APPOINTMENT_SCAN]: 'checkin.step.appointment',
  [CheckInState.TEST_SAMPLE_ATTACHMENT]: 'checkin.step.sample_attachment',
  [CheckInState.TEST_CONFIRMATION]: 'checkin.step.confirmation',
};

/**
 * Data should contain two keys:
 *
 * `appointment` which is the retrieved data (and/or updated)
 * `identity` which is confirmed by user
 */
const NO_CONTEXT_DATA = undefined;

/**
 * The main Wizard view
 */
export const TestCheckInFlow = ({
  contextData: initialContextData,
  onSuccess,
}) => {
  const [uiState, setUiState] = useState(CheckInState.TEST_SAMPLE_ATTACHMENT);
  const [contextData, setContextData] = useState(initialContextData);

  const resetDataAndSwitchToAppointmentView = useCallback(() => {
    setContextData(NO_CONTEXT_DATA);
    onSuccess();
  }, [onSuccess]);

  const switchToConfirmationView = useCallback((testCode) => {
    setContextData((existingContextData) => ({
      ...existingContextData,
      testCode,
    }));
    setUiState(CheckInState.TEST_CONFIRMATION);
  }, []);

  const switchToSampleAttachmentView = () =>
    setUiState(CheckInState.TEST_SAMPLE_ATTACHMENT);

  return (
    <>
      {/* Stepper */}
      <Stepper
        steps={Object.values(STEPPER_STATES)}
        activeStep={Object.keys(STEPPER_STATES).indexOf(uiState)}
      />

      {/* Step 2 (Add sample attachment) */}
      {CheckInState.TEST_SAMPLE_ATTACHMENT === uiState && (
        <SampleAttachment
          validationRegex={contextData?.appointment?.test?.validationRegex}
          onSuccess={switchToConfirmationView}
        />
      )}

      {/* Step 3 (Confirmation page) */}
      {CheckInState.TEST_CONFIRMATION === uiState && (
        <Confirmation
          contextData={contextData}
          onSuccess={resetDataAndSwitchToAppointmentView}
          onError={switchToSampleAttachmentView}
        />
      )}
    </>
  );
};
