import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';

const useResetIdCard = ({ identity, onSuccess = noop, onError = noop }) => {
  const { put } = useApi();
  const { showNotification } = useNotification();

  return useMutation(() => put(`identity/idcard/${identity}/reset`), {
    onSuccess: (data) => {
      showNotification(SUCCESS_NOTIFICATION, 'identity.idCard.reset.success');

      onSuccess(data);
    },
    onError: () => {
      showNotification(ERROR_NOTIFICATION, 'identity.idCard.reset.error');

      onError();
    },
  });
};

export default useResetIdCard;
