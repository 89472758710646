import { ExpirationDates } from 'constants/ExpirationDate';
import { EMPTY_STRING } from 'constants/semanticConstants';

export const getExpirationDate = (expirationDate) => {
  switch (expirationDate) {
    case ExpirationDates.SEVEN_DAYS:
      return 'profile.personalAccessToken.expiration.sevenDays';
    case ExpirationDates.THIRTY_DAYS:
      return 'profile.personalAccessToken.expiration.thirtyDays';
    case ExpirationDates.SIXTY_DAYS:
      return 'profile.personalAccessToken.expiration.sixtyDays';
    case ExpirationDates.NINTY_DAYS:
      return 'profile.personalAccessToken.expiration.nintyDays';
    default:
      return EMPTY_STRING;
  }
};
