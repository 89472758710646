import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { StorageKey } from 'constants/StorageKey';
import { useLocalStorage } from 'hooks/useLocalStorage';

const SENTRY_ORGANIZATION_TAG = 'organization';

export const OrganizationContext = createContext({
  current: null,
  organizations: [],
});

export const OrganizationProvider = ({ children, initialValue = null }) => {
  const [currentLs, setCurrentLs] = useLocalStorage(
    StorageKey.HPS_ORGANIZATIONS_CURRENT,
    initialValue,
  );
  const [organizationsLS, setOrganizationsLS] = useLocalStorage(
    StorageKey.HPS_ORGANIZATIONS,
    [],
  );
  const [current, setCurrentInternal] = useState(currentLs);
  const [organizations, setOrganizationsInternal] = useState(organizationsLS);

  const setCurrent = useCallback(
    (nextOrganization) => {
      setCurrentLs(nextOrganization);
      setCurrentInternal(nextOrganization);
    },
    [setCurrentLs, setCurrentInternal],
  );

  const setOrganizations = useCallback(
    (nextOrganizations) => {
      setOrganizationsInternal(nextOrganizations);
      setOrganizationsLS(nextOrganizations);
    },
    [setOrganizationsInternal, setOrganizationsLS],
  );

  useEffect(() => {
    Sentry.setTag(SENTRY_ORGANIZATION_TAG, current?.organizationId);
  }, [current?.organizationId]);

  const context = useMemo(
    () => ({
      current,
      organizations,
      setCurrent,
      setOrganizations,
    }),
    [current, organizations, setCurrent, setOrganizations],
  );

  return (
    <OrganizationContext.Provider value={context}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);
