import { Box, styled, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/BlockOutlined';
import CloseIcon from '@mui/icons-material/CloseRounded';
import TrashIcon from '@mui/icons-material/DeleteForeverOutlined';
import Icon from '@mui/material/Icon';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { AppointmentState } from 'constants/AppointmentState';
import { Colour } from 'ui/Colour';

const StyledIcon = styled(Icon)`
  margin-bottom: 16px;
  font-size: 4rem;
  opacity: 0.8;
`;

const Container = styled(Box)`
  color: ${Colour.red};
`;

const Texts = {
  [AppointmentState.CANCELLED]: 'scan.appointment.state.cancelled',
  [AppointmentState.OTHER_ORGANIZATION]:
    'scan.appointment.state.otherOrganization',
  [AppointmentState.UNKNOWN]: 'scan.appointment.state.unknown',
};

const Icons = {
  [AppointmentState.CANCELLED]: TrashIcon,
  [AppointmentState.OTHER_ORGANIZATION]: BlockIcon,
  [AppointmentState.UNKNOWN]: CloseIcon,
};

export const ErrorStatus = ({ state }) =>
  state ? (
    <Container
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      py={4}
      px={2}
      my={4}
    >
      <StyledIcon component={Icons[state]} />
      <Typography variant="h6" align="center">
        <Translate text={Texts[state]} />
      </Typography>
    </Container>
  ) : (
    SKIP_RENDER
  );
