import {
  Box,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { readableColor } from 'polished';
import { useParams } from 'react-router-dom';
import { Colour } from 'ui/Colour';
import { IntlDate } from 'i18n/IntlDate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { useCo2Certificate } from 'hooks/useCo2Certificate';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0),
  },
}));

const ColoredListItemSecondaryText = styled(({ backgroundColor, ...props }) => (
  <Typography variant="body1" {...props} />
))`
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
          width: fit-content;
          padding: 4px 8px;
          border-radius: 4px;
          background-color: ${backgroundColor};
          color: ${readableColor(backgroundColor)};
        `}
`;
const ListEntry = ({ messageId, data, color }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return data ? (
    <ListItem dense>
      <ListItemText
        className={classes.root}
        disableTypography
        primary={
          <Typography variant="caption">
            {formatMessage({ id: messageId })}
          </Typography>
        }
        secondary={
          <ColoredListItemSecondaryText backgroundColor={color}>
            {data}
          </ColoredListItemSecondaryText>
        }
      />
    </ListItem>
  ) : (
    SKIP_RENDER
  );
};

export const CertificateDetails = () => {
  const { externalReference } = useParams();

  const { data: certificate, isLoading } = useCo2Certificate({
    certificateReference: externalReference,
  });

  return isLoading ? (
    <FullPageSpinner />
  ) : (
    <Box component="section" bgcolor={Colour.background}>
      <List dense>
        <ListEntry messageId="test.type" data={certificate.test_type} />
        <ListEntry
          messageId="co2.certificate.externalReference"
          data={certificate.external_reference}
        />
        <ListEntry
          messageId="co2.certificate.co2e"
          data={certificate.certificateInfo.co2E}
        />
        <ListEntry
          messageId="co2.certificate.certifiedBy"
          data={certificate.certificateInfo.certifiedBy}
        />
        <ListEntry
          messageId="co2.certificate.startDate"
          data={
            <IntlDate date={certificate.certificateInfo.timePeriod.start} />
          }
        />
        <ListEntry
          messageId="co2.certificate.endDate"
          data={<IntlDate date={certificate.certificateInfo.timePeriod.end} />}
        />
      </List>
    </Box>
  );
};
