import { FormattedDate } from 'react-intl';

export const IntlDate = ({ date }) =>
  date ? (
    <FormattedDate
      value={date}
      day="2-digit"
      month="2-digit"
      timeZone="UTC"
      year="numeric"
    />
  ) : (
    '-'
  );
