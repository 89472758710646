export const EMPTY_OBJECT = Object.freeze({});
export const UNDEFINED = undefined;
export const EMPTY_STRING = '';
export const EN_DASH = '—';
export const CONCAT_STRING = ', ';
export const EMPTY_ARRAY = Object.freeze([]);
export const SKIP_RENDER = null;
export const DEFAULT_INITIAL_REF = null;
export const EMPTY_REGEX = /(.*?)/;
// See https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#:~:text=Preventing%20autofilling%20with%20autocomplete%3D%22new-password%22
export const PREVENT_AUTOFILL_HINT = 'new-password';

/**
 * Max rendered results per page.
 */
export const MAX_NUMBER_OF_RESULTS_PER_PAGE = 100;

export const BACKDROP_CLICK_EVENT = 'backdropClick';

/**
 * debounce submit milliseconds across our forms
 */
export const DEBOUNCE_SUBMIT_MILLIS = 300;
