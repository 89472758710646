import { Box, styled, Typography } from '@mui/material';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { RedButton } from 'ui/theme/button/RedButton';
import { Translate } from 'i18n/Translate';
import { useCancelAccount } from './useCancelAccount';

const AppLogo = styled('img')`
  max-width: 200px;
`;

export const View = () => {
  const { isLoading, mutate: mutateByCancelingAccount } = useCancelAccount();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={2}
    >
      {/* Loading */}
      <BackdropSpinner isOpen={isLoading} />

      {/* Logo */}
      <AppLogo src={`${process.env.PUBLIC_URL}/cfplogo.svg`} />

      {/* Title */}
      <Box mt={30} p={3}>
        <Typography variant="h6" align="center">
          <Translate text="cancel.account.title" />
        </Typography>
      </Box>

      {/* Account removal */}
      <Box mt={1}>
        <RedButton
          disabled={isLoading}
          onClick={mutateByCancelingAccount}
          text="cancel.account.button"
          variant="contained"
        />
      </Box>
    </Box>
  );
};
