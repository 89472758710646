import { identity as truthyKeys, pickBy } from 'lodash-es';
import { isDate } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { camelToSnakeCase } from 'utils/utils';
import { DATE_FORMAT } from 'customer/cfp/constants';
import { UNDEFINED } from 'constants/semanticConstants';

const getNormalizedDateOfBirth = (dateOfBirth) => {
  if (!dateOfBirth) {
    return UNDEFINED;
  }
  return format(
    isDate(dateOfBirth) ? dateOfBirth : parseISO(dateOfBirth),
    DATE_FORMAT,
  );
};

/**
 * Computes the test scan execution payload from the given context and test code.
 */
export const computeAppointmentUpdatePayload = ({
  dateOfBirth,
  emailAddress,
  firstName,
  idNumber,
  insuranceCardNumber,
  lastName,
  phoneNumber,
  testType,
}) => {
  const truthyPII = pickBy(
    {
      firstName,
      lastName,
      dateOfBirth: getNormalizedDateOfBirth(dateOfBirth),
      emailAddress,
      idNumber,
      insuranceCardNumber,
      phoneNumber,
    },
    truthyKeys,
  );

  return camelToSnakeCase({
    pii: truthyPII,
    service: {
      id: testType,
    },
  });
};
