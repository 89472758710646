import { useCallback } from 'react';
import { attachOrganizationSlug } from 'constants/RoutePath';
import { useOrganization } from 'components/organization/OrganizationProvider';

export const useOrganizationSlug = () => {
  const { current } = useOrganization();

  const withOrganizationSlug = useCallback(
    (unCompiledPath, organizationId = current?.organizationId) =>
      attachOrganizationSlug(organizationId, unCompiledPath),
    [current?.organizationId],
  );

  return {
    withOrganizationSlug,
  };
};
