import { useCallback, useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { size } from 'lodash-es';
import { IntlDateTime } from 'i18n/IntlDateTime';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { snakeToCamelCase } from 'utils/utils';

const Row = ({ certificate, onRowClick }) => {
  const { createdAt, externalReference, certificateInfo } = useMemo(
    () => snakeToCamelCase(certificate),
    [certificate],
  );

  const onClick = useCallback(
    () => onRowClick(certificate),
    [certificate, onRowClick],
  );

  return (
    <TableRow hover onClick={onClick}>
      <TableCell>
        <IntlDateTime date={createdAt} />
      </TableCell>

      <TableCell>{externalReference}</TableCell>

      <TableCell>{certificateInfo.co2E}</TableCell>

      <TableCell>{certificateInfo.timePeriod.start}</TableCell>

      <TableCell>{certificateInfo.timePeriod.end}</TableCell>
    </TableRow>
  );
};
export const CertificatesList = ({ certificates, onRowClick }) => {
  return size(certificates) ? (
    <Box width={1} mt={3}>
      <Box p={1} pl={2}>
        <Typography variant="subtitle2">
          <Translate
            text="co2.certificates.count"
            values={{ count: certificates.length }}
          />
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Translate text="admin.tests.table.date" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.reference" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.certificate.value" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.certificate.startDate" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.certificate.endDate" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {certificates.map((certificate) => (
              <Row
                key={certificate.test_code}
                certificate={certificate}
                onRowClick={onRowClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    SKIP_RENDER
  );
};
