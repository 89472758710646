import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';
import { RouteParam, RouteParamValue } from 'constants/RouteParam';
import { useAuth } from 'auth/AuthContext';
import { SKIP_RENDER } from 'constants/semanticConstants';

export const SelfAutomator = ({ onIdentifiedAppointmentId }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { userContext } = useAuth();

  useEffect(() => {
    const isSelfCheckInEnabled = isEqual(
      RouteParamValue.TRUE,
      queryString.parse(search)?.[RouteParam.SELF_CHECKIN],
    );

    if (!isSelfCheckInEnabled) {
      return;
    }

    onIdentifiedAppointmentId(userContext?.identity);

    // ---
    // Remove the self check-in query parameter.
    history.replace(pathname);
  }, [onIdentifiedAppointmentId, userContext, search, history, pathname]);

  return SKIP_RENDER;
};
