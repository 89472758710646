import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, styled, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { NotificationContext } from 'components/notification/NotificationContext';
import { SUCCESS_NOTIFICATION } from 'components/notification/notificationType';
import { Colour } from 'ui/Colour';

const ContentBox = styled(Box)`
  cursor: pointer;

  &:hover {
    color: ${Colour.textMid};
  }
`;

export const ClipboardCopy = ({ content, children }) => {
  const { formatMessage } = useIntl();
  const { showNotification } = useContext(NotificationContext);

  return (
    <CopyToClipboard
      text={content}
      onCopy={() => showNotification(SUCCESS_NOTIFICATION, 'clipboard.copy')}
    >
      <Tooltip
        onClick={(event) => event.stopPropagation()}
        title={formatMessage({ id: 'clipboard.copy.tooltip' })}
        placement="top-start"
        arrow
      >
        <ContentBox>{children || content}</ContentBox>
      </Tooltip>
    </CopyToClipboard>
  );
};
