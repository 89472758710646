import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which triggers a vaccination checkin execution
 *
 * @param contextData the data we're expecting
 * @param onSuccess a callback called with the given parameters when the operation is succeeded
 */
const useTriggerVaccinationCheckIn = ({ onSuccess = noop }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  return useMutation(({ payload }) => post(`checkin/vaccine`, payload), {
    onSuccess: () => {
      showNotification(SUCCESS_NOTIFICATION, 'checkin.vaccine.success');

      onSuccess();
    },
    onError: () =>
      showNotification(ERROR_NOTIFICATION, 'checkin.vaccine.error'),
  });
};

export default useTriggerVaccinationCheckIn;
