import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  EMPTY_OBJECT,
  SKIP_RENDER,
  UNDEFINED,
} from 'constants/semanticConstants';
import { WarningBox } from 'ui/WarningBox';
import { WarningInfoIcon } from 'ui/WarningInfoIcon';
import { Translate } from 'i18n/Translate';
import { CenterSpinner } from 'components/CenterSpinner';
import { useLookupTests } from './useLookupTests';
import { getLookupCriteriaFrom } from './modules';
import { SearchForm } from './SearchForm';
import { LookupMatch } from './LookupMatch';

/**
 * Component which looks up for a given test
 */
export const View = ({ onSuccessMatch }) => {
  const [lookupCriteria, setLookupCriteria] = useState(UNDEFINED);

  /**
   * Auto-fetch hook which controls the fetch of the tests.
   *
   * Disabled by default; enabled only when `lookupCriteria` is truthy.
   */
  const {
    isLoading,
    data: matchingTest = EMPTY_OBJECT,
    isSuccess,
    isError,
  } = useLookupTests({
    lookupCriteria,
  });

  /**
   * Indirectly fires the lookup by adjusting the query params
   */
  const updateLookupCriteria = useCallback(({ testLookup } = EMPTY_OBJECT) => {
    const nextLookupCriteria = getLookupCriteriaFrom(testLookup);

    setLookupCriteria(nextLookupCriteria);
  }, []);

  /**
   * No match content (no match or inconclusive)
   */
  const noMatchContent = (
    <WarningBox p={1} display="flex" alignItems="center">
      <WarningInfoIcon />

      <Translate text="scan.appointment.test_lookup.not_found" />
    </WarningBox>
  );

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <SearchForm onSubmit={updateLookupCriteria} />

      {isLoading ? <CenterSpinner /> : SKIP_RENDER}

      <Box mt={1}>
        {isSuccess ? (
          <LookupMatch test={matchingTest} onClick={onSuccessMatch} />
        ) : (
          SKIP_RENDER
        )}

        {isError ? noMatchContent : SKIP_RENDER}
      </Box>
    </Box>
  );
};
