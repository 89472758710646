import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { Translate } from 'i18n/Translate';
import { ClipboardCopy } from 'components/ClipboardCopy';

export const PersonalAccessTokenResultDialog = ({
  accessToken,
  isOpen,
  isPending,
  onClose,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
    >
      <BackdropSpinner isOpen={isPending} />
      <DialogTitle id="form-dialog-title">
        <Translate text="profile.personalAccessToken.add.dialog.result" />
      </DialogTitle>
      <DialogContent>
        <ClipboardCopy content={accessToken}>
          <Typography variant="body1" color="primary">
            {accessToken}
          </Typography>
        </ClipboardCopy>
      </DialogContent>
      <DialogActions>
        <Box mt={2} p={2}>
          <Button onClick={onClose} color="secondary" disabled={isPending}>
            <Translate text="action.cancel" />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
