import { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { first } from 'lodash-es';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Language } from 'ui/Language';
import EnLocale from './locales/en.json';

export const IntlProvider = ({ children }) => {
  const [messages, setMessages] = useState(EnLocale);
  const language = useMemo(
    () => first(window?.navigator?.language?.split(/[-_]/)),
    [],
  );

  useEffect(() => {
    if ([Language.EN, Language.DE].includes(language)) {
      import(`./locales/${language}.json`).then((data) =>
        setMessages(data.default),
      );
      Sentry.setTag('locale', language);
    }
  }, [language]);

  return (
    <ReactIntlProvider
      defaultLocale={Language.DE}
      locale={language}
      messages={messages}
    >
      {children}
    </ReactIntlProvider>
  );
};
