import { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash-es';
import { Box, Typography } from '@mui/material';
import useCheckInOptions from 'hooks/useCheckInOptions';
import { Translate } from 'i18n/Translate';
import { IntlDate } from 'i18n/IntlDate';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/semanticConstants';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { BackButton } from './BackButton';
import { computeCheckinPayload } from './modules';
import useTriggerTestCheckIn from './useTriggerTestCheckIn';
import { ConfirmationOptions } from './ConfirmationOptions';
import { SuccessButton } from './SuccessButton';
import { ConfirmationTestLabel } from './ConfirmationTestLabel';

/**
 * API error types which will yield automatically going back to
 * the previous page.
 */
const AUTO_RETRY_ERRORS = [
  'test_not_allowed_or_not_existing',
  'test_already_used',
];

const UIState = {
  /**
   * The form displaying the channels to be picked.
   */
  FORM: 'FORM',

  /**
   * The submission has been finalized.
   */
  READY: 'READY',

  /**
   * The submission is erroneous.
   */
  ERRONEOUS: 'ERRONEOUS',
};

export const Confirmation = ({
  contextData,
  contextData: {
    appointment: {
      pii: { dateOfBirth, firstName, lastName } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    testCode,
  } = EMPTY_OBJECT,
  onSuccess = noop,
  onError = noop,
}) => {
  const {
    isLoading: isFetchingCheckInOptionsInProgress,
    data: checkInOptions,
  } = useCheckInOptions();

  const [uiState, setUiState] = useState(UIState.FORM);

  const onConfirmationSuccess = () => setUiState(UIState.READY);

  const reactOnError = (errorType) => {
    setUiState(UIState.ERRONEOUS);

    if (!AUTO_RETRY_ERRORS.includes(errorType)) {
      return;
    }

    onError();
  };

  const { mutate, isLoading: isUpdateInProgress } = useTriggerTestCheckIn({
    onSuccess: onConfirmationSuccess,
    onError: reactOnError,
  });

  const isPending = useMemo(
    () => Boolean(isUpdateInProgress || isFetchingCheckInOptionsInProgress),
    [isUpdateInProgress, isFetchingCheckInOptionsInProgress],
  );

  const onSubmit = useCallback(
    (formValues) => {
      mutate({
        testCode,
        payload: computeCheckinPayload(contextData, formValues),
      });
    },
    [contextData, testCode, mutate],
  );

  const renderContent = () => {
    switch (uiState) {
      case UIState.FORM:
        return (
          checkInOptions && (
            <ConfirmationOptions
              checkInOptions={checkInOptions}
              isPending={isPending}
              onSubmit={onSubmit}
            />
          )
        );
      case UIState.READY:
        return (
          <>
            <ConfirmationTestLabel testCode={testCode} />
            <SuccessButton onClick={onSuccess} />
          </>
        );
      case UIState.ERRONEOUS:
        return <BackButton onClick={onError} />;
      default:
        return SKIP_RENDER;
    }
  };

  return (
    <Box>
      {/* Loading spinner */}
      <BackdropSpinner isOpen={isPending} />

      {/* Headline */}
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pt={2}
      >
        <Typography variant="h6" align="center">
          <Translate text="scan.confirmation.channel.title" />
        </Typography>

        <Typography variant="body1" align="center">
          {firstName} {lastName}
        </Typography>

        <Typography variant="body1" align="center">
          <IntlDate date={dateOfBirth} />
        </Typography>
      </Box>

      {/* Controls */}
      <Box display="flex" justifyContent="center" mt={2} width={1}>
        <Box
          display="flex"
          flexBasis="100%"
          flexDirection="column"
          justifyContent="center"
          maxWidth={500}
        >
          {renderContent()}
        </Box>
      </Box>
    </Box>
  );
};
