import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'booking/appointment/';

/**
 * Hook which updates an appointment with the given `appointmentID` and `payload`
 *
 * @param onSettled a callback called with the given parameters when the API call is settled
 */
const useUpdateAppointment = ({ onSettled = noop }) => {
  const { put } = useApi();
  const { showNotification } = useNotification();

  return useMutation(
    ({ appointmentID, payload }) =>
      put(`${API_RESOURCE}${appointmentID}`, payload),
    {
      onSuccess: () =>
        showNotification(SUCCESS_NOTIFICATION, 'scan.appointment.edit.success'),
      onError: () =>
        showNotification(ERROR_NOTIFICATION, 'scan.appointment.edit.error'),
      onSettled: (_1, _2, { appointmentID }) => onSettled(appointmentID),
    },
  );
};

export default useUpdateAppointment;
