export const TestResult = {
  COMPLEX: 'complex',
  DONE: 'done',
  INVALID: 'invalid',
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
  UNKNOWN: 'unknown',
};

export const Co2Result = {
  DONE: 'done',
  VERIFIED: 'verified',
  INVALID: 'invalid',
  UNKNOWN: 'unknown',
};
