import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'checkin/options';

/**
 * Hook which returns the check-in options for a test result.
 */
const useCheckInOptions = () => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(QueryKeys.FETCH_CHECK_IN_OPTIONS, () => get(API_RESOURCE), {
    onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
  });
};

export default useCheckInOptions;
