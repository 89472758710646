import { FormattedRelativeTime } from 'react-intl';
import { Translate } from './Translate';

const getDateDiff = (date) => (new Date() - date) / 1000;

export const RelativeDate = ({ date }) => {
  if (!date) {
    return <Translate text="date.missing" />;
  }

  const diff = getDateDiff(new Date(date));

  return (
    <FormattedRelativeTime
      value={-diff}
      numeric="auto"
      updateIntervalInSeconds={1}
    />
  );
};
