import { useCallback, useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import format from 'date-fns/format';
import { parseInt } from 'lodash-es';
import { Translate } from 'i18n/Translate';
import { useBool } from 'hooks/useBool';
import { DATE_FORMAT } from 'customer/cfp/constants';
import { EMPTY_OBJECT, EMPTY_STRING } from 'constants/semanticConstants';
import { CenterSpinner } from 'components/CenterSpinner';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { useSingleAccount } from 'admin/accounts/hooks/useSingleAccount';
import { useGeneratePersonalAccessToken } from './useGeneratePersonalAccessToken';
import { PersonalAccessTokenDialog } from './PersonalAccessTokenDialog';
import { PersonalAccessTokenResultDialog } from './PersonalAccessTokenResultDialog';

export const PersonalAccessTokenAdd = ({
  accountKey = EMPTY_STRING,
  onReloadData,
}) => {
  const {
    isTrue: isDialogOpened,
    setTrue: openDialog,
    setFalse: closeDialog,
  } = useBool();

  const currentDate = new Date();
  const [personalAccessToken, setPersonalAccessToken] = useState('');
  const [openResult, setOpenResult] = useState(false);

  const {
    current: { organizationId, groupName },
  } = useOrganization();

  const { data: { roles } = EMPTY_OBJECT, isLoading: isFetchAccountLoading } =
    useSingleAccount({
      accountKey,
      organizationId,
    });

  const onSuccess = (data) => {
    setPersonalAccessToken(data.token);
    handleClose();
    setOpenResult(true);
  };

  const { mutate: generateAccessToken, isLoading: isPending } =
    useGeneratePersonalAccessToken({
      onSuccess,
    });

  const handleClose = useCallback(() => {
    closeDialog();
    setOpenResult(false);
    onReloadData();
  }, [closeDialog, onReloadData]);

  const getExpirationDate = (value) => {
    const numberOfDays = parseInt(value.split(' ')[0]);

    return format(
      new Date(
        new Date(currentDate).setDate(currentDate.getDate() + numberOfDays),
      ),
      DATE_FORMAT,
    );
  };

  const handleSubmit = async (formValues) => {
    const { expireDate } = formValues;

    const formatExpireDate = getExpirationDate(expireDate);

    generateAccessToken({
      payload: { ...formValues, expireDate: formatExpireDate },
    });
  };

  return isFetchAccountLoading || isPending ? (
    <CenterSpinner />
  ) : (
    <>
      <ListSubheader disableSticky>
        <Translate text="profile.personalAccessToken.add" />
      </ListSubheader>
      <ListItemButton key="add" onClick={openDialog}>
        <ListItemAvatar>
          <Avatar>
            <VpnKeyTwoToneIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Translate text="profile.personalAccessToken.generate" />}
        />
      </ListItemButton>
      <PersonalAccessTokenDialog
        accountRoles={roles}
        isOpen={isDialogOpened}
        isPending={false}
        onClose={handleClose}
        onSubmit={handleSubmit}
        groupName={groupName}
      />
      <PersonalAccessTokenResultDialog
        accessToken={personalAccessToken}
        isOpen={openResult}
        isPending={false}
        onClose={handleClose}
      />
    </>
  );
};
