export const CheckInState = {
  // ---
  // Appointment related.
  // ---
  APPOINTMENT_SCAN: 'APPOINTMENT_SCAN',
  APPOINTMENT_ATTACHMENT: 'APPOINTMENT_ATTACHMENT',
  APPOINTMENT_CONFIRMATION: 'APPOINTMENT_CONFIRMATION',

  // ---
  // Test related.
  // ---
  TEST_SAMPLE_ATTACHMENT: 'TEST_SAMPLE_ATTACHMENT',
  TEST_CONFIRMATION: 'TEST_CONFIRMATION',

  // ---
  // Vaccination related.
  // ---
  VACCINATION_DATA_ATTACHMENT: 'VACCINATION_DATA_ATTACHMENT',
  VACCINATION_CONFIRMATION: 'VACCINATION_CONFIRMATION',
};
