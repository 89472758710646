import { Box, Button, Grid } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { TestCodeLabel } from 'components/test/TestCodeLabel';
import { InfoBox } from 'ui/InfoBox';

export const LookupMatch = ({ test, onClick }) => (
  <>
    <InfoBox>
      <Translate text="scan.appointment.test_lookup.found" />
    </InfoBox>

    <Box mt={1} p={2}>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <TestCodeLabel testCode={test?.testCode} />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            onClick={() => onClick(test?.testCode)}
            color="primary"
            variant="contained"
          >
            <Translate text="scan.appointment.test_lookup.cta" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  </>
);
