import { Avatar, styled, Typography } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
import { TestResult } from 'constants/TestResult';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';
import { ResultSelector } from './ResultSelector';

const PositiveTestIcon = styled(Avatar)`
  background-color: ${Colour.red};
  margin-right: 16px;
`;

export const OptionPositive = ({ onClick }) => (
  <ResultSelector
    focusRipple
    color={Colour.red}
    onClick={() => onClick(TestResult.POSITIVE)}
  >
    <PositiveTestIcon>
      <PlusIcon />
    </PositiveTestIcon>
    <Typography variant="body1">
      <Translate text="scan.result.positive" />
    </Typography>
  </ResultSelector>
);
