import { useContext } from 'react';
import { useQuery } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import { NotificationContext } from 'components/notification/NotificationContext';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';

/**
 * Hook which returns the state of a check-in
 */
const useCheckInState = ({
  isOldURLFormat,
  scannedValue,
  onSuccess = noop,
  onError = noop,
}) => {
  const { get } = useApi();
  const { showNotification } = useContext(NotificationContext);

  return useQuery(
    [QueryKeys.CHECKIN_STATE, isOldURLFormat, scannedValue],
    () =>
      get(
        isOldURLFormat
          ? `test/external/${scannedValue}/execution`
          : `test/${scannedValue}/execution`,
      ),
    {
      onSuccess,
      onError: () => {
        showNotification(ERROR_NOTIFICATION, 'state.error');
        onError();
      },
      refetchOnWindowFocus: false,
    },
  );
};

export default useCheckInState;
