import { styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Translate } from 'i18n/Translate';
import { IntlDate } from 'i18n/IntlDate';
import { Colour } from 'ui/Colour';
import { ClipboardCopy } from 'components/ClipboardCopy';
import { useCo2Project } from 'hooks/useCo2Project';
import { FullPageSpinner } from 'components/FullPageSpinner';

const Container = styled('div')`
  position: relative;
`;

const ElementContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'noMargin',
})`
  padding-bottom: ${(props) => (props.noMargin ? '0px' : '16px')};
`;

const Title = styled('h5')`
  color: ${Colour.textMid};
  font-weight: normal;
  margin: 0;
`;
export const ProjectData = () => {
  const { externalReference } = useParams();

  const { data: project, isLoading } = useCo2Project({
    projectReference: externalReference,
  });

  return isLoading ? (
    <FullPageSpinner />
  ) : (
    <Container>
      <ElementContainer>
        <Typography variant="h6">{project.projectInfo.name}</Typography>
        <Title>
          <Translate text="co2.project.name" />
        </Title>
      </ElementContainer>

      <ElementContainer>
        <ClipboardCopy content={project.projectInfo.type}>
          <Typography variant="body1">{project.projectInfo.type}</Typography>
        </ClipboardCopy>
        <Title>
          <Translate text="co2.project.type" />
        </Title>
      </ElementContainer>

      <ElementContainer>
        <ClipboardCopy content={project.projectInfo.updates}>
          <Typography variant="body1">{project.projectInfo.updates}</Typography>
        </ClipboardCopy>
        <Title>
          <Translate text="co2.project.updates" />
        </Title>
      </ElementContainer>

      <ElementContainer>
        <ClipboardCopy content={project.projectInfo.startDate}>
          <Typography variant="body1">
            <IntlDate date={project.projectInfo.startDate} />
          </Typography>
        </ClipboardCopy>
        <Title>
          <Translate text="co2.project.startDate" />
        </Title>
      </ElementContainer>
    </Container>
  );
};
