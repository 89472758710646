import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { useAuth } from 'auth/AuthContext';

const TreeSwing = styled('img')`
  max-width: 500px;
  width: 50%;
  padding: 5%;
`;

export const EmptyState = () => {
  const { formatMessage } = useIntl();
  const { onLogout } = useAuth();

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      mt={3}
      p={2}
    >
      <TreeSwing
        src={`${process.env.PUBLIC_URL}/illustrations/tree_swing.svg`}
        alt={formatMessage({ id: 'alt.tree_swing' })}
      />
      <Box my={2}>
        <Typography variant="h5" align="center">
          <Translate text="onboarding.selectOrganization.empty" />
        </Typography>
      </Box>
      <Box py={2}>
        <Typography variant="body2">
          <Translate text="onboarding.selectOrganization.empty.info" />
        </Typography>
      </Box>
      <Grid
        container
        component={Box}
        pt={3}
        display="flex"
        justifyContent="center"
      >
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onLogout}
          >
            <Translate text="onboarding.selectOrganization.backToLogin" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
