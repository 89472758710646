import { Box, Paper, styled, Typography } from '@mui/material';
import { readableColor } from 'polished';
import QuizIcon from '@mui/icons-material/QuizOutlined';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/semanticConstants';
import { Colour } from 'ui/Colour';

const Container = styled(Box)`
  background-color: ${Colour.main};
  color: ${() => readableColor(Colour.main)};
`;

const StyledIcon = styled(QuizIcon)`
  margin-right: 16px;
  font-size: 2rem;
`;

export const QuestionnaireResult = ({
  metadata: { questionnaireResult } = EMPTY_OBJECT,
}) =>
  questionnaireResult ? (
    <Container component={Paper} elevation={0} mb={1}>
      <Box py={1} px={2} alignItems="center" display="flex">
        <StyledIcon data-testid="quiz-icon" />
        <Typography variant="subtitle2">{questionnaireResult}</Typography>
      </Box>
    </Container>
  ) : (
    SKIP_RENDER
  );
