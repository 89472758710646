import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { isEmpty } from 'lodash-es';
import { Translate } from 'i18n/Translate';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { IdentityItem } from './IdentityItem';
import { IdentityItemAddMicrosoft } from './add/IdentityItemAddMicrosoft';
import { IdentityItemAddIDCard } from './add/IdentityItemAddIDCard';
import { IdentityItemAddKeycloak } from './add/IdentityItemAddKeycloak';

export const IdentityList = ({
  identities,
  accountName,
  onReloadData,
  onDelete,
}) => (
  <List
    subheader={
      <ListSubheader disableSticky>
        <Translate text="profile.identities.existing" />
      </ListSubheader>
    }
  >
    {identities.map((identity) => (
      <IdentityItem
        key={identity.identity}
        accountName={accountName}
        isDeletionEnabled={!isEmpty(identities)}
        identity={identity}
        onDelete={onDelete}
      />
    ))}

    <ListSubheader disableSticky>
      <Translate text="profile.identities.add" />
    </ListSubheader>

    <SelfCheckinProtectedState
      otherwise={
        <IdentityItemAddIDCard
          accountName={accountName}
          onReloadData={onReloadData}
        />
      }
    />

    <IdentityItemAddMicrosoft />
    <IdentityItemAddKeycloak />
  </List>
);
