import { useState } from 'react';
import { Keccak } from 'sha3';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import { CenterSpinner } from 'components/CenterSpinner';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { checkPersonalCode } from '../utils/personalCode';
import { CfpCheck } from './CfpCheck';
import { CfpCheckResult } from './CfpCheckResult';

const UiStates = {
  SCAN: 0,
  PENDING: 1,
  CFP_CHECKED: 2,
};

export const CfpCheckContainer = () => {
  const { get, post } = useApi();
  const [uiState, setUiState] = useState(UiStates.SCAN);
  const [cfp, setCfp] = useState({ valid: false });

  const onScanSuccess = async (data) => {
    setUiState(UiStates.PENDING);
    if (checkPersonalCode(data)) {
      const hash = new Keccak(256).update(data);
      const personHash = `0x${hash.digest('hex')}`;

      const response = await get(`cfp/check/${personHash}`);
      setCfp({
        ...response,
        pii: data,
      });
    } else {
      const response = await post(`cfp/external/check`, { value: data });
      setCfp({ ...response });
    }

    setUiState(UiStates.CFP_CHECKED);
  };

  return (
    <div>
      {uiState === UiStates.SCAN ? (
        <CfpCheck onScanSuccess={onScanSuccess} onError={noop} />
      ) : (
        SKIP_RENDER
      )}
      {uiState === UiStates.PENDING ? <CenterSpinner /> : SKIP_RENDER}
      {uiState === UiStates.CFP_CHECKED ? (
        <CfpCheckResult cfp={cfp} />
      ) : (
        SKIP_RENDER
      )}
    </div>
  );
};
