import { useCallback, useMemo, useState } from 'react';
import { Box, Button, List, Paper, Typography } from '@mui/material';
import { noop } from 'lodash-es';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { Translate } from 'i18n/Translate';
import { EMPTY_OBJECT } from 'constants/semanticConstants';
import { Colour } from 'ui/Colour';
import { IntlDate } from 'i18n/IntlDate';
import { useCountries } from 'hooks/useCountries';
import useVaccinationValueSet from '../useVaccinationValueSet';
import { computeVaccinationCheckinTriggerPayload } from './modules';
import useTriggerVaccinationCheckIn from './useTriggerVaccinationCheckIn';
import { ConfirmationFragment } from './ConfirmationFragment';

export const Confirmation = ({
  contextData,
  contextData: {
    appointment: { pii: { firstName, lastName, dateOfBirth } = EMPTY_OBJECT },
    vaccination: {
      batchNumber,
      vaccine,
      dose: { numberOfDoses, totalNumberOfDoses },
      vaccinationDate,
      countryOfVaccination,
    },
  } = EMPTY_OBJECT,
  onSuccess = noop,
}) => {
  const [success, setSuccess] = useState(false);
  const {
    isLoading: isFetchVaccineValueSetInProgress,
    data: { vaccines } = EMPTY_OBJECT,
  } = useVaccinationValueSet();

  const countries = useCountries();
  const country = useMemo(
    () =>
      countries.find((c) => c.code && c.code === countryOfVaccination)?.name,
    [countries, countryOfVaccination],
  );

  const usedVaccine = useMemo(
    () => vaccines?.find((vac) => vac.identifier === vaccine),
    [vaccine, vaccines],
  );

  const onConfirmationSuccess = () => {
    setSuccess(true);
  };

  const { mutate, isLoading: isUpdateInProgress } =
    useTriggerVaccinationCheckIn({
      onSuccess: onConfirmationSuccess,
    });

  const isLoading = useMemo(
    () => Boolean(isUpdateInProgress || isFetchVaccineValueSetInProgress),
    [isFetchVaccineValueSetInProgress, isUpdateInProgress],
  );

  const onSubmit = useCallback(() => {
    const payload = computeVaccinationCheckinTriggerPayload(contextData);
    const { testCode } = contextData;

    mutate({
      testCode,
      payload,
    });
  }, [contextData, mutate]);

  return (
    <Box>
      {/* Loading spinner */}
      <BackdropSpinner isOpen={isLoading} />

      {/* Headline */}
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pt={2}
      >
        <Typography variant="h6" align="center">
          <Translate
            text={
              success
                ? 'checkin.vaccine.success.title'
                : 'checkin.vaccine.confirmation.title'
            }
          />
        </Typography>

        <Box
          my={2}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          component={Paper}
          bgcolor={Colour.mainLightest}
          width={1}
          maxWidth={500}
        >
          <List>
            <ConfirmationFragment
              messageId="checkin.vaccine.confirmation.field.name"
              data={`${firstName} ${lastName}`}
            />
            <ConfirmationFragment
              messageId="checkin.vaccine.confirmation.field.dateOfBirth"
              data={<IntlDate date={dateOfBirth} />}
            />
            <ConfirmationFragment
              messageId="checkin.vaccine.confirmation.field.vaccine"
              data={`${usedVaccine?.name} (#${batchNumber})`}
            />
            <ConfirmationFragment
              messageId="checkin.vaccine.confirmation.field.dose"
              data={`${numberOfDoses} / ${totalNumberOfDoses}`}
            />
            <ConfirmationFragment
              messageId="checkin.vaccine.confirmation.field.date"
              data={<IntlDate date={vaccinationDate} />}
            />
            {country && (
              <ConfirmationFragment
                messageId="checkin.vaccine.confirmation.field.country"
                data={country}
              />
            )}
          </List>
        </Box>

        <Box display="flex" width={1} maxWidth={500}>
          {/* Success button */}
          {success && (
            <Button
              onClick={onSuccess}
              variant="contained"
              color="primary"
              fullWidth
            >
              <Translate text="checkin.success.next" />
            </Button>
          )}

          {/* Submit button */}
          {!success && (
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              fullWidth
            >
              <Translate text="checkin.vaccine.confirmation.confirm" />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
