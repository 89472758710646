import { Box, Typography } from '@mui/material';
import { CodeInput } from 'ui/theme/input/CodeInput';
import { Translate } from 'i18n/Translate';

export const IdCardCode = ({ onComplete, text, headlineText }) => (
  <Box textAlign="center">
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      m={2}
      mb={2}
      px={4}
    >
      {headlineText && (
        <Box mt={2}>
          <Typography variant="body1" align="center">
            <Translate text={headlineText} />
          </Typography>
        </Box>
      )}

      <Box mt={4}>
        <Typography variant="h6" align="center">
          <Translate text={text} />
        </Typography>
      </Box>
    </Box>

    <CodeInput onComplete={onComplete} />
  </Box>
);
