import { Paper, styled } from '@mui/material';
import { TestResultText, TestText } from 'components/test';
import { Colour } from 'ui/Colour';
import { IntlDateTime } from 'i18n/IntlDateTime';
import { Translate } from 'i18n/Translate';
import { RelativeDate } from 'i18n/RelativeDate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { ResultInfoWarning } from './ResultInfoWarning';

const Layout = styled(Paper, {
  shouldForwardProp: (props) => props !== '$stripedBackground',
})`
  padding: 16px;
  background: ${(props) =>
    props.$stripedBackground
      ? `repeating-linear-gradient(
          -45deg,
          ${Colour.orangeBackground},
          ${Colour.orangeBackground} 10px,
          white 10px,
          white 20px
        );`
      : 'white'};
`;

const ElementContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'noMargin',
})`
  padding-bottom: ${(props) => (props.noMargin ? '0px' : '16px')};
`;

const Title = styled('h5')`
  color: ${Colour.textMid};
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
`;

export const ResultInfo = ({ data }) => (
  <Layout $stripedBackground={data?.result}>
    {data?.result ? (
      <ElementContainer>
        <ResultInfoWarning />
      </ElementContainer>
    ) : (
      SKIP_RENDER
    )}

    <ElementContainer>
      <Title>
        <Translate text="test.type" />
      </Title>
      <TestText test={data?.test} />
    </ElementContainer>

    <ElementContainer noMargin={!data?.result}>
      <Title>
        <Translate text="test.execution.info.start" />
      </Title>
      <RelativeDate date={data?.created_at} /> (
      <IntlDateTime date={data?.created_at} />)
    </ElementContainer>

    {data?.result ? (
      <ElementContainer noMargin>
        <Title>
          <Translate text="test.execution.info.result" />
        </Title>
        <TestResultText result={data?.result} />
      </ElementContainer>
    ) : (
      SKIP_RENDER
    )}
  </Layout>
);
