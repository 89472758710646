import { noop } from 'lodash-es';
import queryString from 'query-string';
import { Box, Typography } from '@mui/material';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { Translate } from 'i18n/Translate';
import { RouteParam } from 'constants/RouteParam';

export const IdCardScan = ({ onSuccess }) => {
  const onScanSuccess = (data) => {
    // Check if scanned id card contains url
    if (data.includes('?') || data.includes('http')) {
      const url = queryString.parseUrl(data);
      const maybeId = url?.query?.[RouteParam.ID];
      if (maybeId) {
        onSuccess(maybeId);
      }
    } else {
      onSuccess(data);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        m={2}
      >
        <Typography variant="h6" align="center">
          <Translate text="login.id_card.info" />
        </Typography>
      </Box>
      <BarcodeReader onScanSuccess={onScanSuccess} onScanError={noop} />
    </>
  );
};
