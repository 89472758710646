const CONTENT_TYPE_APPLICATION_JSON = 'application/json';

const API_VERSION = '2021-03-31';
const APP_VERSION = `web ${process.env.REACT_APP_VERSION}`;

const DEFAULT_HEADERS = {
  'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
  'Api-Version': API_VERSION,
  'App-Version': APP_VERSION,
};

const getBaseUrl = () => {
  const env = process.env.REACT_APP_KEYPER_ENV?.toLowerCase();

  switch (env) {
    case 'develop':
      return 'https://api.dev.c19t.net/v1';
    case 'staging':
      return 'https://api.sta.c19t.net/v1';
    case 'sandbox':
      return 'https://api.sbx.c19t.net/v1';
    case 'production':
      return 'https://api.c19t.net/v1';
    default:
      return 'http://localhost:63584';
  }
};

const post = async (url, body, headers) => {
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get('content-type');
      if (contentType?.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      return response.json().then((json) => {
        if (!response.ok) {
          // eslint-disable-next-line promise/no-return-wrap
          return Promise.reject(json);
        }
        return json;
      });
    }
    return null;
  });
};

const put = (url, body, headers) => {
  const requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get('content-type');
      if (contentType?.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error('Request failed');
    }
    return null;
  });
};

const get = (url, headers) => {
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get('content-type');
      if (contentType?.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error('Request failed');
    }
    return null;
  });
};

const del = (url, headers) => {
  const requestOptions = {
    method: 'DELETE',
    headers,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get('content-type');
      if (contentType?.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error('Request failed');
    }
    return null;
  });
};
const postFile = async (url, body, headers) => {
  const requestOptions = {
    method: 'POST',
    headers,
    body,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get('content-type');
      if (contentType?.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      return response.json().then((json) => {
        if (!response.ok) {
          // eslint-disable-next-line promise/no-return-wrap
          return Promise.reject(json);
        }
        return json;
      });
    }
    return null;
  });
};

export { get, post, put, del, postFile, DEFAULT_HEADERS };
