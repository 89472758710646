import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { MenuItem, TextField } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { useCountries } from 'hooks/useCountries';

export const CountryOfVaccination = ({ control }) => {
  const { formatMessage } = useIntl();
  const countryOptions = useCountries();

  return (
    <Controller
      control={control}
      name="countryOfVaccination"
      rules={{
        required: formatMessage({
          id: 'checkin.vaccine.input.countryOfVaccination.required',
        }),
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          id="countryOfVaccination"
          error={!!error}
          fullWidth
          helperText={error?.message}
          InputLabelProps={{ htmlFor: 'countryOfVaccination', shrink: true }}
          SelectProps={{ displayEmpty: true }}
          label={
            <Translate text="checkin.vaccine.input.countryOfVaccination" />
          }
          margin="dense"
          select
          variant="outlined"
          {...field}
        >
          {countryOptions.map(({ name, code }) => (
            <MenuItem key={name} value={code}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
