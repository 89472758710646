import { styled } from '@mui/material';
import ArrowIcon from '@mui/icons-material/ChevronRightRounded';
import { StateHeader } from 'ui/theme/text/StateHeader';
import { Colour } from 'ui/Colour';

const Layout = styled('div')`
  padding: 16px;
`;

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 24px 8px;
`;

const ListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 2px solid ${Colour.main};
  border-radius: 6px;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const SelectOrganizationList = ({ organizations, setOrganization }) => (
  <Layout>
    <StateHeader text="organization.select" />

    <List>
      {organizations.map((organization) => (
        <ListItem
          key={organization.organizationId}
          onClick={() => setOrganization(organization)}
        >
          {organization.name}
          <ArrowIcon />
        </ListItem>
      ))}
    </List>
  </Layout>
);
