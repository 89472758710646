import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import { IdentityType } from 'constants/IdentityType';

const API_RESOURCE = 'identity';

export const useCreateIdCard = ({ onSuccess = noop }) => {
  const { post } = useApi();

  return useMutation(
    () =>
      post(API_RESOURCE, {
        type: IdentityType.IDCARD,
      }),
    {
      onSuccess,
    },
  );
};
