import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box } from '@mui/material';
import { IdentityType } from 'constants/IdentityType';
import { RouteParam } from 'constants/RouteParam';
import { IdCardScan } from './IdCardScan';
import { IdCardCode } from './IdCardCode';

export const IdCardLogin = ({ onLoginReady, onDiscoverIdentity }) => {
  const { search } = useLocation();
  const maybeId = queryString.parse(search)?.[RouteParam.ID];
  const [idCard, setIdCard] = useState(maybeId);

  const attemptToLoginWithIDCard = (pin) => {
    onLoginReady({
      identity: idCard,
      key: pin,
      type: IdentityType.IDCARD,
    });
  };

  const discoverIdentity = (nextIdentity) => {
    setIdCard(nextIdentity);

    onDiscoverIdentity(nextIdentity);
  };

  return (
    <Box px={2}>
      {idCard ? (
        <IdCardCode
          onComplete={attemptToLoginWithIDCard}
          text="login.id_card.default"
        />
      ) : (
        <IdCardScan onSuccess={discoverIdentity} />
      )}
    </Box>
  );
};
