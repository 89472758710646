import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';
import { EMPTY_OBJECT, EN_DASH } from 'constants/semanticConstants';

export const snakeToCamelCase = (source = EMPTY_OBJECT) =>
  camelcaseKeys(source, {
    deep: true,
  });

export const camelToSnakeCase = (source = EMPTY_OBJECT) =>
  snakeCaseKeys(source, {
    deep: true,
  });

export const byQuery = ({ search }) => queryString.parse(search);

/**
 * Checks if two `location` have changed by excluding query parameters
 * from change check (optional).
 */
export const isRouteChange = (location, prevLocation) => {
  const hasPathnameChanged = !isEqual(prevLocation.pathname, location.pathname);
  const hasQueryChanged = !isEqual(byQuery(prevLocation), byQuery(location));

  return hasPathnameChanged || hasQueryChanged;
};

export const logToConsole = (...args) => {
  // eslint-disable-next-line no-console
  console.log(...args);
};

export const computeUniqueKey = (object) => {
  return Object.values(object).join(EN_DASH);
};

export const getCo2pUrl = () => {
  const env = process.env.REACT_APP_KEYPER_ENV?.toLowerCase();

  switch (env) {
    case 'develop':
      return 'https://dev.co2p.com';
    case 'staging':
      return 'https://dev.co2p.com';
    case 'sandbox':
      return 'https://dev.co2p.com';
    case 'production':
      return 'https://www.co2p.com';
    default:
      return 'https://dev.co2p.com';
  }
};

export const getDomain = () => {
  const domain = document.location.host.includes('localhost')
    ? 'scan.dev.c19t.st'
    : document.location.host;

  return domain;
};
