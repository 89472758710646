import { isEmpty } from 'lodash-es';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { Translate } from 'i18n/Translate';
import { EMPTY_STRING } from 'constants/semanticConstants';
import { Language } from 'ui/Language';
import { LanguageText } from 'components/LanguageText';
import { FormField } from './FormField';

const DEFAULT_VALUES = {
  [FormField.EMAIL]: EMPTY_STRING,
  [FormField.LANGUAGE]: Language.DE,
  [FormField.NAME]: EMPTY_STRING,
  [FormField.PHONE]: EMPTY_STRING,
};

export const AccountUpdater = ({ isDisabled, onSubmit, account }) => {
  const intl = useIntl();
  const defaultValues = useMemo(
    () => ({
      ...DEFAULT_VALUES,
      ...account,
    }),
    [account],
  );
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const currentPhone = useWatch({
    control,
    name: FormField.PHONE,
  });
  const currentEmail = useWatch({
    control,
    name: FormField.EMAIL,
  });

  /**
   * Auto default the language field if the two relevant fields are not defined.
   */
  useEffect(() => {
    if (currentEmail || currentPhone) {
      return;
    }

    setValue(FormField.LANGUAGE, Language.DE);
  }, [currentEmail, currentPhone, setValue]);

  /**
   * Re-set the form to default values on config change
   */
  useEffect(() => reset(defaultValues), [reset, defaultValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        py={1}
        width={1}
      >
        {/* Name of the account */}
        <Controller
          control={control}
          name={FormField.NAME}
          rules={{
            required: intl.formatMessage({
              id: 'account.edit.form.name.required',
            }),
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              disabled={isDisabled}
              error={!!error}
              fullWidth
              helperText={error?.message}
              id={FormField.NAME}
              InputLabelProps={{ htmlFor: FormField.NAME }}
              label={<Translate text="account.edit.form.name" />}
              margin="dense"
              size="small"
              variant="outlined"
              {...field}
            />
          )}
        />

        {/* Optional name of the user's account */}
        <Controller
          control={control}
          name={FormField.EMAIL}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              disabled={isDisabled}
              error={!!error}
              fullWidth
              helperText={error?.message}
              id={FormField.EMAIL}
              InputLabelProps={{ htmlFor: FormField.EMAIL }}
              label={<Translate text="account.edit.form.email" />}
              margin="dense"
              placeholder={intl.formatMessage({
                id: 'account.edit.form.email.placeholder',
              })}
              variant="outlined"
              {...field}
            />
          )}
        />

        {/* Optional phone of the user's account */}
        <Controller
          control={control}
          name={FormField.PHONE}
          render={({ field, fieldState: { error } }) => (
            <TextField
              size="small"
              disabled={isDisabled}
              error={!!error}
              fullWidth
              helperText={error?.message}
              id={FormField.PHONE}
              InputLabelProps={{ htmlFor: FormField.PHONE }}
              label={<Translate text="account.edit.form.phone" />}
              margin="dense"
              variant="outlined"
              {...field}
            />
          )}
        />
      </Box>

      {/* Language of the account */}
      <Controller
        control={control}
        name={FormField.LANGUAGE}
        rules={{
          required: intl.formatMessage({
            id: 'account.edit.form.language.required',
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={
              isDisabled || (isEmpty(currentPhone) && isEmpty(currentEmail))
            }
            error={!!error}
            fullWidth
            helperText={error?.message}
            id={FormField.LANGUAGE}
            InputLabelProps={{ htmlFor: FormField.LANGUAGE }}
            label={<Translate text="account.edit.form.language" />}
            margin="dense"
            select
            size="small"
            variant="outlined"
            {...field}
          >
            {Object.values(Language).map((value) => (
              <MenuItem key={value} value={value}>
                <LanguageText language={value} />
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Box mt={2}>
        <Button
          color="primary"
          disabled={isDisabled}
          fullWidth
          type="submit"
          variant="contained"
        >
          <Translate text="action.save_changes" />
        </Button>
      </Box>
    </form>
  );
};
