import { IconButton, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { SearchRounded } from '@mui/icons-material';
import { Translate } from 'i18n/Translate';
import {
  EMPTY_STRING,
  EN_DASH,
  PREVENT_AUTOFILL_HINT,
} from 'constants/semanticConstants';
import { getValidateFunctionWithMessage } from './modules';

/**
 * Form default value
 */
const DEFAULT_VALUES = {
  testLookup: EMPTY_STRING,
};

/**
 * The test lookup mask (two number digits and four alphanumeric digits)
 */
const TEST_LOOKUP_MASK = `99${EN_DASH}****`;

export const SearchForm = ({ onSubmit }) => {
  const { formatMessage } = useIntl();
  const { handleSubmit, control } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="testLookup"
        rules={{
          required: formatMessage({
            id: 'scan.appointment.test_lookup.input.required',
          }),
          validate: getValidateFunctionWithMessage({
            invalidValueMessage: formatMessage({
              id: 'scan.appointment.test_lookup.input.incorrect',
            }),
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <InputMask {...field} mask={TEST_LOOKUP_MASK} alwaysShowMask={false}>
            {(inputProps) => (
              <TextField
                size="small"
                type="text"
                autoComplete={PREVENT_AUTOFILL_HINT}
                inputProps={{
                  autoComplete: PREVENT_AUTOFILL_HINT,
                }}
                /* eslint-disable-next-line react/jsx-no-duplicate-props */
                InputProps={{
                  endAdornment: (
                    <IconButton
                      type="submit"
                      aria-label={formatMessage({
                        id: 'scan.appointment.test_lookup.input',
                      })}
                      size="large"
                    >
                      <SearchRounded />
                    </IconButton>
                  ),
                }}
                error={!!error}
                fullWidth
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
                label={<Translate text="scan.appointment.test_lookup.input" />}
                margin="dense"
                placeholder={formatMessage({
                  id: 'scan.appointment.test_lookup.input.placeholder',
                })}
                variant="outlined"
                {...inputProps}
              />
            )}
          </InputMask>
        )}
      />
    </form>
  );
};
