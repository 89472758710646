import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

export const ResultSelector = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== 'color',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid ${(props) => props.color};
  border-radius: 6px;
  padding: 12px 8px;
  margin-bottom: 32px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  user-select: none;
  width: 100%;
  font-size: 1em;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
