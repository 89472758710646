import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SCROLL_OPTS = {
  top: 0,
  left: 0,
  behavior: 'smooth',
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window?.scroll(SCROLL_OPTS);
  }, [pathname]);
};
