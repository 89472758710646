import { useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Spinner } from 'components/Spinner';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';
import { useApi } from 'hooks/useApi';

const ResultContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerContainer = styled('div')`
  position: relative;
`;

const StyledPrintIcon = styled(PrintIcon)`
  color: ${Colour.main};
  font-size: 56px;
  position: absolute;
  left: calc(50% - 28px);
  top: calc(50% - 28px);
`;

const StyledErrorIcon = styled(ErrorIcon)`
  color: ${Colour.red};
  font-size: 56px;
`;

const InfoText = styled('p', {
  shouldForwardProp: (props) => props !== 'red',
})`
  color: ${(props) => (props.red ? Colour.red : Colour.text)};
  text-align: center;
  margin-bottom: 24px;
`;

export const DownloadLabel = ({ testCode, onRestartClick }) => {
  const [pending, setPending] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('test.label.download.error');
  const { post } = useApi();

  useEffect(() => {
    post(`test/${testCode}/label`, {
      test_code: testCode,
    })
      .then((responseData) => {
        // eslint-disable-next-line promise/always-return
        if (responseData == null) {
          setError(true);
        } else {
          window.location = responseData.url;
        }

        setPending(false);
      })
      .catch((error) => {
        switch (error.type) {
          case 'not_started':
            setErrorMessage('test.label.download.error.not_started');
            break;
          case 'unauthorized':
            setErrorMessage('test.label.download.error.unauthorized');
            break;
          default:
            setErrorMessage('test.label.download.error');
            break;
        }

        setError(true);
        setPending(false);
      });
  }, [testCode, post]);

  return (
    <ResultContainer>
      {pending || !hasError ? (
        <>
          <SpinnerContainer>
            <Spinner size={150} thickness={0.5} />
            <StyledPrintIcon />
          </SpinnerContainer>
          <InfoText>
            <Translate text="test.label.download.info" />
          </InfoText>
        </>
      ) : (
        <>
          <StyledErrorIcon />
          <InfoText red>
            <Translate text={errorMessage} />
          </InfoText>
        </>
      )}
      <Button variant="outlined" color="secondary" onClick={onRestartClick}>
        <Translate text="action.restart" />
      </Button>
    </ResultContainer>
  );
};
