import { styled } from '@mui/material';

const TextContainer = styled('div')`
  position: relative;
  width: 110px;
  height: 80px;
`;

const IndexContainer = styled('div')`
  margin: 0;
  font-size: 3em;
  position: absolute;
  top: -8px;
  right: 24px;
`;

const ShortCode = styled('div')`
  margin: 0;
  position: absolute;
  right: -16px;
  top: 24px;
  transform: rotate(-90deg);
  font-weight: 500;
  font-size: 1.25em;
`;

const Rest = styled('div')`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.7em;
`;

export const TestCodeLabelText = ({ testCode }) => {
  const [, , , index, uuidPart1, uuidPart2, uuidPart3, shortCode] = testCode;

  return (
    <TextContainer>
      <IndexContainer>{index}</IndexContainer>
      <ShortCode>
        <i>{shortCode}</i>
      </ShortCode>
      <Rest>{[uuidPart1, uuidPart2, uuidPart3].join('-')}</Rest>
    </TextContainer>
  );
};
