import { Avatar, styled, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TestResult } from 'constants/TestResult';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';
import { ResultSelector } from './ResultSelector';

const DoneIcon = styled(Avatar)`
  background-color: ${Colour.main};
  margin-right: 16px;
`;

export const OptionDone = ({ onClick }) => (
  <ResultSelector
    focusRipple
    color={Colour.main}
    onClick={() => onClick(TestResult.DONE)}
  >
    <DoneIcon>
      <CheckIcon />
    </DoneIcon>
    <Typography variant="body2">
      <Translate text="scan.result.done" />
    </Typography>
  </ResultSelector>
);
