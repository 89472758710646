import { Box, Button, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import { Translate } from 'i18n/Translate';

export const SuccessButton = ({ onClick }) => (
  <Box
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
    pt={2}
  >
    <Typography variant="body1" align="center">
      <Translate text="scan.finished" />
    </Typography>

    <Box pt={3} width={1}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        onClick={onClick}
        endIcon={<ArrowRightIcon />}
      >
        <Translate text="checkin.success.next" />
      </Button>
    </Box>
  </Box>
);
