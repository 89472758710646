import { Box, Button, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Translate } from 'i18n/Translate';

export const BackButton = ({ onClick }) => (
  <Box
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
    pt={2}
  >
    <Typography variant="body1" align="center">
      <Translate text="scan.erroneous" />
    </Typography>

    <Box pt={3} width={1}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        onClick={onClick}
        startIcon={<ArrowBack />}
      >
        <Translate text="checkin.erroneous.previous" />
      </Button>
    </Box>
  </Box>
);
