import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { useApi } from 'hooks/useApi';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { useOidc } from 'auth/oidc/OidcHook';

export const AuthSetupRedirect = () => {
  const {
    params: { provider },
  } = useRouteMatch();
  const history = useHistory();
  const [pending, setPending] = useState(true);
  const { post } = useApi();
  const { setupFinished, signInRedirectCallback } = useOidc(provider);

  useEffect(() => {
    const createIdentity = async (token, successUrl) => {
      try {
        await post('identity', {
          type: provider,
          identity: token,
        });

        history.replace(successUrl);
      } finally {
        setPending(false);
      }
    };

    if (setupFinished) {
      signInRedirectCallback().then((res) =>
        createIdentity(res.id_token, res.state.url),
      );
    }
  }, [setupFinished, signInRedirectCallback, history, post, provider]);

  return pending ? <FullPageSpinner /> : SKIP_RENDER;
};
