import { useEffect } from 'react';
import { OidcProvider } from 'auth/oidc/Oidc';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { getLoginWithRedirect, RoutePath } from 'constants/RoutePath';
import { useOidc } from 'auth/oidc/OidcHook';

export const KeycloakLogin = () => {
  const { getUser, signInRedirect, setupFinished } = useOidc(
    OidcProvider.KEYCLOAK,
    getLoginWithRedirect(OidcProvider.KEYCLOAK),
  );

  useEffect(() => {
    if (!setupFinished) {
      return;
    }

    (async () => {
      await getUser();

      await signInRedirect({
        state: {
          url: RoutePath.ROOT,
        },
      });
    })();
  }, [setupFinished, getUser, signInRedirect]);

  return <FullPageSpinner />;
};
