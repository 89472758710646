import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import CardIcon from '@mui/icons-material/CreditCardRounded';
import KeyIcon from '@mui/icons-material/VpnKeyOutlined';
import KeyCloakIcon from '@mui/icons-material/KeyRounded';
import { IdentityType } from 'constants/IdentityType';
import { SKIP_RENDER } from 'constants/semanticConstants';

export const IdentityTypeIcon = ({ type }) => {
  switch (type) {
    case IdentityType.MICROSOFT:
      return <BusinessIcon />;
    case IdentityType.IDCARD:
      return <CardIcon />;
    case IdentityType.APIKEY:
      return <KeyIcon />;
    case IdentityType.KEYCLOAK:
      return <KeyCloakIcon />;
    default:
      return SKIP_RENDER;
  }
};
