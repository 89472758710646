import { isEqual } from 'lodash-es';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { useOrganization } from 'components/organization/OrganizationProvider';

export const CustomizableContent = ({
  organizationId,
  children = SKIP_RENDER,
  otherwise = SKIP_RENDER,
}) => {
  const { current } = useOrganization();
  const renderForCurrentOrganization =
    organizationId && isEqual(organizationId, current?.organizationId);

  return renderForCurrentOrganization ? children : otherwise;
};
