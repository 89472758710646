import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { includes, isEmpty, last, negate } from 'lodash-es';
import { usePendingPath } from 'hooks/usePendingPath';
import { useAuth } from 'auth/AuthContext';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { AdminRoles, Roles as AllKnownRoles } from 'constants/Roles';
import {
  NON_SCOPED_ADMIN_BASE,
  ORG_SCOPED_ADMIN_BASE,
  ORGANIZATION_FRAGMENT,
  RoutePath,
} from 'constants/RoutePath';
import { useOrganizationSlug } from 'hooks/useOrganizationSlug';

/**
 * Checks if the given candidate has the admin roles.
 *
 * If yes, is eligible to navigate to the dashboard page.
 */
const hasOnlyAdminRoles = (candidates) =>
  negate(isEmpty)(candidates) &&
  candidates
    ?.filter((role) => Object.values(AllKnownRoles).includes(role))
    .every((knownRole) => AdminRoles.includes(knownRole));

/**
 * Checks if the given resource is one which should be scoped.
 *
 * Should be scoped if begins with `/admin` and does not include the `organization` fragment`.
 *
 * Note: This redirector only intercepts un-matched routes.
 */
const isUnScopedResource = (pathname) =>
  pathname?.startsWith(NON_SCOPED_ADMIN_BASE) &&
  !includes(pathname, ORGANIZATION_FRAGMENT);

/**
 * Computes the resource of an unscoped location to a scoped location base on the pathname and
 * search.
 */
const computeScopedResourceLocation = (
  pathname,
  withOrganizationSlug,
  search,
) => {
  const scopedBase = withOrganizationSlug(ORG_SCOPED_ADMIN_BASE);
  const toBeScopedResourcePath = last(pathname?.split(NON_SCOPED_ADMIN_BASE));

  return `${scopedBase}${toBeScopedResourcePath}${search}`;
};

/**
 * Interceptor which gets triggered whenever the uses switches from the
 * admin area -> main client area.
 */
export const AppInterceptor = ({ fallbackTargetPath }) => {
  const { pendingPath, setPendingPath } = usePendingPath();
  const history = useHistory();
  const { withOrganizationSlug } = useOrganizationSlug();
  const { roles } = useAuth();

  useEffect(() => {
    if (pendingPath) {
      history.replace(pendingPath);
      setPendingPath(null);

      return;
    }

    const {
      location: { pathname, search },
    } = history;

    if (isUnScopedResource(pathname)) {
      history.replace(
        computeScopedResourceLocation(pathname, withOrganizationSlug, search),
      );

      return;
    }

    if (hasOnlyAdminRoles(roles)) {
      history.replace(RoutePath.ADMIN_DASHBOARD);

      return;
    }

    history.replace(fallbackTargetPath);
  }, [
    fallbackTargetPath,
    history,
    pendingPath,
    roles,
    setPendingPath,
    withOrganizationSlug,
  ]);

  return SKIP_RENDER;
};
