import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box, styled } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { SUCCESS_NOTIFICATION } from './notificationType';

const AUTO_HIDE_DURATION = 5000;
const CLICK_AWAY_REASON = 'clickaway';

const StyledSnackbar = styled(Snackbar)`
  bottom: 72px;
`;

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const Notification = ({
  isOpen,
  setIsOpen,
  text,
  type = SUCCESS_NOTIFICATION,
  values,
}) => {
  const handleClose = (event, reason) => {
    if (reason === CLICK_AWAY_REASON) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
    >
      <Box>
        <Alert onClose={handleClose} severity={type}>
          <Translate text={text} values={values} />
        </Alert>
      </Box>
    </StyledSnackbar>
  );
};
