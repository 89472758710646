/* eslint-disable no-console,no-underscore-dangle */
import { ResponseValidator } from 'oidc-client/src/ResponseValidator';

/**
 * Only use this custom validator for Azure AD login (Microsoft).
 * This HACK skips the issuer validation of the token (as the common tenant doesn't return the same
 * issuer as the well-known configuration endpoint)
 */
export class ResponseValidatorCtor extends ResponseValidator {
  _validateIdToken(state, response) {
    if (!state.nonce) {
      console.error('ResponseValidator._validateIdToken: No nonce on state');
      return Promise.reject(new Error('No nonce on state'));
    }

    const jwt = this._joseUtil.parseJwt(response.id_token);
    if (!jwt || !jwt.header || !jwt.payload) {
      console.error(
        'ResponseValidator._validateIdToken: Failed to parse id_token',
        jwt,
      );
      return Promise.reject(new Error('Failed to parse id_token'));
    }

    if (state.nonce !== jwt.payload.nonce) {
      console.error(
        'ResponseValidator._validateIdToken: Invalid nonce in id_token',
      );
      return Promise.reject(new Error('Invalid nonce in id_token'));
    }

    return this._metadataService.getIssuer().then(() => {
      console.debug('ResponseValidator._validateIdToken: Received issuer');
      return this._getSigningKeyForJwtWithSingleRetry(jwt).then((key) => {
        if (!key) {
          console.error(
            'ResponseValidator._validateIdToken: No key matching kid or alg found in signing keys',
          );
          throw new Error('No key matching kid or alg found in signing keys');
        }

        const audience = state.client_id;

        const clockSkewInSeconds = this._settings.clockSkew;
        console.debug(
          'ResponseValidator._validateIdToken: Validating JWT; using clock skew (in seconds) of: ',
          clockSkewInSeconds,
        );

        return this._joseUtil
          .validateJwt(
            response.id_token,
            key,
            jwt.payload.iss,
            audience,
            clockSkewInSeconds,
          )
          .then(() => {
            console.debug(
              'ResponseValidator._validateIdToken: JWT validation successful',
            );

            if (!jwt.payload.sub) {
              console.error(
                'ResponseValidator._validateIdToken: No sub present in id_token',
              );
              throw new Error('No sub present in id_token');
            }

            response.profile = jwt.payload;

            return response;
          });
      });
    });
  }
}
