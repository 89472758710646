import { first } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import PinField from 'react-pin-field';
import { styled } from '@mui/material';
import { Colour } from 'ui/Colour';
import { EMPTY_STRING } from 'constants/semanticConstants';

const StyledPinField = styled(PinField)`
  width: 48px;
  height: 48px;
  font-size: 24px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid ${Colour.text};
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
  margin: 0 4px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 7px ${Colour.mainLight};
    border: 1px solid ${Colour.main};
    transform: scale(1.05);
  }

  -moz-appearance: textfield;
`;

const AUTO_FOCUS_DELAY = 200;

export const CodeInput = ({ onComplete, ...props }) => {
  const codeInputRef = useRef();

  const focusInput = useCallback(() => {
    if (!codeInputRef?.current) {
      return;
    }

    first(codeInputRef.current)?.focus();
  }, []);

  useEffect(() => {
    setTimeout(focusInput, AUTO_FOCUS_DELAY);
  }, [focusInput]);

  const applyCallbacksAndReset = (...args) => {
    onComplete(...args);

    codeInputRef.current.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.value = EMPTY_STRING;
    });

    focusInput();
  };

  return (
    <StyledPinField
      data-testid="@keyper-pin"
      inputMode="numeric"
      length={4}
      onComplete={applyCallbacksAndReset}
      pattern="[0-9]*"
      ref={codeInputRef}
      type="number"
      validate={/^[0-9]$/}
      {...props}
    />
  );
};
