import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'valueset/vaccines';

/**
 * Hook which returns a vaccination value set.
 */
const useVaccinationValueSet = () => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    QueryKeys.FETCH_VACCINATION_VALUE_SET,
    () => get(API_RESOURCE),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
      refetchOnWindowFocus: false,
    },
  );
};

export default useVaccinationValueSet;
