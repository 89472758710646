import { useCallback, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import format from 'date-fns/format';
import { CenterSpinner } from 'components/CenterSpinner';
import { EMPTY_STRING, SKIP_RENDER } from 'constants/semanticConstants';
import { Translate } from 'i18n/Translate';
import { DATE_FORMAT } from 'customer/cfp/constants';
import useVaccinationValueSet from '../useVaccinationValueSet';
import {
  getNormalizedDoses,
  getNormalizedVaccinationDate,
} from '../Confirmation/modules';
import { VaccineFragment } from './Fragments/VaccineFragment';
import { DoseFragment } from './Fragments/DoseFragment';
import { MetadataFragment } from './Fragments/MetadataFragment';

const DEFAULT_DOSE_VALUE = Object.freeze({
  numberOfDoses: 0,
  totalNumberOfDoses: 0,
});

export const DataAttachment = ({ onSuccess }) => {
  const { data, isLoading } = useVaccinationValueSet();

  const defaultValues = useMemo(
    () => ({
      vaccine: EMPTY_STRING,
      dose: {
        ...DEFAULT_DOSE_VALUE,
      },
      batchNumber: EMPTY_STRING,
      isConfirmation: false,
      vaccinationDate: format(new Date(), DATE_FORMAT),
      countryOfVaccination: EMPTY_STRING,
    }),
    [],
  );

  const { handleSubmit, control, clearErrors, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const resetDoseValues = () => {
    setValue('dose', { ...DEFAULT_DOSE_VALUE });
  };

  const onSubmit = useCallback(
    ({ vaccinationDate, dose, ...formData }) => {
      onSuccess({
        ...formData,
        vaccinationDate: getNormalizedVaccinationDate(vaccinationDate),
        dose: getNormalizedDoses(dose),
      });
    },
    [onSuccess],
  );

  return (
    <>
      {isLoading ? <CenterSpinner /> : SKIP_RENDER}
      {isLoading ? (
        SKIP_RENDER
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          m={2}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <VaccineFragment
              vaccines={data.vaccines}
              control={control}
              clearErrors={clearErrors}
              resetDoseValues={resetDoseValues}
            />
            <DoseFragment
              vaccines={data.vaccines}
              control={control}
              clearErrors={clearErrors}
            />
            <MetadataFragment control={control} />
            <Box mt={3}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                <Translate text="checkin.vaccine.viewOverview" />
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </>
  );
};
