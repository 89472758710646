import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, styled } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { RedButton } from 'ui/theme/button/RedButton';
import { Translate } from 'i18n/Translate';
import useCo2ImportData from 'hooks/useCo2ImportData';

const CertificateArea = styled('div')`
  margin-top: 16px;
`;
export const GenerateCertificate = () => {
  const { externalReference } = useParams();
  const [file, setFile] = useState();

  const { mutate, isLoading } = useCo2ImportData({
    projectReference: externalReference,
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      mutate(formData);
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      margin="auto"
      mt={5}
    >
      <Typography variant="h6">
        <Translate text="co2.container.title" />
      </Typography>
      <Typography variant="caption">
        <Translate text="co2.container.import.info" />
      </Typography>

      <CertificateArea>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <Box pt={5}>
          <RedButton
            component="a"
            disabled={isLoading}
            onClick={handleButtonClick}
            variant="contained"
            text="co2.container.import"
            endIcon={<ChevronRightIcon />}
          />
        </Box>
      </CertificateArea>
    </Box>
  );
};
