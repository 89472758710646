import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/SendToMobileOutlined';
import { useAuth } from 'auth/AuthContext';
import { Translate } from 'i18n/Translate';
import useSendIdCardPerSms from './useSendIdCardPerSms';

/**
 * Using the external identity from the userContext is a hack solution for the self check-in
 * feature.
 *
 * If we want to re-use the functionality for all id-cards we should pass the identity as a param.
 */
export const SendIdCard = () => {
  const { userContext } = useAuth();

  const { mutate: sendIdCardPerSms, isSuccess } = useSendIdCardPerSms({
    externalIdentityId: userContext?.identity,
  });

  return (
    <Button
      color="secondary"
      disabled={isSuccess}
      onClick={sendIdCardPerSms}
      startIcon={<SendIcon />}
      variant="outlined"
    >
      <Translate text="identity.action.send_id_card_per_sms" />
    </Button>
  );
};
