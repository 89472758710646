import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';
import { QueryKeys } from 'constants/QueryKeys';

/**
 * Hook which triggers an accounts' update
 */
export const useUpdateAccount = () => {
  const { put } = useApi();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(({ payload }) => put('account', payload), {
    onSuccess: async () => {
      showNotification(SUCCESS_NOTIFICATION, 'account.edit.success');

      return queryClient.invalidateQueries(QueryKeys.FETCH_SINGLE_ACCOUNT);
    },
    onError: () => showNotification(ERROR_NOTIFICATION, 'account.edit.error'),
  });
};
