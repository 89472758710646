import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';

export const InsuranceCardNumberFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Controller
      control={control}
      name="insuranceCardNumber"
      rules={{
        required: formatMessage({
          id: 'scan.appointment.edit.input.insuranceCardNumber.required',
        }),
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          autoFocus
          disabled={isDisabled}
          fullWidth
          margin="dense"
          variant="outlined"
          label={
            <Translate text="scan.appointment.edit.input.insuranceCardNumber" />
          }
          error={!!error}
          helperText={error?.message}
          placeholder={formatMessage({
            id: 'scan.appointment.edit.input.insuranceCardNumber.placeholder',
          })}
          {...field}
        />
      )}
    />
  );
};
