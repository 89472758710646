import { useState } from 'react';
import { Box } from '@mui/material';
import { isEqual, last, negate } from 'lodash-es';
import { CenterSpinner } from 'components/CenterSpinner';
import {
  EMPTY_STRING,
  SKIP_RENDER,
  UNDEFINED,
} from 'constants/semanticConstants';
import { useAuth } from 'auth/AuthContext';
import useChangePin from 'auth/useChangePin';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';
import { IdCardCode } from './IdCardCode';

const Step = {
  NEW_PIN: {
    applies: ({ oldPin, newPin }) =>
      (oldPin && !newPin) || isEqual(oldPin, newPin),
    order: 0,
  },
  NEW_PIN_VERIFICATION: {
    applies: ({ oldPin, newPin }) => Boolean(oldPin && newPin),
    order: 1,
  },
};

export const IdCardActivation = () => {
  const { showNotification } = useNotification();
  const {
    onUserContextUpdate,
    decryptedKey: oldPin,
    userContext: { identity: externalIdentityId },
  } = useAuth();
  const [newPin, setNewPin] = useState(EMPTY_STRING);

  const currentStep = last(
    Object.entries(Step).find(([, { applies }]) =>
      applies({
        oldPin,
        newPin,
      }),
    ),
  )?.order;

  const enableAuthenticationModeAfterSuccess = () =>
    onUserContextUpdate({
      isLoginMethodActivated: true,
      encryptedKey: UNDEFINED,
    });

  const resetTheFlowAfterUnsuccessfulUpdateAttempt = () =>
    setNewPin(EMPTY_STRING);

  const { isLoading, mutate: mutateByUpdatingPin } = useChangePin({
    externalIdentityId,
    onSuccess: enableAuthenticationModeAfterSuccess,
    onError: resetTheFlowAfterUnsuccessfulUpdateAttempt,
  });

  const verifyNewPinAndContinue = (newPinCandidate) => {
    if (isEqual(oldPin, newPinCandidate)) {
      showNotification(
        ERROR_NOTIFICATION,
        'login.id_card.renew_pin.new_repeat.invalid.change_required',
      );

      return;
    }

    setNewPin(newPinCandidate);
  };

  const verifyNewPinAndUpdate = (reCheckedNewPin) => {
    if (negate(isEqual)(newPin, reCheckedNewPin)) {
      showNotification(
        ERROR_NOTIFICATION,
        'login.id_card.renew_pin.new_repeat.invalid',
      );

      return;
    }

    mutateByUpdatingPin({
      oldPin,
      newPin,
    });
  };

  if (isLoading) {
    return (
      <Box width={1} mt={10}>
        <CenterSpinner />
      </Box>
    );
  }

  switch (currentStep) {
    case Step.NEW_PIN.order:
      return (
        <IdCardCode
          onComplete={verifyNewPinAndContinue}
          headlineText="login.id_card.renew_pin.headline"
          text="login.id_card.renew_pin.new.input"
        />
      );
    case Step.NEW_PIN_VERIFICATION.order:
      return (
        <IdCardCode
          onComplete={verifyNewPinAndUpdate}
          headlineText="login.id_card.renew_pin.headline"
          text="login.id_card.renew_pin.new_repeat.input"
        />
      );

    default:
      return SKIP_RENDER;
  }
};
