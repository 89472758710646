import { useMemo } from 'react';
import { Box, Icon, Paper, styled, Typography } from '@mui/material';
import { readableColor } from 'polished';
import MoneyIcon from '@mui/icons-material/PaidOutlined';
import PosIcon from '@mui/icons-material/PointOfSaleRounded';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/semanticConstants';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';
import { ChangePaymentState } from './ChangePaymentState';

const getColor = ({ paidOnline, markedAsPaid }) => {
  if (paidOnline || markedAsPaid) {
    return Colour.main;
  }
  // default case -> paymentRequired; nothing else should ever be rendered
  return Colour.red;
};

const getText = ({ paidOnline, markedAsPaid }) => {
  if (paidOnline) {
    return 'checkin.payment.paidOnline';
  }
  if (markedAsPaid) {
    return 'checkin.payment.markedAsPaid';
  }

  // default case -> paymentRequired; nothing else should ever be rendered
  return 'checkin.payment.paymentRequired';
};

const getIcon = ({ paidOnline, markedAsPaid }) => {
  if (paidOnline || markedAsPaid) {
    return MoneyIcon;
  }

  // default case -> paymentRequired; nothing else should ever be rendered
  return PosIcon;
};

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'containerColor',
})`
  background-color: ${({ containerColor }) => containerColor};
  color: ${({ containerColor }) => readableColor(containerColor)};
`;

const StyledIcon = styled(Icon)`
  margin-right: 16px;
  font-size: 2rem;
`;

export const PaymentInformation = ({
  appointmentID,
  metadata: { paidOnline, paymentRequired, markedAsPaid } = EMPTY_OBJECT,
  metadata = EMPTY_OBJECT,
}) => {
  const color = useMemo(() => getColor(metadata), [metadata]);

  const text = useMemo(() => getText(metadata), [metadata]);

  const icon = useMemo(() => getIcon(metadata), [metadata]);

  return paymentRequired || paidOnline ? (
    <Container containerColor={color} component={Paper} elevation={0} mb={1}>
      <Box py={1} px={2} display="flex" justifyContent="space-between">
        {/** Icon and text */}
        <Box alignItems="center" display="flex">
          <StyledIcon component={icon} />
          <Typography variant="subtitle2">
            <Translate text={text} />
          </Typography>
        </Box>

        {/** Change payment state */}
        {!paidOnline && paymentRequired ? (
          <ChangePaymentState
            appointmentID={appointmentID}
            markedAsPaid={markedAsPaid}
          />
        ) : (
          SKIP_RENDER
        )}
      </Box>
    </Container>
  ) : (
    SKIP_RENDER
  );
};
