import { Button } from '@mui/material';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import { Translate } from 'i18n/Translate';
import useResetIdCard from './useResetIdCard';

export const ResetIdCard = ({ identity }) => {
  const { mutate: resetIdCard } = useResetIdCard({ identity });

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<SmsIcon />}
      onClick={resetIdCard}
    >
      <Translate text="identity.idCard.reset" />
    </Button>
  );
};
