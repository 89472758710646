import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which gets an appointment's data.
 */
const useAppointment = ({ appointmentId, enabled, onError }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_APPOINTMENT, appointmentId],
    () => get(`booking/appointment/${appointmentId}`),
    {
      onError: () => {
        showNotification(ERROR_NOTIFICATION, 'scan.appointment.error');
        onError();
      },
      onSuccess: (responseData) => {
        if (responseData) {
          return;
        }
        showNotification(ERROR_NOTIFICATION, 'scan.appointment.invalid');
      },
      enabled,
      refetchOnWindowFocus: false,
    },
  );
};

export default useAppointment;
