import { createGlobalStyle } from 'styled-components';
import { Colour } from 'ui/Colour';

export const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${Colour.background};
    color: ${Colour.text};
  }

  b {
    font-weight: 500;
  }
`;
