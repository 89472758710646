import { Box, Paper, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { identity, pick, pickBy } from 'lodash-es';
import { Account } from 'components/account/Account';
import { Translate } from 'i18n/Translate';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { AccountUpdater } from './AccountUpdater';
import { useUpdateAccount } from './hooks/useUpdateAccount';
import { useFetchAccount } from './hooks/useFetchAccount';
import { FormField } from './FormField';

export const CustomerProfile = () => {
  const { mutate: mutateByUpdatingAccount, isLoading: isPending } =
    useUpdateAccount();
  const { data: fetchedAccount, isLoading: isFetchingAccount } =
    useFetchAccount();

  const onSubmit = useCallback(
    (updatedAccount) =>
      mutateByUpdatingAccount({
        payload: updatedAccount,
      }),
    [mutateByUpdatingAccount],
  );

  const relevantData = useMemo(
    () => pickBy(pick(fetchedAccount, Object.values(FormField)), identity),
    [fetchedAccount],
  );

  return (
    <>
      <BackdropSpinner isOpen={isPending || isFetchingAccount} />

      {/* The account settings */}
      <Box p={2} display="flex" alignItems="center" flexDirection="column">
        <Box mb={2} width={1}>
          <Paper sx={{ width: 1 }}>
            <Box p={2}>
              <Typography variant="h6">
                <Translate text="account.edit.title" />
              </Typography>

              <AccountUpdater
                isDisabled={isPending || isFetchingAccount}
                onSubmit={onSubmit}
                account={relevantData}
              />
            </Box>
          </Paper>
        </Box>

        {/* The existing account information (login/roles) */}
        <Account />
      </Box>
    </>
  );
};
