import { includes, isEqual, replace } from 'lodash-es';
import { EMPTY_STRING } from 'constants/semanticConstants';
import { Language } from 'ui/Language';

/**
 * Language path delimiter used for some internal routes
 */
const LANGUAGE_PATH_PARAMETER = `:language(${Object.values(Language).join(
  '|',
)})?`;

/**
 * Admin unscoped root path
 */
export const NON_SCOPED_ADMIN_BASE = '/admin';

/**
 * Organization fragment used by the scoped URLs
 */
export const ORGANIZATION_FRAGMENT = 'organization';

/**
 * Represents the scoped slug (route) template for scoped-required URLs.
 */
export const ORG_SCOPED_SLUG_TEMPLATE = `${ORGANIZATION_FRAGMENT}/:organizationId`;

/**
 * Base admin route for scoped URLs
 */
export const ORG_SCOPED_ADMIN_BASE = `${NON_SCOPED_ADMIN_BASE}/${ORG_SCOPED_SLUG_TEMPLATE}`;

/**
 * All the existing route paths
 */
export const RoutePath = {
  // ---
  // Un-scoped routes
  // ---

  /**
   * This route needs to not be scoped, otherwise the microsoft OIDC will complain, so it has to be
   * outside the scoped admin base route.
   */
  ADMIN_OAUTH_REDIRECT: `${NON_SCOPED_ADMIN_BASE}/oauth/:provider/redirect`,

  // ---
  // Scoped routes
  // ---

  /**
   * This admin route is the base for all the next routes.
   * It does not have any corresponding component.
   *
   * The functionality is the following: everything below this component has to
   * have the required admin roles, and it is part of the authenticated app.
   *
   * Any route which is not matching this one (currently no one), will fall back
   * to the redirector. The redirector will pick either any pending path, either
   * the admin dashboard as a fallback URL.
   */
  ADMIN: ORG_SCOPED_ADMIN_BASE,

  /**
   * Admin dashboard (currently shows an empty page), sidebar and the other menus
   */
  ADMIN_DASHBOARD: `${ORG_SCOPED_ADMIN_BASE}/dashboard`,

  /**
   * List of account(s) health issues in the admin area
   */
  ADMIN_DASHBOARD_ACCOUNTS_HEALTH: `${ORG_SCOPED_ADMIN_BASE}/dashboard/accounts-health`,

  /**
   * List of test(s) health issues in the admin area
   */
  ADMIN_DASHBOARD_TESTS_HEALTH: `${ORG_SCOPED_ADMIN_BASE}/dashboard/tests-health`,

  /**
   * Admin about information
   */
  ADMIN_ABOUT: `${ORG_SCOPED_ADMIN_BASE}/about`,

  /**
   * List of accounts in the admin area
   */
  ADMIN_ACCOUNTS: `${ORG_SCOPED_ADMIN_BASE}/accounts`,

  /**
   * Admin support page
   */
  ADMIN_SUPPORT: `${ORG_SCOPED_ADMIN_BASE}/support`,

  /**
   * List of accounts in the admin area to be warned to be deleted
   */
  ADMIN_SUPPORT_DANGLING_ACCOUNTS_WARNING: `${ORG_SCOPED_ADMIN_BASE}/support/dangling-accounts-warning`,

  /**
   * List of accounts in the admin area to be deleted
   */
  ADMIN_SUPPORT_DANGLING_ACCOUNTS_DELETE: `${ORG_SCOPED_ADMIN_BASE}/support/dangling-accounts-delete`,

  /**
   * Admin communication text settings
   */
  ADMIN_COMMUNICATION_TEXTS_SETTINGS: `${ORG_SCOPED_ADMIN_BASE}/settings/communicationText/:id?/${LANGUAGE_PATH_PARAMETER}`,

  /**
   * DCC admin page
   */
  ADMIN_DCC: `${ORG_SCOPED_ADMIN_BASE}/settings/dcc`,

  /**
   * Health authority page
   */
  ADMIN_HEALTH_AUTHORITY: `${ORG_SCOPED_ADMIN_BASE}/settings/healthAuthority`,

  /**
   * Organization settings page
   */
  ADMIN_ORGANIZATION_SETTINGS: `${ORG_SCOPED_ADMIN_BASE}/settings`,

  /**
   * Admin profile page
   */
  ADMIN_PROFILE: `${ORG_SCOPED_ADMIN_BASE}/profile`,

  /**
   * Reports settings page
   */
  ADMIN_REPORTS_SETTINGS: `${ORG_SCOPED_ADMIN_BASE}/settings/report/:settingId?`,

  /**
   * Admin settings page
   */
  ADMIN_SETTINGS: `${ORG_SCOPED_ADMIN_BASE}/settings`,

  /**
   * Admin tests page
   */
  ADMIN_TEST: `${ORG_SCOPED_ADMIN_BASE}/tests/:testCode`,

  /**
   * Admin test page
   */
  ADMIN_TEST_SETTINGS: `${ORG_SCOPED_ADMIN_BASE}/settings/tests`,

  /**
   * Admin test list page
   */
  ADMIN_TESTS: `${ORG_SCOPED_ADMIN_BASE}/tests`,

  /**
   * Admin global test list page
   */
  ADMIN_GLOBAL_TESTS: `${ORG_SCOPED_ADMIN_BASE}/tests/global`,

  // ---
  // Customer related.
  // ---
  CANCEL_ACCOUNT: '/cancel-account',
  CFP: '/cfp',
  CFP_CHECK: '/cfp/check',
  CHECKIN: '/checkin',
  CHECKIN_APPOINTMENT: '/checkin/:appointmentId?',
  CHECKIN_APPOINTMENTS: '/checkin/appointments',
  ID_CARD_ACTIVATION: '/id-card/activation',
  LOGIN: '/login',
  LOGIN_WITH_REDIRECT: '/login/oauth/:provider/redirect',
  MORE: '/more',
  OAUTH_REDIRECT: '/oauth/:provider/redirect',
  PRINT_LABEL: '/more/print-label',
  PROFILE: '/more/profile',
  RESULT: '/result',
  RESULT_ALT: '/more/result-alt',
  CO2_PROJECT: '/cfp/project/:externalReference',
  CO2_CERTIFICATE: '/cfp/certificate/:externalReference',
  CO2_ORGANIZATION: '/organization',
  CO2_ORGANIZATION_DATA: '/cfp/organization/:externalReference',
  CO2_PROJECT_REGISTER: '/cfp/project/register/:externalReference',
  CO2_ORGANIZATION_REGISTER: '/cfp/organization/register',
  ROOT: '/',
};

export const getAdminAccountsBySearch = (search) =>
  `${RoutePath.ADMIN_ACCOUNTS}?search=${search}`;

export const getAdminReportSettingsPathById = (settingId) =>
  RoutePath.ADMIN_REPORTS_SETTINGS.replace(':settingId?', settingId);

export const getAdminCommunicationTextPath = (id, language = EMPTY_STRING) =>
  RoutePath.ADMIN_COMMUNICATION_TEXTS_SETTINGS.replace(':id?', id).replace(
    LANGUAGE_PATH_PARAMETER,
    language.toLowerCase(),
  );

export const getAdminTestPath = (testCode) =>
  RoutePath.ADMIN_TEST.replace(':testCode', testCode);

export const getOrganizationPath = (externalReference) =>
  RoutePath.CO2_ORGANIZATION_DATA.replace(
    ':externalReference',
    externalReference,
  );

export const getProjectPath = (externalReference) =>
  RoutePath.CO2_PROJECT.replace(':externalReference', externalReference);

export const getOrganizationRegisterPath = () =>
  RoutePath.CO2_ORGANIZATION_REGISTER;

export const getOrganizationsPath = () => RoutePath.CO2_ORGANIZATION;

export const getProjectRegisterPath = (externalReference) =>
  RoutePath.CO2_PROJECT_REGISTER.replace(
    ':externalReference',
    externalReference,
  );

export const getCertificatePath = (externalReference) =>
  RoutePath.CO2_CERTIFICATE.replace(':externalReference', externalReference);

export const getAdminRedirect = (provider) =>
  RoutePath.ADMIN_OAUTH_REDIRECT.replace(':provider', provider).replace(
    RoutePath.ROOT,
    EMPTY_STRING,
  );

export const getCheckinAppointmentPath = (appointmentId = EMPTY_STRING) =>
  RoutePath.CHECKIN_APPOINTMENT.replace(':appointmentId?', appointmentId);

export const getLoginWithRedirect = (provider) =>
  RoutePath.LOGIN_WITH_REDIRECT.replace(':provider', provider).replace(
    RoutePath.ROOT,
    EMPTY_STRING,
  );

export const getRedirect = (provider) =>
  RoutePath.OAUTH_REDIRECT.replace(':provider', provider).replace(
    RoutePath.ROOT,
    EMPTY_STRING,
  );

export const getOrganizationSlug = (organizationId) => {
  if (!organizationId) {
    return EMPTY_STRING;
  }

  return ORG_SCOPED_SLUG_TEMPLATE.replace(':organizationId', organizationId);
};

export const attachOrganizationSlug = (organizationId, unCompiledPath) => {
  const compiledOrganizationSlug = getOrganizationSlug(organizationId);

  if (
    !!compiledOrganizationSlug &&
    includes(unCompiledPath, compiledOrganizationSlug)
  ) {
    return unCompiledPath;
  }

  const compiledPath = replace(
    unCompiledPath,
    ORG_SCOPED_SLUG_TEMPLATE,
    compiledOrganizationSlug,
  );

  const preparedPath = replace(compiledPath, '//', RoutePath.ROOT);

  if (
    !isEqual(RoutePath.ROOT, preparedPath) &&
    preparedPath.endsWith(RoutePath.ROOT)
  ) {
    return preparedPath.substring(0, preparedPath.length - 1);
  }

  return preparedPath;
};

/**
 * Custom id representing any inexistent entity w/ the route
 */
export const SYNTHETIC_ID = 0;
