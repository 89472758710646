import { useCallback } from 'react';
import BarcodeReaderKeyboard from 'react-barcode-reader';
import { Colour } from 'ui/Colour';
import { EMPTY_STRING, SKIP_RENDER } from 'constants/semanticConstants';
import { BarcodeScanner } from './BarcodeScanner';

export const BarcodeReader = ({
  onScanSuccess,
  onScanError,
  variantColor = Colour.black,
  disableKeyboardMode = false,
  unitTestCode = EMPTY_STRING,
}) => {
  const onDataReceived = useCallback(
    (data) => {
      if (!data) {
        return;
      }
      onScanSuccess(data);
    },
    [onScanSuccess],
  );

  return (
    <>
      {/* Camera barcode scanner */}
      <BarcodeScanner
        onScanSuccess={onDataReceived}
        variantColor={variantColor}
      />

      {/* Keyboard input for external barcode scanners */}
      {disableKeyboardMode ? (
        SKIP_RENDER
      ) : (
        <BarcodeReaderKeyboard
          testCode={unitTestCode}
          onScan={onDataReceived}
          onError={onScanError}
        />
      )}
    </>
  );
};
