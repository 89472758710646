import { styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { RoutePath } from 'constants/RoutePath';
import { CertificateOverview } from 'customer/co2/CertificateOverview';
import { ProjectDetails } from 'customer/co2';
import { OrganizationOverview } from 'customer/co2/OrganizationOverview';
import { ProjectRegister } from 'customer/co2/ProjectRegister';
import { GroupType } from 'constants/GroupType';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { OrganizationRegister } from 'customer/co2/OrganizationRegister';
import { Project } from 'customer/co2/Project';
import { CfpCheckContainer } from './check/CfpCheckContainer';
import { Cfp } from './Cfp';

const Layout = styled('section')`
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CfpLogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  width: 100%;
`;

const CfpLogo = styled('img')`
  max-width: 60px;
`;

const Content = styled('div')`
  width: 100%;
  padding-top: 16px;
`;

export const CfpContainer = () => {
  const { formatMessage } = useIntl();

  const {
    current: { groupName },
  } = useOrganization();

  return (
    <Layout>
      <CfpLogoContainer>
        <CfpLogo
          src={
            isEqual(groupName, GroupType.CO2)
              ? `${process.env.PUBLIC_URL}/co2PLogo.svg`
              : `${process.env.PUBLIC_URL}/applogo.svg`
          }
          alt={formatMessage({ id: 'alt.cfp_logo' })}
        />
      </CfpLogoContainer>
      <Content>
        <Switch>
          <Route
            component={OrganizationRegister}
            path={RoutePath.CO2_ORGANIZATION_REGISTER}
          />
          <Route
            component={ProjectRegister}
            path={RoutePath.CO2_PROJECT_REGISTER}
          />
          <Route
            component={CertificateOverview}
            path={RoutePath.CO2_CERTIFICATE}
          />
          <Route component={ProjectDetails} path={RoutePath.CO2_PROJECT} />
          <Route component={CfpCheckContainer} path={RoutePath.CFP_CHECK} />

          {isEqual(groupName, GroupType.CO2) ? (
            <>
              <Route
                component={OrganizationOverview}
                path={RoutePath.CO2_ORGANIZATION}
              />
              <Route
                component={Project}
                path={RoutePath.CO2_ORGANIZATION_DATA}
              />
            </>
          ) : (
            <Route component={Cfp} path={RoutePath.ROOT} />
          )}
        </Switch>
      </Content>
    </Layout>
  );
};
