import { checkPersonalCode, parsePersonalCode } from '../utils/personalCode';
import { PersonInfoProcedureGeneric } from './default/PersonInfoProcedureGeneric';
import { PersonInfoExternal } from './external/PersonInfoExternal';
import { procedures } from './procedure';

export const PersonInfo = ({ pii }) => {
  let procedure = null;
  let personInfo = null;

  if (checkPersonalCode(pii)) {
    const personalCodeArray = parsePersonalCode(pii);
    [procedure, ...personInfo] = personalCodeArray;
  } else {
    procedure = procedures.EXTERNAL;
  }

  switch (procedure) {
    case procedures.DEFAULT:
      return <PersonInfoProcedureGeneric idNumber={personInfo[0]} />;
    case procedures.FIRSTNAME_LASTNAME:
      return (
        <PersonInfoProcedureGeneric
          firstName={personInfo[0]}
          lastName={personInfo[1]}
        />
      );
    case procedures.FIRSTNAME_LASTNAME_DOB:
      return (
        <PersonInfoProcedureGeneric
          firstName={personInfo[0]}
          lastName={personInfo[1]}
          dateOfBirth={personInfo[2]}
        />
      );
    case procedures.FIRSTNAME_LASTNAME_DOB_IDNUMBER:
      return (
        <PersonInfoProcedureGeneric
          firstName={personInfo[0]}
          lastName={personInfo[1]}
          dateOfBirth={personInfo[2]}
          idNumber={personInfo[3]}
        />
      );
    case procedures.FIRSTNAME_LASTNAME_DOB_TAXNUMBER:
      return (
        <PersonInfoProcedureGeneric
          firstName={personInfo[0]}
          lastName={personInfo[1]}
          dateOfBirth={personInfo[2]}
          taxNumber={personInfo[3]}
        />
      );
    case procedures.EXTERNAL:
      return <PersonInfoExternal pii={pii} />;
    default:
      return null;
  }
};
