import { styled } from '@mui/material';
import { Colour } from 'ui/Colour';

export const Row = styled('div')`
  padding: 4px 0;
`;

export const Text = styled('p')`
  font-size: 1.1rem;
  margin: 0;
`;

export const Label = styled('span')`
  font-size: 0.8rem;
  color: ${Colour.textMid};
`;
