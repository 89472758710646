import { keyframes, styled } from '@mui/material';
import { Colour } from 'ui/Colour';

const animate = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
`;

const animateCheck = keyframes`
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
`;

const Svg = styled('svg')`
  width: 80px;
  display: block;
`;

const Circle = styled('circle')`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: ${animate} 900ms ease-in-out;
`;

const Check = styled('polyline')`
  stroke-dasharray: 1000;
  stroke-dashoffset: -100;
  animation: ${animateCheck} 900ms 350ms ease-in-out forwards;
`;

export const AnimatedCheckIcon = () => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <Circle
      fill="none"
      stroke={Colour.main}
      strokeWidth="6"
      strokeMiterlimit="10"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <Check
      fill="none"
      stroke={Colour.main}
      strokeWidth="6"
      strokeLinecap="round"
      strokeMiterlimit="10"
      points="100.2,40.2 51.5,88.8 29.8,67.5 "
    />
  </Svg>
);
