import { styled, Paper } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ErrorComponent } from 'components/ErrorComponent';
import { Co2PdfUpload } from './Co2PdfUpload';
import { CertificateDetails } from './CertificateDetails';

const Layout = styled('div')`
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  grid-gap: 8px 8px;
  grid-template-areas: 'certificateDetails certificateDetails certificateDetails report' 'certificateDetails certificateDetails certificateDetails report' 'certificateDetails certificateDetails certificateDetails healthAuthority';

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'details' 'report' 'certificateDetails' 'certificate' 'healthAuthority';
  }
`;

const StyledPaper = styled(({ gridArea, ...props }) => <Paper {...props} />, {
  shouldForwardProp: (props) => props !== 'gridArea',
})`
  padding: 16px;
  grid-area: ${(props) => props.gridArea};
`;

const Card = ({ component, gridArea }) => (
  <Sentry.ErrorBoundary fallback={ErrorComponent} showDialog>
    <StyledPaper gridArea={gridArea}>{component}</StyledPaper>
  </Sentry.ErrorBoundary>
);

export const CertificateOverview = () => {
  return (
    <Layout>
      <Card gridArea="certificateDetails" component={<CertificateDetails />} />
      <Card gridArea="report" component={<Co2PdfUpload type="certificate" />} />
    </Layout>
  );
};
