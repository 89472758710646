import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which triggers a checkin execution
 *
 * @param contextData the data we're expecting
 * @param onSuccess a callback called with the given parameters when the
 * operation is succeeded
 * @param onError a callback called with the given parameters when the
 * operation has failed
 */
const useTriggerTestCheckIn = ({ onSuccess = noop, onError = noop }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  return useMutation(({ payload }) => post('checkin/test', payload), {
    onSuccess: () => {
      showNotification(SUCCESS_NOTIFICATION, 'scan.test.success');

      onSuccess();
    },
    onError: (error) => {
      showNotification(
        ERROR_NOTIFICATION,
        error?.type ? `scan.test.error.${error.type}` : `scan.test.error`,
      );

      onError(error?.type);
    },
  });
};

export default useTriggerTestCheckIn;
