import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import { useAuth } from 'auth/AuthContext';
import {
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
} from 'components/notification/notificationType';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';

const useCo2PdfUpload = ({ onSuccess = noop, type, reference }) => {
  const { token } = useAuth();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const { postFile } = useApi();
  const { showNotification } = useNotification();
  return useMutation(
    (formData) =>
      postFile(`co2/${type}/pdf?reference=${reference}`, formData, headers),
    {
      onSuccess: () => {
        showNotification(SUCCESS_NOTIFICATION, 'co2.data.import.success');
        onSuccess();
      },
      onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
    },
  );
};

export default useCo2PdfUpload;
