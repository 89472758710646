import useRefreshToken from 'auth/useRefreshToken';
import useTimedTokenRefresh from 'auth/useTimedTokenRefresh';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/semanticConstants';

export const TokenRefresh = ({ onSuccess, token, refreshToken }) => {
  const clearTokens = () => onSuccess(EMPTY_OBJECT);

  const { mutate: mutateByRefreshingToken } = useRefreshToken({
    onSuccess,
    onError: clearTokens,
  });

  useTimedTokenRefresh({
    token,
    refreshToken,
    onDoRefresh: mutateByRefreshingToken,
  });

  return SKIP_RENDER;
};
