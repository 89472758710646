import { useMutation, useQueryClient } from 'react-query';
import { noop } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';
import { QueryKeys } from 'constants/QueryKeys';
import { useOrganization } from 'components/organization/OrganizationProvider';
import {
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
} from 'components/notification/notificationType';

export const useGeneratePersonalAccessToken = ({ onSuccess = noop }) => {
  const {
    current: { organizationId },
  } = useOrganization();

  const { post } = useApi();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }) =>
      post(`organization/${organizationId}/account/token`, payload),
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(QueryKeys.FETCH_ACCESSTOKEN);

        showNotification(
          SUCCESS_NOTIFICATION,
          'profile.personalAccessToken.add.success',
        );
        onSuccess(data);
      },
      onError: () =>
        showNotification(
          ERROR_NOTIFICATION,
          'profile.personalAccessToken.add.error',
        ),
    },
  );
};
