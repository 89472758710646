import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Translate } from 'i18n/Translate';
import { RelativeDate } from 'i18n/RelativeDate';
import { useBool } from 'hooks/useBool';
import { IdentityTypeText } from './IdentityTypeText';
import { IdentityTypeIcon } from './IdentityTypeIcon';
import { IdentityDialog } from './dialog/IdentityDialog';

export const IdentityItem = ({
  identity,
  identity: { type, createdAt },
  accountName,
  isDeletionEnabled,
  onDelete,
}) => {
  const {
    isTrue: isDialogOpened,
    setTrue: openDialog,
    setFalse: closeDialog,
  } = useBool();

  return (
    <>
      <ListItemButton onClick={openDialog}>
        <ListItemAvatar>
          <Avatar>
            <IdentityTypeIcon type={type} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={<IdentityTypeText type={type} />}
          secondary={
            <Translate
              text="date.created_at"
              values={{
                date: <RelativeDate date={createdAt} />,
              }}
            />
          }
        />
      </ListItemButton>

      <IdentityDialog
        accountName={accountName}
        identity={identity}
        isDeletionEnabled={isDeletionEnabled}
        isOpen={isDialogOpened}
        onClose={closeDialog}
        onDelete={onDelete}
      />
    </>
  );
};
