import { useCallback, useMemo } from 'react';
import { useAuth } from 'auth/AuthContext';
import { DEFAULT_HEADERS, del, get, post, put, postFile } from 'utils/api';

export const useApi = () => {
  const { token } = useAuth();
  const headers = useMemo(
    () =>
      token
        ? { ...DEFAULT_HEADERS, Authorization: `Bearer ${token}` }
        : DEFAULT_HEADERS,
    [token],
  );

  const onGet = (url, _headers = headers) => get(url, _headers);

  const onPost = (url, body, _headers = headers) => post(url, body, _headers);

  const onPut = (url, body, _headers = headers) => put(url, body, _headers);

  const onDelete = (url, _headers = headers) => del(url, _headers);

  const onPostFile = (url, body, _headers = headers) =>
    postFile(url, body, _headers);

  return {
    post: useCallback(onPost, [headers]),
    get: useCallback(onGet, [headers]),
    put: useCallback(onPut, [headers]),
    del: useCallback(onDelete, [headers]),
    postFile: useCallback(onPostFile, [headers]),
  };
};
