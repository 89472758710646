import { Box, Button, styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';

const Layout = styled('section')`
  color: ${Colour.red};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  text-align: center;
`;

const Icon = styled('img')`
  max-width: 200px;
  margin-top: 24px;
  padding: 24px;
`;

export const CheckInNotAllowed = ({ text, onRestartClick }) => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Icon
        src={`${process.env.PUBLIC_URL}/illustrations/access_denied.svg`}
        alt={formatMessage({ id: 'alt.access_denied' })}
      />

      <Typography variant="body1">
        <Translate text={text} />
      </Typography>

      <Box pt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={onRestartClick}
          disableElevation
        >
          <Translate text="action.restart" />
        </Button>
      </Box>
    </Layout>
  );
};
