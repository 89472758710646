import { useCallback, useMemo } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { size } from 'lodash-es';
import { IntlDateTime } from 'i18n/IntlDateTime';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { snakeToCamelCase } from 'utils/utils';

const Row = ({ organization, onRowClick }) => {
  const { createdAt, organizationInfo } = useMemo(
    () => snakeToCamelCase(organization),
    [organization],
  );

  const onClick = useCallback(
    () => onRowClick(organization),
    [organization, onRowClick],
  );

  return (
    <TableRow hover onClick={onClick}>
      <TableCell>{organizationInfo.name}</TableCell>

      <TableCell>{organizationInfo.mail}</TableCell>

      <TableCell>{organizationInfo.phone}</TableCell>

      <TableCell>{organizationInfo.uid}</TableCell>

      <TableCell>
        <IntlDateTime date={createdAt} />
      </TableCell>
    </TableRow>
  );
};
export const OrganizationsList = ({ organizations, onRowClick }) => {
  return size(organizations) ? (
    <Box width={1} mt={3}>
      <Box p={1} pl={2}>
        <Typography variant="subtitle2">
          <Translate
            text="co2.organizations.count"
            values={{ count: organizations.length }}
          />
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Translate text="admin.tests.table.organization.name" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.organization.mail" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.organization.phone" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.organization.uid" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.organization.date" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {organizations.map((organization) => (
              <Row
                key={organization.external_reference}
                organization={organization}
                onRowClick={onRowClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    SKIP_RENDER
  );
};
