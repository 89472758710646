import { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { Route, Switch } from 'react-router-dom';
import { Stepper } from 'ui/theme/stepper/Stepper';
import { ProtectedState } from 'components/auth/ProtectedState';
import { Roles } from 'constants/Roles';
import { TestType } from 'constants/TestType';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { RoutePath } from 'constants/RoutePath';
import { ModeSwitcher } from './ModeSwitcher';
import { CheckInState } from './CheckInState';
import { View as Appointment } from './Appointment';
import { TestCheckInFlow } from './Test';
import { VaccinationCheckInFlow } from './Vaccination';
import { CheckInNotAllowed } from './CheckInNotAllowed';
import { View as Appointments } from './Appointments';

const STEPPER_STATES = {
  [CheckInState.APPOINTMENT_SCAN]: 'checkin.step.appointment',

  // ---
  // Both below executions are not shown to the user.
  [CheckInState.APPOINTMENT_ATTACHMENT]: 'checkin.step.execution',
  [CheckInState.APPOINTMENT_CONFIRMATION]: 'checkin.step.confirmation',
};

/**
 * Data should contain two keys:
 *
 * `appointment` which is the retrieved data (and/or updated)
 * `identity` which is confirmed by user
 */
const NO_CONTEXT_DATA = undefined;

/**
 * The main Wizard view
 */
export const View = () => {
  const [uiState, setUiState] = useState(CheckInState.APPOINTMENT_SCAN);
  const [contextData, setContextData] = useState(NO_CONTEXT_DATA);

  const resetDataAndSwitchToAppointmentView = useCallback(() => {
    setContextData(NO_CONTEXT_DATA);

    setUiState(CheckInState.APPOINTMENT_SCAN);
  }, []);

  const switchToNextUiState = useCallback((enrichedContextData) => {
    setContextData(enrichedContextData);

    const nextUiState = enrichedContextData.appointment?.test?.type?.startsWith(
      TestType.COV19_VACCINE,
    )
      ? CheckInState.VACCINATION_DATA_ATTACHMENT
      : CheckInState.TEST_SAMPLE_ATTACHMENT;

    setUiState(nextUiState);
  }, []);

  const renderBasedOnUiState = () => {
    switch (uiState) {
      case CheckInState.APPOINTMENT_SCAN: {
        return (
          <>
            {/* Stepper */}
            <Stepper
              steps={Object.values(STEPPER_STATES)}
              activeStep={Object.keys(STEPPER_STATES).indexOf(uiState)}
            />

            <Switch>
              {/* Step 1b: appointments list */}
              <Route path={RoutePath.CHECKIN_APPOINTMENTS} exact>
                {/* Switcher between the scan or list mode */}
                <ModeSwitcher />

                {/* The list of upcoming appointments */}
                <Appointments />
              </Route>

              {/* Step 1a: appointment scan */}
              <Route path={RoutePath.CHECKIN_APPOINTMENT}>
                {/* Switcher between the scan or list mode */}
                <Route path={RoutePath.CHECKIN} exact>
                  <ModeSwitcher />
                </Route>

                {/* A single selected appointment */}
                <Appointment onSuccess={switchToNextUiState} />
              </Route>
            </Switch>
          </>
        );
      }
      case CheckInState.TEST_SAMPLE_ATTACHMENT:
      case CheckInState.TEST_CONFIRMATION: {
        return (
          <ProtectedState
            role={Roles.TEST_CHECKIN}
            errorComponent={
              <CheckInNotAllowed
                onRestartClick={resetDataAndSwitchToAppointmentView}
                text="checkin.test.notAllowed"
              />
            }
          >
            {/* Maybe step 2 or 3 (Test) */}
            <TestCheckInFlow
              contextData={contextData}
              onSuccess={resetDataAndSwitchToAppointmentView}
            />
          </ProtectedState>
        );
      }
      case CheckInState.VACCINATION_DATA_ATTACHMENT:
      case CheckInState.VACCINATION_CONFIRMATION: {
        return (
          <ProtectedState
            role={Roles.VACCINATION_CHECKIN}
            errorComponent={
              <CheckInNotAllowed
                onRestartClick={resetDataAndSwitchToAppointmentView}
                text="checkin.vaccination.notAllowed"
              />
            }
          >
            {/* Maybe step 2 or 3 (Vaccination) */}
            <VaccinationCheckInFlow
              contextData={contextData}
              onSuccess={resetDataAndSwitchToAppointmentView}
            />
          </ProtectedState>
        );
      }
      default: {
        return SKIP_RENDER;
      }
    }
  };

  return <Box p={2}>{renderBasedOnUiState()}</Box>;
};
