import { Button, styled, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  color: ${Colour.red};
  font-size: 56px;
`;

const InfoText = styled('div')`
  color: ${Colour.red};
  text-align: center;
  margin-bottom: 24px;
`;

export const ResultError = ({ errorText, onRestartClick }) => (
  <Container>
    <StyledErrorIcon />

    <InfoText>
      <Typography variant="body2">
        <Translate text={errorText} />
      </Typography>
    </InfoText>

    <Button variant="contained" color="primary" onClick={onRestartClick}>
      <Translate text="action.restart" />
    </Button>
  </Container>
);
