import { keyframes, styled } from '@mui/material';
import { Colour } from 'ui/Colour';

const animate = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
`;

const Svg = styled('svg')`
  width: 80px;
  display: block;
`;

const Circle = styled('circle')`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: ${animate} 900ms ease-in-out;
`;

const LineOne = styled('line')`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${animate} 900ms 350ms ease-in-out forwards;
`;

const LineTwo = styled('line')`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${animate} 900ms 350ms ease-in-out forwards;
`;

export const AnimatedCrossIcon = () => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <Circle
      fill="none"
      stroke={Colour.red}
      strokeWidth="6"
      strokeMiterlimit="10"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <LineOne
      fill="none"
      stroke={Colour.red}
      strokeWidth="6"
      strokeLinecap="round"
      strokeMiterlimit="10"
      x1="34.4"
      y1="37.9"
      x2="95.8"
      y2="92.3"
    />
    <LineTwo
      fill="none"
      stroke={Colour.red}
      strokeWidth="6"
      strokeLinecap="round"
      strokeMiterlimit="10"
      x1="95.8"
      y1="38"
      x2="34.4"
      y2="92.2"
    />
  </Svg>
);
