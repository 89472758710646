import { identity as truthyKeys, pickBy } from 'lodash-es';
import formatISO from 'date-fns/formatISO';
import isDate from 'date-fns/isDate';
import { Language } from 'ui/Language';
import { EMPTY_OBJECT, UNDEFINED } from 'constants/semanticConstants';
import { CommunicationType } from 'constants/CommunicationType';
import { snakeToCamelCase } from 'utils/utils';
import { CommunicationChannel } from 'constants/CommunicationChannel';

const DEFAULT_EXTERNAL_REFERENCE = 'unknown';

/**
 * Computes the test scan execution payload from the given context and test code.
 */
export const computeCheckinPayload = (
  contextData,
  formValues = EMPTY_OBJECT,
) => {
  const {
    appointment: {
      test: { type: testType } = EMPTY_OBJECT,
      externalReference = DEFAULT_EXTERNAL_REFERENCE,
      pii: { emailAddress, language = Language.DE, phoneNumber } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    identity,
    testCode,
  } = snakeToCamelCase(contextData);

  const {
    description,
    identityType: type,
    verificationLevel,
  } = identity || EMPTY_OBJECT;

  const source = {
    encodedService: testType,
    externalReference,
    testCode,
    testType,
    identity: identity ? { description, type, verificationLevel } : UNDEFINED,
    timestamp: isDate(formValues?.timestamp)
      ? formatISO(formValues?.timestamp)
      : UNDEFINED,
    communicationChannels: [
      formValues?.[CommunicationChannel.EMAIL] &&
        emailAddress && {
          type: CommunicationType.EMAIL,
          language,
          target: emailAddress,
        },
      formValues?.[CommunicationChannel.SMS] &&
        phoneNumber && {
          type: CommunicationType.PHONE,
          language,
          target: phoneNumber,
        },
    ].filter(Boolean),
  };

  return pickBy(source, truthyKeys);
};
