export const Roles = {
  ORGANIZATION_MANAGEMENT: 'organization.manage',
  ORGANIZATION_TEST_MANAGEMENT: 'organization.test.manage',
  TEST_CHECKIN: 'test.checkin.write',
  TEST_RESULT: 'test.result.write',
  VACCINATION_CHECKIN: 'vaccination.checkin.write',
  SUPPORT: 'support',
};

export const AdminRoles = [
  Roles.ORGANIZATION_MANAGEMENT,
  Roles.ORGANIZATION_TEST_MANAGEMENT,
];

export const SyntheticRole = {
  UNSPECIFIED: 'roles.unspecified',
};

export const Co2Roles = {
  ORGANIZATION_MANAGEMENT: 'organization.manage',
  ORGANIZATION_CERTIFICATE_MANAGEMENT: 'organization.test.manage',
  CERTIFICATE_CHECKIN: 'test.checkin.write',
  CERTIFICATE_RESULT: 'test.result.write',
  SUPPORT: 'support',
};
