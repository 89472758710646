import { useCallback, useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { rgba } from 'polished';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import QrReader from 'modern-react-qr-reader';
import { noop } from 'lodash-es';
import { Colour } from 'ui/Colour';
import { useOrganization } from 'components/organization/OrganizationProvider';

const BarcodeWrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'variantColor',
})`
  width: 100%;
  max-width: 300px;
  position: relative;
  aspect-ratio: 1;

  video {
    object-fit: cover;
    max-height: 300px;
  }

  #barcode-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    border: 50px solid ${(props) => rgba(props.variantColor, 0.4)} !important;
    box-shadow: rgba(255, 0, 0, 0.5) 0 0 0 2px inset !important;
  }

  section,
  video,
  div {
    border: 1px solid transparent;
    border-radius: 6px;
  }

  section > div {
    border: 50px solid ${(props) => rgba(props.variantColor, 0.4)} !important;
    box-shadow: rgba(255, 0, 0, 0.5) 0 0 0 2px inset !important;
  }
`;

const QR_READER_DELAY = 300;

export const BarcodeScanner = ({
  onScanSuccess,
  onScanError = noop,
  variantColor = Colour.black,
}) => {
  const { current } = useOrganization();

  const useMultiPurposeBarcodeScannerLibrary = useMemo(
    () => current?.features?.useNewBarcodeLibrary || false,
    [current?.features],
  );

  const onDataReceived = useCallback(
    (data) => {
      if (!data) {
        return;
      }
      onScanSuccess(data);
    },
    [onScanSuccess],
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
      <BarcodeWrapper variantColor={variantColor}>
        {useMultiPurposeBarcodeScannerLibrary ? (
          <>
            <Box id="barcode-overlay" />
            <BarcodeScannerComponent
              delay={QR_READER_DELAY}
              onUpdate={(err, result) => {
                if (!result) {
                  return;
                }

                onDataReceived(result.text);
              }}
            />
          </>
        ) : (
          <QrReader
            delay={QR_READER_DELAY}
            onError={onScanError}
            onScan={onDataReceived}
            showViewFinder
          />
        )}
      </BarcodeWrapper>
    </Box>
  );
};
