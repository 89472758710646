import { Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandIcon from '@mui/icons-material/ExpandMoreRounded';
import { Translate } from 'i18n/Translate';
import { AnimatedCrossIcon } from 'ui/icons/AnimatedCrossIcon';
import { Colour } from 'ui/Colour';
import { RedButton } from 'ui/theme/button/RedButton';
import { PersonInfo } from '../procedure/PersonInfo';

const Layout = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const Title = styled('p')`
  font-size: 1.1em;
  font-weight: 500;
  color: ${Colour.red};
`;

const InfoText = styled('p')`
  font-size: 0.9em;
`;

const ResetButton = styled(RedButton)`
  margin-top: 32px;
`;

export const CfpCheckInvalid = ({ pii }) => (
  <Layout>
    <AnimatedCrossIcon />
    <Title>
      <Translate text="cfp.invalid.title" />
    </Title>
    <PersonInfo pii={pii} />
    <InfoText>
      <Translate text="cfp.invalid.info" />
    </InfoText>
    <Button variant="text" color="secondary" endIcon={<ExpandIcon />}>
      <Translate text="cfp.action.show_details" />
    </Button>
    <ResetButton
      variant="outlined"
      text="action.restart"
      component={Link}
      to="/cfp"
    />
  </Layout>
);
