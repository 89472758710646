import { identity as truthyKeys, pickBy } from 'lodash-es';
import { format, isDate, parseISO } from 'date-fns';
import { Language } from 'ui/Language';
import { EMPTY_OBJECT, UNDEFINED } from 'constants/semanticConstants';
import { CommunicationType } from 'constants/CommunicationType';
import { snakeToCamelCase } from 'utils/utils';
import { DATE_FORMAT } from 'customer/cfp/constants';

const DEFAULT_EXTERNAL_REFERENCE = 'unknown';
export const RECOVERY_VACCINATION_CODE = 1001;

export const getNormalizedVaccinationDate = (vaccinationDate) => {
  if (!vaccinationDate) {
    return UNDEFINED;
  }
  return format(
    isDate(vaccinationDate) ? vaccinationDate : parseISO(vaccinationDate),
    DATE_FORMAT,
  );
};

export const getNormalizedDoses = (dose) => {
  if (dose.numberOfDoses === RECOVERY_VACCINATION_CODE) {
    return {
      numberOfDoses: 1,
      totalNumberOfDoses: 1,
    };
  }
  return dose;
};

/**
 * Computes the vaccination check-in (& result) payload from the given context and test code.
 */
export const computeVaccinationCheckinTriggerPayload = (contextData) => {
  const {
    appointment: {
      externalReference = DEFAULT_EXTERNAL_REFERENCE,
      pii: { emailAddress, language = Language.DE, phoneNumber } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    identity,
    vaccination: {
      vaccine: encodedService,
      dose: { numberOfDoses, totalNumberOfDoses },
      batchNumber,
      countryOfVaccination,
      vaccinationDate,
      isConfirmation,
    },
  } = snakeToCamelCase(contextData);

  const source = {
    externalReference,
    encodedService,
    identity,
    numberOfDoses: getNormalizedDoses(numberOfDoses),
    totalNumberOfDoses,
    batchNumber,
    countryOfVaccination,
    vaccinationDate: getNormalizedVaccinationDate(vaccinationDate),
    isConfirmation,
    communicationChannels: [
      emailAddress && {
        type: CommunicationType.EMAIL,
        language,
        target: emailAddress,
      },
      phoneNumber && {
        type: CommunicationType.PHONE,
        language,
        target: phoneNumber,
      },
    ].filter(Boolean),
  };

  return pickBy(source, truthyKeys);
};
