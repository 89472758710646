import { useMutation } from 'react-query';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';

const useChangePin = ({ externalIdentityId, onSuccess, onError }) => {
  const { put } = useApi();
  const { showNotification } = useNotification();

  return useMutation(
    (payload) => put(`identity/idcard/${externalIdentityId}`, payload),
    {
      onSuccess: () => {
        showNotification(
          SUCCESS_NOTIFICATION,
          'login.id_card.renew_pin.update.success',
        );

        onSuccess();
      },
      onError: () => {
        showNotification(
          ERROR_NOTIFICATION,
          'login.id_card.renew_pin.update.error',
        );

        onError();
      },
    },
  );
};

export default useChangePin;
