import { useCallback, useMemo } from 'react';
import { noop } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { BackdropSpinner } from 'components/BackdropSpinner';
import useCheckInOptions from 'hooks/useCheckInOptions';
import { Translate } from 'i18n/Translate';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/semanticConstants';
import { snakeToCamelCase } from 'utils/utils';
import useAvailableTests from 'hooks/useAvailableTests';
import useUpdateAppointment from '../hooks/useUpdateAppointment';
import { computeAppointmentUpdatePayload } from './modules';
import { FormFragmentsMap } from './Fragments';

export const Edit = ({
  appointment: {
    externalReference: appointmentID,
    service: { id } = EMPTY_OBJECT,
    pii: person,
  },
  onCancelEdit = noop,
  onSettled = noop,
}) => {
  const {
    isLoading: isFetchingCheckInOptionsInProgress,
    data: checkInOptions,
  } = useCheckInOptions();
  const { editAppointmentFields: fieldsSet = EMPTY_ARRAY } =
    snakeToCamelCase(checkInOptions);
  const { isLoading: isFetchingTestsInProgress, data: availableTests } =
    useAvailableTests({ useDefaultServices: false });
  const { mutate, isLoading: isUpdateInProgress } = useUpdateAppointment({
    onSettled,
  });

  const defaultValues = useMemo(
    () => ({
      ...person,
      testType: id,
    }),
    [person, id],
  );

  const { handleSubmit, control, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const isPending =
    isUpdateInProgress ||
    isFetchingTestsInProgress ||
    isFetchingCheckInOptionsInProgress;

  const onCancel = useCallback(() => {
    reset(defaultValues);
    onCancelEdit();
  }, [defaultValues, onCancelEdit, reset]);

  const onSubmit = useCallback(
    (formData) =>
      mutate({
        appointmentID,
        payload: computeAppointmentUpdatePayload(formData),
      }),
    [appointmentID, mutate],
  );

  return (
    <Box>
      <BackdropSpinner isOpen={isPending} />

      <form onSubmit={handleSubmit(onSubmit)}>
        {fieldsSet.map((field) => {
          const FormFragment = FormFragmentsMap[field];

          return (
            FormFragment && (
              <FormFragment
                key={field}
                control={control}
                isDisabled={isPending}
                availableTests={availableTests}
              />
            )
          );
        })}

        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          pt={6}
          mr={2}
          ml={2}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isPending}
          >
            <Translate text="action.continue" />
          </Button>
          <Box
            mt={1}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Button
              variant="text"
              onClick={onCancel}
              color="secondary"
              disabled={isPending}
            >
              <Translate text="action.back" />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
