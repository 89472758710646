import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which returns the current logged-in accounts' data.
 */
export const useFetchAccount = () => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery([QueryKeys.FETCH_ACCOUNT], () => get('account'), {
    onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
    refetchOnWindowFocus: false,
  });
};
