import { useCallback, useState } from 'react';
import AddIcon from '@mui/icons-material/AddRounded';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Translate } from 'i18n/Translate';
import { EMPTY_OBJECT } from 'constants/semanticConstants';
import { useBool } from 'hooks/useBool';
import { IdentityDialog } from '../dialog/IdentityDialog';
import { useCreateIdCard } from './useCreateIdCard';

export const IdentityItemAddIDCard = ({ accountName, onReloadData }) => {
  const {
    isTrue: isDialogOpened,
    setTrue: openDialog,
    setFalse: closeDialog,
  } = useBool();

  const [newIdentity, setNewIdentity] = useState(EMPTY_OBJECT);

  const handleClose = useCallback(() => {
    closeDialog();
    onReloadData();
  }, [closeDialog, onReloadData]);

  const onSuccess = useCallback(
    (data) => {
      openDialog();
      setNewIdentity(data);
    },
    [openDialog, setNewIdentity],
  );

  const { mutate: createIdCard } = useCreateIdCard({
    onSuccess,
  });

  return (
    <>
      <ListItemButton key="add" onClick={createIdCard}>
        <ListItemAvatar>
          <Avatar>
            <AddIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Translate text="profile.identities.idcard.add" />}
        />
      </ListItemButton>

      <IdentityDialog
        accountName={accountName}
        identity={newIdentity}
        isCreate
        isOpen={isDialogOpened}
        onClose={handleClose}
      />
    </>
  );
};
