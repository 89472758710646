import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { noop } from 'lodash-es';
import { StorageKey } from 'constants/StorageKey';
import { RoutePath } from 'constants/RoutePath';
import { useSessionStorage } from './useSessionStorage';

const PendingPathContext = createContext({
  pendingPath: RoutePath.ROOT,
  setPendingPath: noop,
});

export const PendingPathProvider = ({ children }) => {
  const [pendingPathLS, setPendingPathLS] = useSessionStorage(
    StorageKey.HPS_PENDING_PATH,
    null,
  );
  const [pendingPath, setPendingPathInternal] = useState(pendingPathLS);

  const setPendingPath = useCallback(
    (value) => {
      setPendingPathInternal(value);
      setPendingPathLS(value);
    },
    [setPendingPathInternal, setPendingPathLS],
  );

  const context = useMemo(
    () => ({
      pendingPath,
      setPendingPath,
    }),
    [pendingPath, setPendingPath],
  );

  return (
    <PendingPathContext.Provider value={context}>
      {children}
    </PendingPathContext.Provider>
  );
};

export const usePendingPath = () => useContext(PendingPathContext);
