import { Box } from '@mui/material';
import { TestResult, Co2Result } from 'constants/TestResult';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { OptionInvalid } from './OptionInvalid';
import { OptionPositive } from './OptionPositive';
import { OptionNegative } from './OptionNegative';
import { OptionDone } from './OptionDone';
import { OptionVerified } from './OptionVerified';

export const ResultForm = ({ onResultSelected, options }) => (
  <Box mt={4}>
    {options.map((option) => {
      switch (option) {
        case TestResult.POSITIVE:
          return <OptionPositive key={option} onClick={onResultSelected} />;
        case TestResult.NEGATIVE:
          return <OptionNegative key={option} onClick={onResultSelected} />;
        case TestResult.DONE:
          return <OptionDone key={option} onClick={onResultSelected} />;
        case TestResult.INVALID:
          return <OptionInvalid key={option} onClick={onResultSelected} />;
        case Co2Result.VERIFIED:
          return <OptionVerified key={option} onClick={onResultSelected} />;
        default:
          return SKIP_RENDER;
      }
    })}
  </Box>
);
