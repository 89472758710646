import { useMutation } from 'react-query';
import { noop, isEqual } from 'lodash-es';
import { useApi } from 'hooks/useApi';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useNotification } from 'components/notification/useNotification';
import { GroupType } from 'constants/GroupType';

/**
 * Hook which enters a result
 *
 * @param contextData the data we're expecting
 * @param onSuccess a callback called with the given parameters when the operation is succeeded
 */
const useEnterResult = ({ onSuccess = noop, groupName }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  return useMutation(
    ({ testCode, payload }) =>
      post(
        isEqual(groupName, GroupType.CO2)
          ? `co2/${testCode}/result`
          : `test/${testCode}/result`,
        payload,
      ),
    {
      onSuccess: () => {
        showNotification(SUCCESS_NOTIFICATION, 'scan.result.success');

        onSuccess();
      },
      onError: () => showNotification(ERROR_NOTIFICATION, 'scan.result.error'),
    },
  );
};

export default useEnterResult;
