import { useMutation } from 'react-query';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';

const API_RESOURCE = 'identity/token';

const useLogin = ({ onSuccess, onError }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  return useMutation((payload) => post(API_RESOURCE, payload), {
    onSuccess,
    onError: (error) => {
      showNotification(ERROR_NOTIFICATION, 'login.error');
      onError(error);
    },
  });
};

export default useLogin;
