import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, styled } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Translate } from 'i18n/Translate';
import useCo2PdfUpload from 'hooks/useCo2PdfUpload';

const ReportList = styled('div')`
  margin-top: 16px;
`;

export const Co2PdfUpload = ({ type }) => {
  const { externalReference } = useParams();
  const [file, setFile] = useState();

  const { mutate, isLoading } = useCo2PdfUpload({
    type,
    reference: externalReference,
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      mutate(formData);
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      margin="auto"
      mt={5}
    >
      <Typography variant="h6">
        <Translate text="admin.tests.co2.files.title" />
      </Typography>
      <Typography variant="caption">
        <Translate text="admin.tests.co2.files.info" />
      </Typography>
      <ReportList>
        <input type="file" accept=".pdf" onChange={handleFileChange} />
        <Box pt={5}>
          <Button
            component="a"
            disabled={isLoading}
            onClick={handleButtonClick}
            variant="outlined"
            color="secondary"
            startIcon={<FileUploadOutlinedIcon />}
          >
            {type.includes('project') ? (
              <Translate text="admin.tests.co2.project.pdf" />
            ) : type.includes('certificate') ? (
              <Translate text="admin.tests.co2.certificate.pdf" />
            ) : (
              <Translate text="admin.tests.co2.organization.pdf" />
            )}
          </Button>
        </Box>
      </ReportList>
    </Box>
  );
};
