import { useQuery } from 'react-query';
import { QueryKeys } from 'constants/QueryKeys';
import { isTokenAboutToExpire } from './helpers';

const REFETCH_INTERVAL = 60 * 1000; // 1 minute in milliseconds
const CHECK_BEFORE_EXPIRATION = 5 * 60 * 1000; // 5 minutes in milliseconds

/**
 * Hook which periodically checks if the users token is still valid.
 *
 * NOTE: it might probably be a hack to use react-query for this,
 *       but then we don´t have to implement retry interval
 *       and refetch on window focus ourselves.
 */
const useTimedTokenRefresh = ({ token, refreshToken, onDoRefresh }) => {
  return useQuery(
    QueryKeys.TOKEN_REFRESH,
    () => {
      if (isTokenAboutToExpire(token, CHECK_BEFORE_EXPIRATION)) {
        onDoRefresh({ refreshToken });
      }
    },
    {
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: true,
      enabled: !!token,
    },
  );
};

export default useTimedTokenRefresh;
