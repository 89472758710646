import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { isEqual, noop } from 'lodash-es';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { Stepper } from 'ui/theme/stepper/Stepper';
import { CenterSpinner } from 'components/CenterSpinner';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER, UNDEFINED } from 'constants/semanticConstants';
import useEnterResult from './hooks/useEnterResult';
import { EnterResult } from './EnterResult';

const UIState = {
  SCAN_FIRST_STEP: 0,
  RESULT_SECOND_STEP: 1,
};

const ALL_STEPS = ['result.step.check_in', 'result.step.enter_result'];

export const ResultAlt = () => {
  const [uiState, setUIState] = useState(UIState.SCAN_FIRST_STEP);
  const [externalReference, setExternalReference] = useState(UNDEFINED);

  const resetState = useCallback(() => {
    setUIState(UIState.SCAN_FIRST_STEP);
    setExternalReference(UNDEFINED);
  }, []);

  const { mutate: mutateByEnteringResults, isLoading } = useEnterResult({
    onSuccess: resetState,
  });

  const onScanSuccess = useCallback((data) => {
    if (!data) {
      return;
    }

    setExternalReference(data);
    setUIState(UIState.RESULT_SECOND_STEP);
  }, []);

  const onRestartClick = useCallback(() => {
    setUIState(UIState.SCAN_FIRST_STEP);
  }, []);

  const submitResult = useCallback(
    ({ testCode, result, notifyCustomer }) => {
      const payload = {
        test_code: testCode,
        result,
        suppress_customer_notification: !notifyCustomer,
      };

      mutateByEnteringResults({
        testCode,
        payload,
      });
    },
    [mutateByEnteringResults],
  );

  return (
    <Box p={2}>
      <Stepper steps={ALL_STEPS} activeStep={uiState} />

      {isLoading ? (
        <CenterSpinner />
      ) : (
        <>
          {isEqual(UIState.SCAN_FIRST_STEP, uiState) ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                m={2}
              >
                <Typography variant="h6" align="center">
                  <Translate text="scan.result.alt.title" />
                </Typography>
              </Box>
              <BarcodeReader onScanSuccess={onScanSuccess} onScanError={noop} />
            </>
          ) : (
            SKIP_RENDER
          )}

          {isEqual(UIState.RESULT_SECOND_STEP, uiState) ? (
            <EnterResult
              onFinished={submitResult}
              scannedValue={externalReference}
              onRestartClick={onRestartClick}
              isOldURLFormat
            />
          ) : (
            SKIP_RENDER
          )}
        </>
      )}
    </Box>
  );
};
