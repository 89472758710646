import { Avatar, styled, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Co2Result } from 'constants/TestResult';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';
import { ResultSelector } from './ResultSelector';

const VerifiedTestIcon = styled(Avatar)`
  background-color: ${Colour.green};
  margin-right: 16px;
`;

export const OptionVerified = ({ onClick }) => (
  <ResultSelector
    focusRipple
    color={Colour.green}
    onClick={() => onClick(Co2Result.VERIFIED)}
  >
    <VerifiedTestIcon>
      <VerifiedIcon />
    </VerifiedTestIcon>
    <Typography variant="body1">
      <Translate text="scan.result.verified" />
    </Typography>
  </ResultSelector>
);
