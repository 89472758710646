import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';

export const LastNameFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Controller
      control={control}
      name="lastName"
      rules={{
        required: formatMessage({
          id: 'scan.appointment.edit.input.lastName.required',
        }),
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          autoFocus
          disabled={isDisabled}
          fullWidth
          margin="dense"
          variant="outlined"
          label={<Translate text="scan.appointment.edit.input.lastName" />}
          error={!!error}
          helperText={error?.message}
          placeholder={formatMessage({
            id: 'scan.appointment.edit.input.lastName.placeholder',
          })}
          {...field}
        />
      )}
    />
  );
};
