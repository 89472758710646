import { Controller } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';

export const TestTypeFragment = ({ control, isDisabled, availableTests }) => {
  const { formatMessage } = useIntl();

  return availableTests?.services ? (
    <Controller
      control={control}
      name="testType"
      rules={{
        required: formatMessage({
          id: 'scan.appointment.edit.input.testType.required',
        }),
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          autoFocus
          select
          disabled={isDisabled}
          fullWidth
          margin="dense"
          variant="outlined"
          label={<Translate text="scan.appointment.edit.input.testType" />}
          error={!!error}
          helperText={error?.message}
          {...field}
        >
          {availableTests?.services?.map(({ name, id: serviceId }) => (
            <MenuItem key={name} value={serviceId}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  ) : (
    SKIP_RENDER
  );
};
