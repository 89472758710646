import { Button, styled, Typography } from '@mui/material';
import ExpandIcon from '@mui/icons-material/ExpandMoreRounded';
import { Link } from 'react-router-dom';
import { AnimatedCheckIcon } from 'ui/icons/AnimatedCheckIcon';
import { RedButton } from 'ui/theme/button/RedButton';
import { Translate } from 'i18n/Translate';
import { PersonInfo } from '../procedure/PersonInfo';

const Layout = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const Title = styled('p')`
  font-size: 1.1em;
  font-weight: 500;
`;

const InfoText = styled('p')`
  font-size: 0.9em;
`;

const ResetButton = styled(RedButton)`
  margin-top: 32px;
`;

export const CfpCheckValid = ({ pii }) => (
  <Layout>
    <AnimatedCheckIcon />
    <Title>
      <Typography variant="h6">
        <Translate text="cfp.valid.title" />
      </Typography>
    </Title>
    <PersonInfo pii={pii} />
    <InfoText>
      <Typography variant="body2">
        <Translate text="cfp.valid.info" />
      </Typography>
    </InfoText>
    <Button variant="text" color="secondary" endIcon={<ExpandIcon />}>
      <Translate text="cfp.action.show_details" />
    </Button>
    <ResetButton
      variant="outlined"
      text="action.restart"
      component={Link}
      to="/cfp"
    />
  </Layout>
);
