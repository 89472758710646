import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import StylesProvider from '@mui/styles/StylesProvider';
import { CssBaseline } from '@mui/material';
import { Colour } from 'ui/Colour';
import { EMPTY_OBJECT } from 'constants/semanticConstants';
import { GlobalStyle } from './GlobalStyle';

const muiTheme = responsiveFontSizes(
  createTheme({
    typography: {
      h5: {
        fontWeight: '500',
      },
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.10)',
          },
          '&.Mui-active': {
            '& $text': {
              fill: 'white',
            },
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          lineVertical: {
            minHeight: '8px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 500,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '1em',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            minWidth: 'unset',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      type: 'light',
      primary: {
        main: Colour.main,
        text: Colour.text,
      },
      secondary: {
        main: Colour.buttonSecondary,
      },
      buttonPrimary: {
        main: Colour.buttonPrimary,
      },
    },
  }),
);

export const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <ScThemeProvider theme={EMPTY_OBJECT}>
      <GlobalStyle />
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ScThemeProvider>
  </StylesProvider>
);
