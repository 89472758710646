import { styled } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';

const PersonalDataLayout = styled('div')`
  width: 100%;
`;

const PersonalDataContainer = styled('div')`
  background-color: white;
  margin-top: 4px;
  padding: 8px 16px;
`;

const ContainerLabel = styled('span')`
  font-size: 0.9rem;
  color: ${Colour.text};
  font-weight: 500;
  padding-left: 2px;
`;

export const PersonInfoBase = ({ children }) => (
  <PersonalDataLayout>
    <ContainerLabel>
      <Translate text="cfp.personal_data" />
    </ContainerLabel>
    <PersonalDataContainer>{children}</PersonalDataContainer>
  </PersonalDataLayout>
);
