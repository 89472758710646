import { useCallback } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { RedButton } from 'ui/theme/button/RedButton';
import { getOrganizationRegisterPath } from 'constants/RoutePath';

export const OrganizationAdd = () => {
  const { push } = useHistory();

  const switchToOrganizationRegisterView = useCallback(
    () => push(getOrganizationRegisterPath()),
    [push],
  );

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      margin="auto"
      mt={5}
    >
      <div>
        <RedButton
          component="a"
          onClick={switchToOrganizationRegisterView}
          rel="noopener noreferrer"
          variant="contained"
          text="co2.organization.register"
          endIcon={<ChevronRightIcon />}
        />
      </div>
    </Box>
  );
};
