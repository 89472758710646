import { TestType } from 'constants/TestType';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { TestMaterialText } from './TestMaterialText';
import { TestTypeText } from './TestTypeText';

export const TestText = ({ test }) => (
  <>
    {test?.type ? <TestTypeText type={test.type} /> : SKIP_RENDER}
    {test?.material && test?.material !== TestType.UNKNOWN ? (
      <>
        {' ('}
        <TestMaterialText type={test.material} />)
      </>
    ) : (
      SKIP_RENDER
    )}
  </>
);
