import { Box } from '@mui/material';
import { useAuth } from 'auth/AuthContext';
import { AppLogo } from 'components/AppLogo';
import { SelectOrganization } from './SelectOrganization';
import { IdCardActivation } from './IdCardActivation';

export const Onboarding = () => {
  const {
    userContext: { isLoginMethodActivated },
  } = useAuth();

  return (
    <Box px={2}>
      <Box p={2} display="flex" justifyContent="center">
        <AppLogo />
      </Box>

      {isLoginMethodActivated ? <SelectOrganization /> : <IdCardActivation />}
    </Box>
  );
};
