import { styled } from '@mui/material';
import QRCode from 'react-qr-code';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { TestCodeMatcher } from 'constants/Regexes';
import { TestCodeLabelText } from './TestCodeLabelText';

const Layout = styled('div')`
  display: inline-flex;
  height: 96px;
  justify-content: space-between;
  padding: 8px;
`;

const TestQrCode = styled(QRCode)``;

export const TestCodeLabel = ({ testCode, className }) => {
  const testCodeParsed = testCode.match(TestCodeMatcher);

  return (
    <Layout className={className}>
      <TestQrCode value={testCode} size={80} />
      {testCodeParsed ? (
        <TestCodeLabelText testCode={testCodeParsed} />
      ) : (
        SKIP_RENDER
      )}
    </Layout>
  );
};
