import { useEffect } from 'react';
import { useAuth } from 'auth/AuthContext';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { SKIP_RENDER } from 'constants/semanticConstants';

export const Intercom = ({ useCustomLauncher = false }) => {
  const { token, account } = useAuth();
  const { current } = useOrganization();

  const env = process.env.REACT_APP_KEYPER_ENV
    ? process.env.REACT_APP_KEYPER_ENV.toLowerCase()
    : null;

  const appId = env !== 'production' ? 'p67sfach' : 'uq4fguf9';

  useEffect(() => {
    if (token) {
      window.Intercom('boot', {
        app_id: appId,
        hide_default_launcher: useCustomLauncher,
        custom_launcher_selector: useCustomLauncher
          ? '#custom_intercom_launcher'
          : undefined,
        user_id: account?.account_key,
        user_hash: account?.intercom_user_hash,
        name: account?.name,
        company: {
          id: current?.organizationId,
          name: current?.name,
        },
      });
    } else {
      window.Intercom('shutdown');
    }
  }, [account, token, appId, current, useCustomLauncher]);

  return SKIP_RENDER;
};
