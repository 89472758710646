import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';
import KeyCloakIcon from '@mui/icons-material/KeyRounded';
import { useLocation } from 'react-router-dom';
import { Translate } from 'i18n/Translate';
import { useOidc } from 'auth/oidc/OidcHook';
import { OidcProvider } from 'auth/oidc/Oidc';
import { getAdminRedirect, getRedirect } from 'constants/RoutePath';

const StyledAvatar = styled(Avatar)`
  background-color: gray;
`;

export const IdentityItemAddKeycloak = () => {
  const location = useLocation();
  const isAdminArea = location?.pathname?.includes('admin');

  const { getUser, signInRedirect } = useOidc(
    OidcProvider.KEYCLOAK,
    isAdminArea
      ? getAdminRedirect(OidcProvider.KEYCLOAK)
      : getRedirect(OidcProvider.KEYCLOAK),
  );

  const onButtonClick = async () => {
    await getUser();

    signInRedirect({
      state: {
        url: location.pathname,
      },
    });
  };

  return (
    <ListItemButton key="add" onClick={onButtonClick}>
      <ListItemAvatar>
        <StyledAvatar>
          <KeyCloakIcon />
          {/*  <img
            src={`${process.env.PUBLIC_URL}/msIconSmall.svg`}
            alt={formatMessage({ id: 'alt.microsoft_login_image' })}
          /> */}
        </StyledAvatar>
      </ListItemAvatar>

      <ListItemText
        primary={<Translate text="profile.identities.keycloak.add" />}
      />
    </ListItemButton>
  );
};
