import { Avatar, ListItemButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';

export const MoreListItem = ({ icon, primaryText, secondaryText, onClick }) => (
  <ListItemButton onClick={onClick}>
    <ListItemAvatar>
      <Avatar>{icon}</Avatar>
    </ListItemAvatar>

    <ListItemText
      primary={<Translate text={primaryText} />}
      secondary={
        secondaryText ? <Translate text={secondaryText} /> : SKIP_RENDER
      }
    />
  </ListItemButton>
);
