import { FormField } from '../FormField';
import { DateOfBirthFragment } from './DateOfBirthFragment';
import { EmailAddressFragment } from './EmailAddressFragment';
import { FirstNameFragment } from './FirstNameFragment';
import { IdNumberFragment } from './IdNumberFragment';
import { InsuranceCardNumberFragment } from './InsuranceCardNumberFragment';
import { LastNameFragment } from './LastNameFragment';
import { PhoneNumberFragment } from './PhoneNumberFragment';
import { TestTypeFragment } from './TestTypeFragment';

export const FormFragmentsMap = {
  [FormField.DATE_OF_BIRTH]: DateOfBirthFragment,
  [FormField.EMAIL_ADDRESS]: EmailAddressFragment,
  [FormField.FIRST_NAME]: FirstNameFragment,
  [FormField.ID_NUMBER]: IdNumberFragment,
  [FormField.INSURANCE_CARD_NUMBER]: InsuranceCardNumberFragment,
  [FormField.LAST_NAME]: LastNameFragment,
  [FormField.PHONE_NUMBER]: PhoneNumberFragment,
  [FormField.TEST_TYPE]: TestTypeFragment,
};
