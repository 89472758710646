import { Box, Typography } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { InfoBox } from 'ui/InfoBox';

export const CfpCheck = ({ onScanSuccess }) => (
  <>
    <BarcodeReader onScanSuccess={onScanSuccess} />
    <Box display="flex" justifyContent="center">
      <InfoBox>
        <Typography variant="body2">
          <Translate text="cfp.scan.infobox" />
        </Typography>
      </InfoBox>
    </Box>
  </>
);
