import { Box, styled } from '@mui/material';
import { Colour } from 'ui/Colour';

export const InfoBox = styled(Box)`
  background-color: ${Colour.mainLight};
  padding: 16px;
  margin-top: 16px;
  border-radius: 6px;
  font-weight: 500;
`;
