import { styled } from '@mui/material';
import { Translate } from 'i18n/Translate';

const StyledH1 = styled('h1')`
  font-size: 1.2rem;
  margin: 0;
`;

export const StateHeader = ({ text, value }) => (
  <StyledH1>
    <Translate text={text} values={{ value }} />
  </StyledH1>
);
