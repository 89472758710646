import { procedures } from '../procedure/procedure';
import { SEPARATOR } from '../constants';

export const parsePersonalCode = (personalCode) => {
  if (personalCode.includes(SEPARATOR)) {
    const personalCodeArray = personalCode.split(SEPARATOR);
    const procedureCode = personalCodeArray[0];

    if (Object.values(procedures).includes(procedureCode)) {
      return personalCodeArray;
    }
  }
  return ['', ''];
};

export const checkPersonalCode = (personalCode) => {
  if (
    personalCode &&
    typeof personalCode !== 'object' &&
    personalCode.includes(SEPARATOR) &&
    personalCode.split(SEPARATOR).length >= 3
  ) {
    return true;
  }
  return false;
};
