import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';

// https://usehooks.com/useLocalStorage
export const useSessionStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function, so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
};
