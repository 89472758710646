import { styled } from '@mui/material';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';

const Layout = styled('section')`
  color: ${Colour.red};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  text-align: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 4rem;
  opacity: 0.8;
`;

const Text = styled('p')`
  font-weight: 500;
`;

export const ErrorComponent = () => (
  <Layout>
    <StyledErrorIcon />
    <Text>
      <Translate text="state.error" />
    </Text>
    <p>
      <Translate text="state.error.help_us" />
    </p>
  </Layout>
);
