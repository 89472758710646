import { useMutation } from 'react-query';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';

const PLATFORM = 'web';

const useOrganizationLogin = ({ onSuccess }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  return useMutation(
    ({ organizationId }) =>
      post(`identity/organization/${organizationId}/token`, {
        platform: PLATFORM,
      }),
    {
      onSuccess,
      onError: () =>
        showNotification(ERROR_NOTIFICATION, 'login.exchange_token.error'),
    },
  );
};

export default useOrganizationLogin;
