import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { FormattedTime } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash-es';
import { getCheckinAppointmentPath } from 'constants/RoutePath';
import {
  DEBOUNCE_SUBMIT_MILLIS,
  EMPTY_OBJECT,
  EMPTY_STRING,
} from 'constants/semanticConstants';
import { Translate } from 'i18n/Translate';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { useAppointments } from '../Appointment/hooks/useAppointments';

const DEFAULT_VALUES = {
  search: EMPTY_STRING,
};

const useStyles = makeStyles(() => ({
  date: {
    'flex-grow': 0,
    'flex-basis': '25%',
  },
}));

export const View = () => {
  const classes = useStyles();
  const history = useHistory();
  const { control, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });
  const search = watch('search');
  const [searchQuery, setSearchQuery] = useState(search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchQueryDebounced = useCallback(
    debounce(setSearchQuery, DEBOUNCE_SUBMIT_MILLIS),
    [],
  );

  const { data: { appointments } = EMPTY_OBJECT, isLoading } = useAppointments({
    search: searchQuery,
  });

  /**
   * The sync between the emitted value and the debounced one.
   */
  useEffect(
    () => setSearchQueryDebounced(search),
    [setSearchQueryDebounced, search],
  );

  /**
   * Selects the active appointment indirectly by pushing its id to the URL.
   *
   * As a side effect, the check in flow will load its details.
   *
   * @param appointmentId the new selected id from the list
   */
  const startAppointmentCheckIn = (appointmentId) =>
    history.push({ pathname: getCheckinAppointmentPath(appointmentId) });

  /**
   * Maps a given appointment to its visual representations.
   */
  const toListItem = ({
    appointmentCode,
    appointmentId,
    customerName,
    startsAt,
  }) => (
    <Fragment key={appointmentId}>
      <ListItem alignItems="center">
        <ListItemText
          className={classes.date}
          disableTypography
          primary={
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedTime value={startsAt} />
            </Typography>
          }
        />
        <ListItemText
          disableTypography
          primary={<Typography variant="subtitle1">{customerName}</Typography>}
          secondary={<Typography variant="body1">{appointmentCode}</Typography>}
        />
        <Button
          onClick={() => startAppointmentCheckIn(appointmentId)}
          color="primary"
          variant="contained"
        >
          <Translate text="action.start" />
        </Button>
      </ListItem>

      <Divider variant="fullWidth" />
    </Fragment>
  );

  const searchSection = (
    <Box p={2} display="flex" alignItems="center" flexDirection="column">
      <Controller
        control={control}
        name="search"
        render={({ field }) => (
          <TextField
            size="small"
            fullWidth
            id="search"
            label={<Translate text="checkin.appointments.search" />}
            margin="dense"
            type="search"
            variant="outlined"
            {...field}
          />
        )}
      />
    </Box>
  );

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <BackdropSpinner isOpen={isLoading} />

      {searchSection}

      <List>{appointments?.map?.(toListItem)}</List>
    </Box>
  );
};
