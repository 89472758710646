import { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PhotoCamera } from '@mui/icons-material';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { Colour } from 'ui/Colour';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { Translate } from 'i18n/Translate';
import { EMPTY_REGEX } from 'constants/semanticConstants';
import { useNotification } from 'components/notification/useNotification';
import { useBool } from 'hooks/useBool';
import { View as Lookuper } from '../Lookuper';

const useStyles = makeStyles((theme) => ({
  lookuper: {
    maxWidth: theme.spacing(50),
  },
}));

export const SampleAttachment = ({
  validationRegex = EMPTY_REGEX,
  onSuccess,
}) => {
  const classes = useStyles();
  const { showNotification } = useNotification();
  const { isTrue: isFallbackFlowEnabled, setTrue } = useBool();

  const verifyTestCodeAndContinue = useCallback(
    (testCode) => {
      if (new RegExp(validationRegex).test(testCode)) {
        onSuccess(testCode);

        return;
      }

      showNotification(ERROR_NOTIFICATION, 'scan.test.validation_error');
    },
    [validationRegex, onSuccess, showNotification],
  );

  const notifyUser = useCallback(
    () => showNotification(ERROR_NOTIFICATION, 'scan.test.error'),
    [showNotification],
  );

  return (
    <>
      {/* Main flow (scan the test) */}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        m={2}
      >
        <Typography variant="h6" align="center">
          <Translate text="scan.test.title" />
        </Typography>
      </Box>
      <BarcodeReader
        onScanSuccess={verifyTestCodeAndContinue}
        onScanError={notifyUser}
        variantColor={Colour.main}
      />

      {/* Fallback flow (when the camera is not working) */}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mt={6}
        pt={4}
      >
        {isFallbackFlowEnabled ? (
          <>
            <Typography variant="h6" align="center">
              <Translate text="scan.appointment.test_lookup.title" />
            </Typography>

            <Box mt={3} width={1} className={classes.lookuper}>
              <Lookuper onSuccessMatch={verifyTestCodeAndContinue} />
            </Box>
          </>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            onClick={setTrue}
            startIcon={<PhotoCamera />}
          >
            <Translate text="scan.appointment.test_lookup.enable_fallback_flow" />
          </Button>
        )}
      </Box>
    </>
  );
};
