import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isWebUri } from 'valid-url';
import { EMPTY_OBJECT, UNDEFINED } from 'constants/semanticConstants';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';
import { useNotification } from 'components/notification/useNotification';
import { CenterSpinner } from 'components/CenterSpinner';
import { TestCodeMatcher } from 'constants/Regexes';
import { KnownOrganizationId } from 'constants/KnownOrganizationId';
import { CustomizableContent } from 'components/auth/CustomizableContent';
import { RouteParam } from 'constants/RouteParam';
import { SelfAutomator } from '../SelfAutomator';
import { Overview } from './Overview';
import useAppointment from './hooks/useAppointment';
import { useChangedToScopedOrganization } from './hooks/useChangeToScopedOrganization';

export const View = ({ onSuccess }) => {
  const { showNotification } = useNotification();
  const { appointmentId: defaultAppointmentId } = useParams() || EMPTY_OBJECT;
  const [appointmentId, setAppointmentId] = useState(defaultAppointmentId);

  const resetAppointmentId = () => setAppointmentId(UNDEFINED);

  const checkAndSetAppointmentId = (maybeAppointmentId) => {
    /* Apparently, this is a case - where users are scanning wrong QR code */
    if (TestCodeMatcher.test(maybeAppointmentId)) {
      showNotification(ERROR_NOTIFICATION, 'scan.appointment.errorIsTestCode');

      return;
    }

    if (!isWebUri(maybeAppointmentId)) {
      setAppointmentId(maybeAppointmentId);

      return;
    }

    const maybeFoundAppointmentId = new URL(
      maybeAppointmentId,
    ).searchParams.get(RouteParam.ID);

    if (!maybeFoundAppointmentId) {
      showNotification(ERROR_NOTIFICATION, 'scan.appointment.error');

      return;
    }

    setAppointmentId(maybeFoundAppointmentId);
  };

  const {
    data: appointmentData,
    isLoading,
    isRefetching,
    refetch: reloadCurrentAppointment,
  } = useAppointment({
    appointmentId,
    enabled: !!appointmentId,
    onError: resetAppointmentId,
  });

  const { isLoading: isOrganizationScopeCheckLoading } =
    useChangedToScopedOrganization({
      appointmentData,
      onSuccess: reloadCurrentAppointment,
    });

  const triggerStepSuccessful = useCallback(
    (identityData) =>
      onSuccess({
        appointment: appointmentData,
        identity: identityData,
      }),
    [onSuccess, appointmentData],
  );

  const notifyUser = () =>
    showNotification(ERROR_NOTIFICATION, 'scan.appointment.error');

  useEffect(
    () => setAppointmentId(defaultAppointmentId),
    [setAppointmentId, defaultAppointmentId],
  );

  if (isLoading || isRefetching || isOrganizationScopeCheckLoading) {
    return <CenterSpinner />;
  }

  return appointmentData ? (
    <Overview
      appointment={appointmentData}
      onRestartClick={resetAppointmentId}
      onCheckInReady={triggerStepSuccessful}
      onUpdatedAppointment={reloadCurrentAppointment}
    />
  ) : (
    <>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        m={2}
      >
        <Typography variant="h6" align="center">
          <SelfCheckinProtectedState
            otherwise={
              <CustomizableContent
                organizationId={KnownOrganizationId.SGQUZPUR}
                otherwise={<Translate text="scan.appointment.title" />}
              >
                <Translate text="scan.appointment.title.alternative" />
              </CustomizableContent>
            }
          >
            <Translate text="scan.appointment.self_check_in.title" />
          </SelfCheckinProtectedState>
        </Typography>
      </Box>

      <BarcodeReader
        onScanSuccess={checkAndSetAppointmentId}
        onScanError={notifyUser}
        variantColor={Colour.main}
      />

      {/* This will not render anything; will just auto-switch to the next step */}
      <SelfCheckinProtectedState>
        <SelfAutomator onIdentifiedAppointmentId={setAppointmentId} />
      </SelfCheckinProtectedState>
    </>
  );
};
