import { styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';

const Layout = styled('section')`
  color: ${Colour.red};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  text-align: center;
`;

const Icon = styled('img')`
  max-width: 200px;
  margin-top: 24px;
  padding: 24px;
`;

const Text = styled('p')`
  font-weight: 500;
`;

export const NotAllowed = () => {
  const { formatMessage } = useIntl();
  return (
    <Layout>
      <Icon
        src={`${process.env.PUBLIC_URL}/illustrations/access_denied.svg`}
        alt={formatMessage({ id: 'alt.access_denied' })}
      />
      <Text>
        <Translate text="state.not_allowed" />
      </Text>
    </Layout>
  );
};
