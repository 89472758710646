import { Box, Paper, styled, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import Icon from '@mui/material/Icon';
import { readableColor } from 'polished';
import { Translate } from 'i18n/Translate';
import { Colour } from 'ui/Colour';

const StyledIcon = styled(Icon)`
  margin-right: 16px;
  font-size: 2rem;
`;

const Container = styled(Box)`
  background-color: ${Colour.orange};
  color: ${() => readableColor(Colour.orange)};
`;

export const AppointmentUsed = () => (
  <Container mb={1} component={Paper} elevation={0}>
    <Box py={1} px={2} alignItems="center" display="flex">
      <StyledIcon component={WarningIcon} />
      <Typography variant="subtitle2">
        <Translate text="scan.appointment.state.used" />
      </Typography>
    </Box>
  </Container>
);
