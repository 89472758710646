import { isEqual, replace, size, split } from 'lodash-es';
import {
  EMPTY_OBJECT,
  EMPTY_STRING,
  EN_DASH,
  UNDEFINED,
} from 'constants/semanticConstants';

const TOTAL_NUMBER_LENGTH = 6;

export const getValidateFunctionWithMessage =
  ({ invalidValueMessage }) =>
  (rawValue) => {
    const normalizedValue = replace(rawValue, /[—_]/g, EMPTY_STRING);
    if (!normalizedValue) {
      return UNDEFINED;
    }

    if (!isEqual(size(normalizedValue), TOTAL_NUMBER_LENGTH)) {
      return invalidValueMessage;
    }

    return UNDEFINED;
  };

export const getLookupCriteriaFrom = (testLookup) => {
  const EXPECTED_PARTS = 2;

  const maybeArray = split(testLookup, EN_DASH);
  if (!isEqual(EXPECTED_PARTS, size(maybeArray))) {
    return EMPTY_OBJECT;
  }

  const [runningNumber, lastFour] = maybeArray;

  return { runningNumber, lastFour };
};
