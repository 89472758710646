import { useCallback, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';
import { IdentityList } from './identity/IdentityList';
import { PersonalAccessTokenAdd } from './personalAccessToken/add/PersonalAccessTokenAdd';

export const Account = () => {
  const { get, del } = useApi();
  const { showNotification } = useNotification();
  const [accountData, setAccountData] = useState({ identities: [] });

  const getAccount = useCallback(async () => {
    const account = await get('account');

    setAccountData(account);
  }, [setAccountData, get]);

  const onDelete = async (identity) => {
    try {
      await del(`identity/${identity.type}/${identity.identity}`);
      showNotification(SUCCESS_NOTIFICATION, 'identity.delete.success');

      await getAccount();
    } catch (error) {
      showNotification(ERROR_NOTIFICATION, 'identity.delete.error');
    }
  };

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  return (
    <Box component={Paper} width={1}>
      <Box p={1} mb={2}>
        <IdentityList
          identities={accountData.identities}
          accountName={accountData.name}
          onReloadData={getAccount}
          onDelete={onDelete}
        />
      </Box>
      <Box p={1} mb={2}>
        <PersonalAccessTokenAdd
          accountKey={accountData.account_key}
          onReloadData={getAccount}
        />
      </Box>
    </Box>
  );
};
