import {
  Box,
  FormControlLabel as MuiFormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { EMPTY_ARRAY, SKIP_RENDER } from 'constants/semanticConstants';
import { Translate } from 'i18n/Translate';
import { BorderedPaper } from 'ui/theme/BorderedPaper';

const FormControlLabel = styled(MuiFormControlLabel)`
  width: 100%;
`;

export const VaccineFragment = ({
  vaccines = EMPTY_ARRAY,
  control,
  clearErrors,
  resetDoseValues,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box pb={2}>
        <Typography variant="body1">
          <Translate text="checkin.vaccine.selectVaccine" />
        </Typography>
      </Box>
      <Controller
        control={control}
        name="vaccine"
        rules={{
          required: intl.formatMessage({
            id: 'checkin.vaccine.radioGroup.vaccine.required',
          }),
        }}
        render={({ field, field: { onChange }, fieldState: { error } }) => (
          <>
            <RadioGroup
              {...field}
              onChange={(e) => {
                clearErrors('vaccine');
                resetDoseValues();
                onChange(e);
              }}
            >
              <Grid container spacing={2}>
                {vaccines.map((vaccine) => (
                  <Grid item xs={6} key={vaccine.identifier}>
                    <Box
                      p={1}
                      pl={2}
                      display="flex"
                      alignItems="center"
                      component={BorderedPaper}
                      isSelected={field.value === vaccine.identifier}
                      hasError={!!error}
                    >
                      <FormControlLabel
                        value={vaccine.identifier}
                        control={<Radio color="primary" />}
                        label={vaccine.name}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
            {error ? (
              <Box pt={2}>
                <FormHelperText error>{error.message}</FormHelperText>
              </Box>
            ) : (
              SKIP_RENDER
            )}
          </>
        )}
      />
    </>
  );
};
