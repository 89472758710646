import { Button } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { Spinner } from 'components/Spinner';
import useChangeAppointmentPaymentState from '../hooks/useChangeAppointmentPaymentState';

export const ChangePaymentState = ({ appointmentID, markedAsPaid }) => {
  const { mutate, isLoading } = useChangeAppointmentPaymentState();

  const changePaymentState = () => {
    mutate({
      appointmentID,
      markedAsPaid: !markedAsPaid,
    });
  };

  return isLoading ? (
    <Spinner size={36} color="inherit" />
  ) : (
    <Button color="inherit" variant="outlined" onClick={changePaymentState}>
      <Translate
        text={
          markedAsPaid
            ? 'checkin.payment.action.markAsUnpaid'
            : 'checkin.payment.action.markAsPaid'
        }
      />
    </Button>
  );
};
