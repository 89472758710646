import { useMutation } from 'react-query';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'components/notification/notificationType';
import { useApi } from 'hooks/useApi';
import { useNotification } from 'components/notification/useNotification';

const useSendIdCardPerSms = ({ externalIdentityId }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  const payload = {
    domain: window.location.hostname,
  };

  return useMutation(
    () => post(`identity/idcard/${externalIdentityId}/sms`, payload),
    {
      onSuccess: () =>
        showNotification(
          SUCCESS_NOTIFICATION,
          'login.idCard.sendPerSms.success',
        ),
      onError: () =>
        showNotification(ERROR_NOTIFICATION, 'login.idCard.sendPerSms.error'),
    },
  );
};

export default useSendIdCardPerSms;
